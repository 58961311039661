/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { closeIconGrey } from '../../../constants/images/closeIconGrey';
import type { HandleFilterType } from '../../../types/filterTypes';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import { useRouter } from 'next/router';
import { useLabels } from '@/hooks/useLabels';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { sessionStorage } from '@/utils/sessionStorage';

type Props = {
  pageType: string;
  onClick?: () => void;
  selectedFilters?: Array<HandleFilterType>;
  variant?: string;
  showCloseIcon?: boolean;
  classes?: { root?: string };
  isMobileDrawer?: boolean;
};

const trackShelfPageMobileFilter = (eventType: string, filters: string) => {
  clickTrack({
    eventType: eventType,
    productFilterSelectionCta: filters,
  });
};

const labelMap = {
  lblClearAll: 'label_Clear_All',
  lblAriaLabelClearFilters: 'label_Clear_All_Filters',
};

const ClearFiltersButton = ({
  pageType,
  onClick,
  selectedFilters,
  variant = '',
  showCloseIcon = false,
  classes = {},
  isMobileDrawer,
}: Props) => {
  const { lblClearAll, lblAriaLabelClearFilters } = useLabels(labelMap);
  const lowerCasePageType = pageType?.toLowerCase();
  const isSearchPageType = lowerCasePageType?.includes('search');
  const isShelfPageType = lowerCasePageType?.includes('shelf');
  const router = useRouter();
  const { url, query } = parseUrl(router.asPath);

  const clearFilterBtnStyle =
    variant === 'ghost'
      ? styles.clearFilterGhost
      : isMobileDrawer
      ? styles.mobileDrawerClearFilter
      : styles.clearFilter;

  const clearFilters = () => {
    /*Analytics start*/
    if (selectedFilters) {
      const dataFilter: string[] = [];
      const eventType = pageType === 'shelf' ? 'clearFilterShelf' : 'clearFilterSearch';
      selectedFilters.map((filter) => {
        dataFilter.push(`${filter.dimensionName}:${filter?.refinement?.label}`);
      });
      trackShelfPageMobileFilter(eventType, dataFilter.join('|'));
    }
    sessionStorage.removeItem('filterSelectionAnalytics');
    /*Analytics end*/

    if (isShelfPageType) {
      router.push(url.replace(/(\/bn\/?|\/b\/).*/, ''));
    } else {
      const newUrl = stringifyUrl(
        {
          url,
          query: {
            ...query,
            pageNumber: null,
            currentPage: null,
            searchText: isSearchPageType ? query.searchText : null,
            filters: null,
            sort: null,
            facet: null,
            minPrice: null,
            maxPrice: null,
            ignoreVehicleSpecificProductsCheck: true,
          },
        },
        {
          skipNull: true,
        }
      );
      router.push(newUrl);
    }
  };

  return (
    <Button
      data-testid="applyFilterCloseButton"
      ariaLabel={lblAriaLabelClearFilters}
      onClick={() => {
        clearFilters();
        onClick?.();
      }}
      customClass={cx(clearFilterBtnStyle, {
        [classes.root || '']: classes.root,
        [styles.defaultTextStyle]: !isMobileDrawer,
      })}
      variant={variant}
    >
      {showCloseIcon && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={styles.closeIconImg}
          src={closeIconGrey.src}
          alt={closeIconGrey.alt}
          height="11"
          width="11"
        />
      )}
      {lblClearAll}
    </Button>
  );
};

export default ClearFiltersButton;
