/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useFeatureFlag } from '@/features/globalConfig';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import styles from './styles.module.scss';
import { formatPrice } from '@/utils/validator/formatPrice';
import { getCurrencySymbol } from '@/utils/priceHelpers';

type Props = {
  showPriceCaption: boolean;
  configurableSku: boolean | undefined;
  showDiscountedPrice: boolean;
  dollars: string;
  cents: string;
  discountedPrice: string | null | undefined;
};

const PriceDollarsAndCents = ({
  showPriceCaption,
  configurableSku,
  showDiscountedPrice,
  dollars,
  cents,
  discountedPrice,
}: Props) => {
  const ariaPriceOfItem = useLabel('label_the_price_of_item');
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const getDiscountedPrice = (): string[] => {
    let discountedPriceDollars = '0';
    let discountedPriceCents = '00';
    if (strikeThroughPricingEnabled && discountedPrice !== null) {
      const formattedDiscountedPrice = discountedPrice
        ? formatPrice(parseFloat(discountedPrice))?.toString()
        : '0.00';
      if (formattedDiscountedPrice !== null) {
        discountedPriceDollars = (formattedDiscountedPrice ?? '0').split('.')[0];
        discountedPriceCents = (formattedDiscountedPrice ?? '0').split('.')[1] ?? '00';
      }
    }
    return [discountedPriceDollars, discountedPriceCents];
  };

  const [discountedPriceDollars, discountedPriceCents] = getDiscountedPrice();
  const regularDollarAmount = formatPrice(parseFloat(dollars))?.toString().split('.')[0];
  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);

  return (
    <div
      className={cx(azCommonStyles['az-title-1-heavy'], styles.priceContainer)}
      data-testid="price-container-dollars"
      aria-label={ariaPriceOfItem}
    >
      {showPriceCaption && (
        <span
          aria-hidden="true"
          className={cx(configurableSku ? styles.startingAt : styles.prefix, styles.lowercase)}
        >
          Starting at
        </span>
      )}
      <span
        aria-hidden="true"
        className={showDiscountedPrice ? styles.strikePrefix : styles.prefix}
      >
        {currencySymbol}
      </span>
      <span aria-hidden="true" className={showDiscountedPrice ? styles.strikePrice : styles.price}>
        {showDiscountedPrice ? discountedPriceDollars : regularDollarAmount}
      </span>
      <span aria-hidden="true" className={showDiscountedPrice ? styles.strikeDec : styles.dec}>
        {showDiscountedPrice ? discountedPriceCents : cents}
      </span>
    </div>
  );
};

export default PriceDollarsAndCents;
