/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { FilterMobile } from '@/components/Filter/FilterMobile';
import { useFilteredSortingOptions } from '@/hooks/useFilteredSortingOptions';
import azCommonStyles from '@/theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import SortBy from '@/components/AZCustomComponent/SortBy';
import PageRecordCounter from '@/components/AZCustomComponent/PageRecordCounter';
import ListLayoutOptions from '@/components/ListLayoutOptions';
import ListLayoutOptionsV2 from '@/components/ListLayoutOptionsV2';
import ClearFiltersButton from '@/components/Filter/ClearFiltersButton';
import { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import type { FilterDataType, HandleFilterType } from '@/types/filterTypes';
import { useGetShelfPageRecords } from '@/features/shelf/hooks/useGetShelfPageRecords';
import styles from './styles.module.scss';
import { useProductList } from '@/features/shelf/context/useProductList';
import {
  createRef,
  MutableRefObject,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useSelector } from 'react-redux';
import { ReduxState } from '@/types';
import { setSortByOrItemsPerPageFocus } from '@/actions/shelf/shelf';
import { useDispatch } from '@/hooks/redux/useDispatch';
import ChipTags from '@/components/Filter/ChipTags';
import { useRemoveAppliedPriceFilters } from '@/hooks/useRemoveAppliedPriceFilters';
import { useFilterAndRedirect } from '@/hooks/useFilterAndRedirect';
import { pageTypes } from '@/constants/page';
import { NumberOfResults } from '@/features/search';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useFeatureFlag } from '@/features/globalConfig';
import { countryCodes } from '@/constants/locale';
import { View } from '@az/starc-ui';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { useGetOneClickFilters } from '@/hooks/useGetOneClickFilters';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import Image from '@/components/NextImage';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSwipeable } from 'react-swipeable';

export type ListOptionProps = {
  refQuickFilter?: Ref<HTMLElement>;
  pageType: string;
  onFilterSelect?: (a: HandleFilterType) => void;
  disableRecordsPerPage?: boolean;
  rppOptionsData?: string;
  staticFilterData?: FilterDataType[];
  searchText?: string;
  recordsPerPage?: number;
  removeMargin?: boolean;
};

const labelMap = {
  lblOf: 'label_checkout_shipping_Of',
  lblResults: 'label_Search_Results_Results',
  lblSelectedFilters: 'label_Selected_Filters',
  lblFor: 'label_Search_for',
};

const limit = 1;
const minRecs = 12;

export const ListOptions = ({
  refQuickFilter,
  pageType,
  onFilterSelect,
  disableRecordsPerPage,
  rppOptionsData,
  staticFilterData,
  searchText,
  recordsPerPage,
  removeMargin,
}: ListOptionProps) => {
  const { focusItemsPerPage, focusSortBy } = useSelector(({ shelf }: ReduxState) => shelf);
  const dispatch = useDispatch();
  const router = useRouter();
  const { url } = parseUrl(router.asPath);
  const { data: shelfData } = useProductList();
  const { data: searchProductData } = useSearchProductData({
    enabled: pageType === pageTypes.search,
  });
  const shelfPageBody = shelfData?.shelfPageBody;
  const shelfLeftNav = shelfData?.shelfLeftNav;
  const { toggleListView, listViewState } = useProductListView();
  const isBrandPage = isBrandShelfPage(router.asPath);
  const isListView = isBrandPage ? listViewState.isBrandListView : listViewState.isListView;
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandPage });
  const isNewGridAndListViewForMx = useFeatureFlag('IS_NEW_GRID_AND_LIST_VIEW_FOR_MX') === 'true';
  const { data: productShelfResultsData } = useProductShelfResults();
  const [selectedOneClickFilterIndex, setSelectedOneClickFilterIndex] = useState(0);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const oneClickFilterRefs = useRef<Array<MutableRefObject<HTMLLIElement>>>([]);
  const unorderedListElement: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const handleListViewToggle = (view: boolean) => {
    toggleListView({
      type: isBrandPage ? 'TOGGLE_BRAND_LIST_VIEW' : 'TOGGLE_LIST_VIEW',
      payload: view,
    });
  };

  const setFocus = (sortBy: boolean, itemsPerPage: boolean) => {
    dispatch(setSortByOrItemsPerPageFocus(sortBy, itemsPerPage));
  };

  const removeCustomPriceRangeFilter = useRemoveAppliedPriceFilters();
  const handleFilterAndRedirect = useFilterAndRedirect();
  const onRemoveChipTag = (filter: HandleFilterType) => {
    if (filter.dimensionName === 'CustomPrice') {
      removeCustomPriceRangeFilter(() => onFilterSelect?.(filter));
    } else {
      handleFilterAndRedirect(filter, onFilterSelect);
    }
  };
  const oneClickFilters = useGetOneClickFilters(
    productShelfResultsData?.productShelfResults?.quickFilters ??
      searchProductData?.quickFilters ??
      brandProductData?.quickFilters,
    staticFilterData ?? brandProductData?.staticNavigation ?? shelfLeftNav?.[0].staticNavigation,
    shelfLeftNav?.[0].dynamicNavigation?.attributeNarrowingList
  );

  const selectedOneClickFilters = oneClickFilters.filter((filter) => filter.checked === true);
  const clearAllChipTag = selectedOneClickFilters.length > 1 && (
    <ClearFiltersButton pageType={pageType} />
  );
  const pageRecData = useGetShelfPageRecords(shelfPageBody);

  const recsPerPage =
    recordsPerPage ??
    (isBrandPage ? brandProductData?.recordsPerPage : searchProductData?.recordsPerPage) ??
    (pageRecData?.recsPerPage || minRecs);
  const shouldDisableRecsPerPage = disableRecordsPerPage ?? pageRecData?.disableRecordsPerPage;
  const totalNumRecs =
    (isBrandPage
      ? brandProductData?.total
      : pageRecData
      ? pageRecData.totalNumRecs
      : searchProductData?.total) ??
    (pageRecData?.totalNumRecs || 0);
  const is24ProductViewEnabled = useFeatureFlag('IS_24_PRODUCT_VIEW_ENABLED') === 'true';
  const hidePricing = usePriceHiding();
  let rppOptions = rppOptionsData ?? (pageRecData?.rppOptions || minRecs.toString());
  if (is24ProductViewEnabled && rppOptions.split('12,').length === 2) {
    rppOptions = rppOptions.split('12,')[1];
  }
  const pageNumber: number = router.query.pageNumber ? Number(router.query.pageNumber) : 1;
  const firstPageRec =
    (isBrandPage
      ? brandProductData?.firstRecNum
      : pageRecData
      ? pageRecData.firstRecNum
      : searchProductData?.firstRecNum) ?? recsPerPage * (pageNumber - limit) + limit;
  const [initialSortingOptions = []] = useFilteredSortingOptions() || [];
  const sortingOptions = hidePricing
    ? initialSortingOptions.filter((option) => !option.parameter.includes('price'))
    : initialSortingOptions;
  const showSortOptions = !!sortingOptions && !!sortingOptions.length;
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const isBrandsPage = isBrandShelfPage(url);
  const isShelfPageType = pageType.includes('shelf');
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    locale === countryCodes.us &&
    isShelfPageType &&
    !isBrandsPage;
  const counterSection = shouldDisableRecsPerPage ? null : (
    <div className={styles.pageRecordCounterContainer}>
      <PageRecordCounter
        recordsPerPage={recsPerPage}
        totalRecords={totalNumRecs}
        recordsPerPageOptions={rppOptions}
        enableRecordsPerPageOptions={totalNumRecs > (is24ProductViewEnabled ? 24 : 12)}
        focusItem={focusItemsPerPage}
        setFocus={setFocus}
        disableTotalMarkup
      />
    </div>
  );
  const isBR = locale === countryCodes.ptBr;

  const shouldShowLeftArrow = () => {
    if (unorderedListElement?.current && oneClickFilterRefs?.current[0]) {
      if (
        oneClickFilterRefs?.current[0]?.current?.getBoundingClientRect()?.left <
        unorderedListElement?.current?.getBoundingClientRect()?.left
      ) {
        setHideLeftArrow(false);
      } else {
        setHideLeftArrow(true);
      }
    }
  };

  const shouldShowRightArrow = () => {
    if (
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[oneClickFilterRefs.current.length - 1]
    ) {
      if (
        oneClickFilterRefs?.current[
          oneClickFilterRefs?.current.length - 1
        ]?.current?.getBoundingClientRect()?.right -
          1 >
        unorderedListElement?.current?.getBoundingClientRect()?.right
      ) {
        setHideRightArrow(false);
      } else {
        setHideRightArrow(true);
      }
    }
  };

  const scrollBlockScrolled = useCallback(() => {
    shouldShowLeftArrow();
    shouldShowRightArrow();
  }, []);

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null;
    let divElementRefValue: HTMLDivElement | null = null;

    if (unorderedListElement.current) {
      divElementRefValue = unorderedListElement?.current;
      unorderedListElement?.current?.addEventListener('scroll', scrollBlockScrolled);
      resizeObserver = new ResizeObserver(scrollBlockScrolled);
      resizeObserver.observe(unorderedListElement?.current);
    }

    return () => {
      if (divElementRefValue) {
        divElementRefValue.removeEventListener('scroll', scrollBlockScrolled);
        resizeObserver?.disconnect();
      }
    };
  }, [oneClickFilters, scrollBlockScrolled]);

  const handleLeftClick = useCallback(() => {
    const isScrolled =
      oneClickFilterRefs?.current?.length &&
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[0]?.current?.getBoundingClientRect()?.left <
        unorderedListElement?.current?.getBoundingClientRect()?.left;
    if (isScrolled) {
      const partCards = oneClickFilterRefs.current;
      const targetPartCard =
        partCards[selectedOneClickFilterIndex > 0 ? selectedOneClickFilterIndex - 1 : 0];

      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedOneClickFilterIndex(
        selectedOneClickFilterIndex > 0 ? selectedOneClickFilterIndex - 1 : 0
      );
    }
  }, [matchesTablet, selectedOneClickFilterIndex]);

  const handleRightClick = useCallback(() => {
    const isScrollable = oneClickFilterRefs?.current?.length && unorderedListElement?.current;
    const isOverFlowing =
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[
        oneClickFilterRefs?.current?.length - 1
      ]?.current?.getBoundingClientRect()?.right >
        unorderedListElement?.current?.getBoundingClientRect()?.right;
    if (isScrollable && isOverFlowing) {
      const partCards = oneClickFilterRefs.current;
      const targetPartCard =
        partCards[
          selectedOneClickFilterIndex < oneClickFilterRefs.current.length - 1
            ? selectedOneClickFilterIndex + 1
            : oneClickFilterRefs.current.length - 1
        ];
      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedOneClickFilterIndex(
        selectedOneClickFilterIndex < oneClickFilterRefs.current.length - 1
          ? selectedOneClickFilterIndex + 1
          : oneClickFilterRefs.current.length - 1
      );
    }
  }, [matchesTablet, selectedOneClickFilterIndex]);

  if (oneClickFilters.length && oneClickFilterRefs.current.length !== oneClickFilters.length) {
    oneClickFilterRefs.current = Array(oneClickFilters.length)
      .fill(null)
      .map((_, i) => oneClickFilterRefs.current[i] || createRef());
  }
  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleRightClick(),
    onSwipedRight: () => handleLeftClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div {...swipeableHandlers}>
      <Hidden smDown>
        <Hidden mdDown>
          {oneClickFilters.length > 0 && (
            <div className={styles.filterChipsScrollContainer}>
              <div
                className={cx(styles.leftArrow, {
                  [styles.hiddenArrow]: hideLeftArrow,
                })}
                tabIndex={0}
                onClick={handleLeftClick}
                onKeyDown={(e) => {
                  if ((e || window.event).keyCode == 13) {
                    handleLeftClick();
                  }
                }}
              >
                <Image
                  className={styles.chevronImgHover}
                  src="/images/arrow_right_hover.svg"
                  alt=""
                  height={68}
                  width={57}
                />
                <Image
                  className={styles.chevronImg}
                  src="/images/arrow_right_initial.svg"
                  alt="scroll carousel left"
                  height={64}
                  width={57}
                />
              </div>
              <div
                role="group"
                aria-label={labels.lblSelectedFilters}
                className={styles.filterChipsScroll}
                ref={unorderedListElement}
              >
                <ul role="list" className={styles.filterChipsList}>
                  <ChipTags
                    chipTags={oneClickFilters}
                    handleFilter={onRemoveChipTag}
                    oneClickFilterRefs={oneClickFilterRefs}
                  />
                  {clearAllChipTag && selectedOneClickFilters.length > 1 && (
                    <li
                      className={styles.inlineListItem}
                      ref={oneClickFilterRefs?.current[oneClickFilterRefs?.current.length - 1]}
                    >
                      {clearAllChipTag}
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={cx(styles.rightArrow, {
                  [styles.hiddenArrow]: hideRightArrow,
                })}
                tabIndex={0}
                onClick={handleRightClick}
                onKeyDown={(e) => {
                  if ((e || window.event).keyCode == 13) {
                    handleRightClick();
                  }
                }}
              >
                <Image
                  className={styles.chevronImgHover}
                  src="/images/arrow_right_hover.svg"
                  alt=""
                  height={68}
                  width={57}
                />
                <Image
                  className={styles.chevronImg}
                  src="/images/arrow_right_initial.svg"
                  alt="scroll carousel right"
                  height={64}
                  width={57}
                />
              </div>
            </div>
          )}
        </Hidden>
        <div className={styles.listOptionsContainer}>
          <NumberOfResults
            firstPageRec={firstPageRec}
            recsPerPage={recsPerPage}
            totalNumRecs={totalNumRecs}
            searchText={searchText}
            removeMargin={removeMargin}
          />

          <div className={styles.resultsOptionContainer}>
            {!!showSortOptions && (
              <div className={cx(azCommonStyles['az-body-2-regular'], styles.sortByWrapper)}>
                <label
                  htmlFor="at_select_id_for_sort-by-select-field"
                  className={styles.sortByLabel}
                >
                  <Label label="label.sort.sortBy" />
                </label>
                <SortBy
                  focusItem={focusSortBy}
                  setFocus={setFocus}
                  classes={{
                    select: styles.sortBySelect,
                  }}
                  sortingOptions={sortingOptions}
                />
              </div>
            )}
            {!isBrandsPage && counterSection}
            {locale === countryCodes.us || isBR || isNewGridAndListViewForMx ? (
              <ListLayoutOptionsV2
                triggerShelfAnalytics={true}
                isList={isListView}
                toggleListView={handleListViewToggle}
              />
            ) : (
              <ListLayoutOptions
                triggerShelfAnalytics={true}
                isList={isListView}
                toggleListView={handleListViewToggle}
              />
            )}
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <FilterMobile
          refQuickFilter={refQuickFilter}
          staticFilterData={staticFilterData ?? shelfLeftNav?.[0]?.staticNavigation}
          dynamicFilterData={shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList}
          pageType={pageType}
          onFilterSelect={onFilterSelect}
          oneClickFilters={oneClickFilters}
          clearAllChipTag={clearAllChipTag}
        />
        {useNewShelfDesignMobile && (
          <Hidden mdUp>
            <View padding={[2, 4]}>
              <NumberOfResults
                firstPageRec={firstPageRec}
                recsPerPage={recsPerPage}
                totalNumRecs={totalNumRecs}
                searchText={searchText}
              />
            </View>
          </Hidden>
        )}
      </Hidden>
    </div>
  );
};
