/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { SmartLink as Link } from '../../utils/smartLink';
import DeviceWrapper from '../AZCustomComponent/DeviceWrapper';
import styles from './styles.module.scss';

const ShopByPaginationStyleLinks = ({
  data,
}: {
  data: {
    title: string;
    desktop: boolean;
    tablet: boolean;
    mobile: boolean;
    canonicalPath: string | undefined;
    taxonomyPath: string | undefined;
    pageType: string | undefined;
    shopByPaginationStyleLinks: Array<{
      name: string;
      seoUrl: string;
      taxonomyPath?: string;
      makeModelYearPath?: string;
    }>;
  };
}) => {
  const { shopByPaginationStyleLinks } = data;

  return (
    <DeviceWrapper desktop={data?.desktop} tablet={data?.tablet} mobile={data?.mobile}>
      <div className={styles.yearModelsContainer}>
        <h3 className={styles.title}>{data?.title}</h3>
        <ul className={styles.yearModelsList}>
          {shopByPaginationStyleLinks.map(({ name, seoUrl }) => {
            return (
              <li key={seoUrl} className={styles.yearModelItem}>
                <Link className={styles.yearModelLink} to={seoUrl}>
                  {name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </DeviceWrapper>
  );
};

export default ShopByPaginationStyleLinks;
