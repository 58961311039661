/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Button } from '@/components/Button';
import Image from '@/components/NextImage';
import azCommonStyles from '../../../theme/globals.module.scss';
import type { FilterButtonProps, FilterButtonWithHeaderProps } from './types';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo';

const FilterButtonWithHeader = ({ isHeading, children }: FilterButtonWithHeaderProps) => {
  return isHeading ? (
    <div role="heading" aria-level={1}>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

const FilterButton = (props: FilterButtonProps) => {
  const {
    onClick,
    label,
    icon,
    isHeading = false,
    className = '',
    id,
    classes = {},
    ...buttonProps
  } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const locale = useLocale();
  const { pageType } = usePageType();
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search ||
    pageType === pageTypes.MerchShelf;
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS && isShelfOrSearchPage;
  return (
    <FilterButtonWithHeader isHeading={isHeading}>
      <Button
        {...buttonProps}
        className={cx(styles.filterButton, {
          [styles.slimFilterButton]: useNewShelfDesignMobile,
          [className]: className,
        })}
        id={id}
        onClick={onClick}
      >
        <span
          className={cx(azCommonStyles['az-body-2-regular'], {
            [styles.filterLabel]: !useNewShelfDesignMobile,
            [styles.slimFilterLabel]: useNewShelfDesignMobile,
          })}
        >
          {label}
        </span>
        {icon && (
          <div
            className={cx({
              // @ts-expect-error fix type
              [classes.icon || '']: classes.icon,
            })}
            style={{
              minWidth: icon.width,
              height: icon.height,
            }}
            aria-hidden="true"
          >
            <Image src={icon.src} alt={icon.alt} width={icon.width} height={icon.height} />
          </div>
        )}
      </Button>
    </FilterButtonWithHeader>
  );
};

export default FilterButton;
