/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { getDecodedCookies, setCookie } from '@/utils/cookie';
import { startTransition, useCallback, useMemo, useReducer, useState } from 'react';
import { cookieConstant } from '../../../constants/cookies';
import { ProductListViewContext } from '../ProductListViewContext';

const accordionReducer = (
  state: Record<string, boolean>,
  action: { dimensionName: string; expanded: boolean }
) => {
  switch (action.dimensionName) {
    case 'clearAccordionOpenStates':
      return {};
    default:
      setCookie(
        cookieConstant.names.filterOpenStates,
        JSON.stringify({
          ...state,
          [action.dimensionName]: action.expanded,
        })
      );

      return {
        ...state,
        [action.dimensionName]: action.expanded,
      };
  }
};

const listViewReducer = (
  state: { isListView: boolean; isBrandListView: boolean },
  action: { type: string; payload: boolean }
) => {
  switch (action.type) {
    case 'TOGGLE_LIST_VIEW':
      setCookie(
        cookieConstant.names.shelfListGridView,
        action.payload
          ? cookieConstant.values.shelfListListView
          : cookieConstant.values.shelfListGridView
      );
      return { ...state, isListView: action.payload };
    case 'TOGGLE_BRAND_LIST_VIEW':
      setCookie(
        cookieConstant.names.brandShelfListGridView,
        action.payload
          ? cookieConstant.values.shelfListListView
          : cookieConstant.values.shelfListGridView
      );
      return { ...state, isBrandListView: action.payload };

    default:
      return state;
  }
};

export const ProductListViewProvider = ({
  children,
  isBrandListView = false,
  isListView = true,
  filterOpenStates = '{}',
}: {
  children: React.ReactNode;
  isBrandListView?: boolean;
  isListView?: boolean;
  filterOpenStates?: string;
}) => {
  let initialIsListView = isListView;
  let initialIsBrandListView = isBrandListView;
  let shelfListGridViewCookie;
  let brandShelfListGridViewCookie;
  let initialFilterOpenStates: string = filterOpenStates;
  if (!__IS_SERVER__) {
    const cookies = getDecodedCookies([
      cookieConstant.names.shelfListGridView,
      cookieConstant.names.brandShelfListGridView,
      cookieConstant.names.filterOpenStates,
    ]);
    shelfListGridViewCookie = cookies.get(cookieConstant.names.shelfListGridView);
    brandShelfListGridViewCookie = cookies.get(cookieConstant.names.brandShelfListGridView);
    initialFilterOpenStates = cookies.get(cookieConstant.names.filterOpenStates) || '{}';
  }
  if (shelfListGridViewCookie) {
    initialIsListView = shelfListGridViewCookie !== cookieConstant.values.shelfListGridView;
  }

  if (brandShelfListGridViewCookie) {
    initialIsBrandListView =
      brandShelfListGridViewCookie !== cookieConstant.values.shelfListGridView;
  }

  if (initialFilterOpenStates === null) {
    initialFilterOpenStates = '{}';
  }

  const initialListViewObject = {
    isListView: initialIsListView,
    isBrandListView: initialIsBrandListView,
  };

  const parsedFilterStates = JSON.parse(initialFilterOpenStates) as unknown;

  if (parsedFilterStates !== null && typeof parsedFilterStates !== 'object') {
    throw new Error('Invalid filter states');
  }

  const [isMobileFilterOpen, setIsFilterOpen] = useState(false);
  const [accordionState, accordionDispatch] = useReducer(
    accordionReducer,
    parsedFilterStates as Record<string, boolean>
  );

  const setIsMobileFilterOpen = useCallback((isOpen: boolean) => {
    startTransition(() => {
      setIsFilterOpen(isOpen);
    });
  }, []);

  const [listViewState, toggleView] = useReducer(listViewReducer, initialListViewObject);

  const toggleListView = useCallback((action: { type: string; payload: boolean }) => {
    startTransition(() => {
      toggleView(action);
    });
  }, []);

  const results = useMemo(
    () => ({
      isMobileFilterOpen,
      setIsMobileFilterOpen,
      accordionState,
      accordionDispatch,
      listViewState,
      toggleListView,
    }),
    [
      isMobileFilterOpen,
      setIsMobileFilterOpen,
      accordionState,
      accordionDispatch,
      listViewState,
      toggleListView,
    ]
  );

  return (
    <ProductListViewContext.Provider value={results}>{children}</ProductListViewContext.Provider>
  );
};
