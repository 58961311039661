/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import React, { MutableRefObject } from 'react';
import type { HandleFilterType } from '../../../types/filterTypes';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { getCurrencySymbol } from '@/utils/priceHelpers';
import { priceFilterDisplayNames } from '../priceFilterDisplayNames';
import { formatPriceFilterLabel } from '@/utils/validator/priceCommaFormat';
import { whiteCheckMark } from '@/constants/images/whiteCheckMark';
import Image from '@/components/NextImage';
import { closeIconGrey } from '@/constants/images/closeIconGrey';

type Props = {
  chipTags?: Array<HandleFilterType>;
  handleFilter: (a: HandleFilterType) => void;
  isMobileDrawer?: boolean;
  oneClickFilterRefs?: MutableRefObject<MutableRefObject<HTMLLIElement>[]>;
};

const labelMap = {
  lblRemove: 'label_cart_orderSummary_Remove',
};

const ChipTags = ({
  chipTags,
  handleFilter,
  isMobileDrawer,
  oneClickFilterRefs,
}: Props): null | React.ReactElement => {
  const { lblRemove } = useLabels(labelMap);
  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);
  const priceFilterDimensions = Object.values(priceFilterDisplayNames);

  const renderChipTag = (selectedFilter: HandleFilterType, index: number) => {
    const { displayName, refinement = {}, dimensionName, quickFilter } = selectedFilter;
    const isPriceFilter = priceFilterDimensions.includes(dimensionName?.toLowerCase());
    // @ts-expect-error fix type
    const { properties = {}, label } = refinement;
    const replaceRegex = new RegExp(`${currencySymbol}([\\d,]{4,}(?:\\.\\d+)?)`, 'g');

    const labelForUI = (displayName: any) => {
      switch (displayName) {
        case 'Rating':
          return label.length < 2 ? `${label} stars` : label;
        default:
          const formattedLabel = label.replace(replaceRegex, (match: string) => {
            const price = parseFloat(match.replace(currencySymbol, '').replace(',', ''));
            return currencySymbol + price.toLocaleString();
          });
          return isPriceFilter ? formatPriceFilterLabel(formattedLabel) : formattedLabel;
      }
    };

    const ariaLabelForChip = (labelText: string) => {
      switch (displayName) {
        case 'Rating':
          if (labelText.includes('and')) {
            return labelText.replace('and', 'stars and');
          }
          return labelText.length < 2 ? `${labelText} stars` : labelText;
        case 'Price':
          return labelText.includes('-') ? labelText.replace('-', 'to') : labelText;
        default:
          return labelText;
      }
    };
    const { filters } = properties;
    return (
      <li
        key={label}
        className={(styles.filtersChip, styles.inlineListItem)}
        ref={oneClickFilterRefs?.current[index]}
      >
        <Button
          customClass={cx({
            [styles.filterChip]: !isMobileDrawer,
            [styles.selectedFilterChip]: selectedFilter.checked && !isMobileDrawer,
            [styles.mobileDrawerFilterChip]: selectedFilter.checked && isMobileDrawer,
          })}
          ariaLabel={`${lblRemove} ${displayName} ${ariaLabelForChip(label)}`}
          key={filters}
          variant="ghost"
          onClick={() =>
            handleFilter({
              refinement: selectedFilter.refinement,
              checked: !selectedFilter.checked,
              displayName,
              dimensionName,
              quickFilter,
              selectionType: 'One Click',
            })
          }
        >
          <span className={styles.filterLabel}>{labelForUI(displayName)}</span>
          {selectedFilter.checked && !isMobileDrawer && (
            <>
              <div className={styles.selectedFilterCheckBox} />
              <div className={styles.selectedFilterCheckMark} aria-hidden="true">
                <Image
                  src={whiteCheckMark.src}
                  alt={whiteCheckMark.alt}
                  width={whiteCheckMark.width}
                  height={whiteCheckMark.height}
                />
              </div>
            </>
          )}
          {selectedFilter.checked && isMobileDrawer && (
            <img
              src={closeIconGrey.src}
              alt={closeIconGrey.alt}
              height="11"
              width="11"
              role="presentation"
            />
          )}
        </Button>
      </li>
    );
  };

  return chipTags && chipTags?.length > 0 ? (
    <>
      {chipTags?.map((selectedFilter: HandleFilterType, index) =>
        renderChipTag(selectedFilter, index)
      )}
    </>
  ) : null;
};

export default ChipTags;
