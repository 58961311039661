/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CategoryData } from '@/features/category';
import { ProductShelfResponse } from '@/features/category';
import type { ShelfPageBodyProductFinder } from '@/types/legacy';
import { isProductFinderBlock } from '@/utils/typeGuards/isProductFinderBlock';

const mapCategoryDataAttrs = (attributes: ShelfPageBodyProductFinder['ProductFinder']) => {
  return attributes.map((attribute) => ({
    attribute: attribute.attribute,
    value: attribute.value,
    description: attribute.subtext,
  }));
};

const mapShelfResultData = (
  attributes: Array<{
    vehicleProductAttributeName: string | null;
    vehicleProductAttributeValue: string | null;
    vehicleProductAttributeValueNote: string | null;
  }>
) => {
  return attributes.map((attribute) => ({
    attribute: attribute.vehicleProductAttributeName,
    value: attribute.vehicleProductAttributeValue,
    description: attribute.vehicleProductAttributeValueNote,
  }));
};

const mapCMSDataToProductAttributes = (
  apiData: ProductShelfResponse | CategoryData | undefined
) => {
  if (!apiData) {
    return [];
  }

  if ('contents' in apiData && apiData.contents !== null) {
    let productFinder;

    productFinder = apiData.contents.contents[0].shelfPageBody.find((block) =>
      isProductFinderBlock(block)
    ) as ShelfPageBodyProductFinder | undefined;

    if (!productFinder) {
      const slot = apiData.contents.contents[0].shelfPageBody.find(
        (block) => block['@type'] && block['@type'] === ('ShelfPageBody_Slot' as const)
      );

      if (slot && 'contents' in slot) {
        productFinder = slot.contents.find((block: unknown) => {
          return isProductFinderBlock(block);
        }) as ShelfPageBodyProductFinder | undefined;

        if (productFinder) {
          return mapCategoryDataAttrs(productFinder.ProductFinder);
        }
      }
    }

    return productFinder ? mapCategoryDataAttrs(productFinder.ProductFinder) : [];
  }

  if ('productShelfResults' in apiData) {
    return Array.isArray(apiData.productShelfResults?.vehicleProductAttributes)
      ? mapShelfResultData(apiData.productShelfResults?.vehicleProductAttributes ?? [])
      : [];
  }

  return [];
};

export { mapCMSDataToProductAttributes };
