/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { clickTrack } from '@/utils/analytics/clickTrack';
import { SmartLink as Link } from '@/utils/smartLink';
import React from 'react';
import { ProductInfoItemContainer } from '../ProductInfoItemContainer';
import styles from './styles.module.scss';

const trackReviewLinkShelf = () => {
  clickTrack({
    eventType: 'reviewsLinkInteractionShelf',
  });
};

type Props = {
  skuNumber: number;
  url: string;
  onProductClick?: () => void;
};

export const ProductReviews = ({ skuNumber, url, onProductClick }: Props) => {
  /* analytics Code start : review clickTrack */
  const handleReviewClick = () => {
    onProductClick?.();
    trackReviewLinkShelf();
  };
  /* analytics Code end : review clickTrack */

  return (
    <ProductInfoItemContainer data-testid="reviews-container" className={styles.reviewsContainer}>
      <Link
        to={`${url}#bv_PDPReviews`}
        scroll={false}
        onClick={handleReviewClick}
        aria-hidden="true"
        tabIndex={-1}
      >
        <div
          data-bv-seo="false"
          data-bv-show="inline_rating"
          data-bv-product-id={skuNumber}
          className={`${styles.reviews}`}
          id={`inlineRatingsBV-${skuNumber}`}
        />
      </Link>
    </ProductInfoItemContainer>
  );
};
