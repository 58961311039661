/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '@/components/Grid';
import azCommonStyles from '@/theme/globals.module.scss';
import { formatPrice, getStaticFormattedPrice } from '@/utils/priceHelpers';
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { countryCodes } from '@/constants/locale';
import { useProductList } from '../../../../../context/useProductList';
import Skeleton from '@/components/Skeleton/Skeleton';
import PriceWithSavingsInfo from './PriceWithSavingsInfo/PriceWithSavingsInfo';
import StrikeThroughPriceWrapper from '@/components/AZCustomComponent/StrikethroughPricing';
import ClearanceBadgeWrapper from '@/components/AZCustomComponent/ClearanceBadge';
import Hidden from '@/components/Hidden';
import { DealsDrawerButton } from '@/features/deals/components/DrawerOpenButton/DrawerOpenButton';
import CartCommonCounterPart from '@/components/Cart/CommonCounterPart';
import CorePrice from '@/components/AZCustomComponent/CorePrice';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { getAllAvailableQuantitiesForSku } from '@/utils/getFulfillmentOption';
import { priceCommaFormat } from '@/utils/validator/priceCommaFormat';

type Props = {
  cents: string;
  className?: string;
  dollars: string;
  isDiscountShown: boolean;
  itemPresentInCart: boolean | undefined;
  showPriceCaption: boolean;
  shippingType: string;
  prodIndex: number;
  count: number;
  corePrice?: number;
  price: string;
  handleQuantityChange: (a: number) => void;
  setFulfillmentId?: (fulfillmentId: number) => void;
  clearance: boolean | undefined;
  showDealDrawerButton: boolean;
  onDealClick: (ref: React.RefObject<HTMLButtonElement>) => void;
  isMobile: boolean;
  fulfillmentId?: number;
  priceLoaded?: boolean;
  dealConfigurableLabel?: string | null | undefined;
};

const labelMap = {
  lblAddToCartDiscount: 'label_ShelfPage_AddToCartDiscount',
  lblPriceNotAvailable: 'label_shelf_resultsList_price_not_available',
  lblSeeSavingsInCart: 'label_see_savings_in_cart',
  lblClearanceBadge: 'label_clearance_badge',
  lblPriceDollars: 'label_shelf_resultsList_price_dollars',
  lblPriceCents: 'label_shelf_resultsList_price_cents',
  lblPriceOfThisItemIs: 'label_the_price_of_item',
};

const ProductPriceComponent = ({
  cents,
  className = '',
  dollars,
  itemPresentInCart,
  isDiscountShown,
  showPriceCaption,
  shippingType,
  prodIndex,
  count,
  price,
  handleQuantityChange,
  clearance,
  showDealDrawerButton,
  onDealClick,
  isMobile,
  fulfillmentId,
  setFulfillmentId,
  priceLoaded = true,
  dealConfigurableLabel,
}: Props) => {
  const locale = useLocale();
  const labels = useLabels(labelMap);
  const hasPriceAvailable = Number(dollars) > 0 || Number(cents) >= 18;
  const { data: shelfData } = useProductList();
  const shelfPart = shelfData?.shelfParts[prodIndex];
  const corePriceForSpecificProduct = shelfPart?.skuPricingAndAvailability?.corePrice ?? 0;
  const skuPricingAndAvailability = shelfPart?.skuPricingAndAvailability;
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const strikethroughPrice = skuPricingAndAvailability?.wasRetailPrice;
  const discountedPrice = skuPricingAndAvailability?.discountedPrice;
  const eligibleForNextDay = shelfPart?.eligibleForNextDay;
  const availabilityInfoVO = shelfPart?.availabilityInfoVO;
  const discountedPriceMiniCart = discountedPrice ? parseFloat(discountedPrice) : 0;
  const staticPrice = getStaticFormattedPrice(
    skuPricingAndAvailability?.configurableSku,
    skuPricingAndAvailability
  );
  let priceDollars: string;
  let priceCents: string;
  const showDiscountedPrice = strikeThroughPricingEnabled && !!discountedPrice;
  if (strikeThroughPricingEnabled && discountedPrice) {
    const discountedPriceByQty = parseFloat(discountedPrice) * (count === 0 ? 1 : count);
    priceDollars = formatPrice(discountedPriceByQty)?.toString()?.split('.')[0] ?? '0';
    priceCents = formatPrice(discountedPriceByQty)?.toString()?.split('.')[1] ?? '00';
  } else {
    priceDollars = formatPrice(parseInt(price))?.toString()?.split('.')[0] ?? '0';
    priceCents = price?.split('.')[1] ?? '00';
  }

  const ariaLabelForPrice = `${labels.lblPriceOfThisItemIs} ${priceDollars} ${labels.lblPriceDollars} ${priceCents} ${labels.lblPriceCents}`;
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled ?? false;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const configurableSku = skuPricingAndAvailability?.configurableSku;
  const seeDealsButtonRef = React.useRef<HTMLButtonElement>(null);
  const counterData = {
    itemQuantity: count,
    orderType: shippingType,
    eligibleForNextDay,
    productInfo: {
      skuId: availabilityInfoVO?.skuId,
    },
    unitAmount: Number(staticPrice),
    lineItemPriceInfo: {
      unitPrice: Number(staticPrice),
    },
  };
  const displayCounter = count > 0 && (locale !== countryCodes.mx || isBopusMexicoStoreEnabled);

  const updatedDiscountedPrice =
    discountedPrice && parseFloat(discountedPrice) * (count === 0 ? 1 : count);

  const showCartDiscountCaption = () => {
    if (locale === countryCodes.mx) {
      return isDiscountShown && !dealDrawerShelfEnabled && !strikeThroughPricingEnabled;
    } else {
      return isDiscountShown && !dealDrawerShelfEnabled;
    }
  };
  const selectedFulfillmentOption =
    skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
      (option) => option.fulfillmentTypeId === fulfillmentId
    ) ??
    skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
      (option) => option.selected
    );

  const isAvailable =
    selectedFulfillmentOption?.available ||
    (fulfillmentId === FULFILLMENT_METHODS.STOREORDER &&
      selectedFulfillmentOption?.sisterStoreAvailable);
  const sisterStoreAvailable =
    shelfPart?.fulfillmentOptions?.[0]?.groupFulfillmentOptions[0]?.sisterStoreAvailable ?? false;
  const skuAvailableQuantities = getAllAvailableQuantitiesForSku(shelfPart?.fulfillmentOptions);
  const getStrikeThroughPrice = (): {
    price: string | number | undefined;
    wasPrice: string | number | undefined;
  } => {
    if (strikeThroughPricingEnabled && discountedPrice) {
      return { price: updatedDiscountedPrice as number, wasPrice: price };
    }
    if (strikethroughPrice) {
      return { price: staticPrice, wasPrice: strikethroughPrice };
    }

    return { price: undefined, wasPrice: undefined };
  };

  const { price: strikePrice, wasPrice: strikeWasPrice } = getStrikeThroughPrice();

  return (
    <Grid alignItems="flex-start" className={className} container spacing={0}>
      {!hasPriceAvailable &&
        locale !== countryCodes.mx &&
        (priceLoaded ? (
          <Grid item>
            <div className={cx(azCommonStyles['az-subtitle-2-medium'], styles.noPriceContainer)}>
              {labels.lblPriceNotAvailable || ''}
            </div>
          </Grid>
        ) : (
          <Grid item>
            <Skeleton animation="wave" height={25} width={75} variant="rect" />
          </Grid>
        ))}
      {hasPriceAvailable && locale === countryCodes.mx && (
        // this is for the old shelf version with the counter
        <>
          <Grid item xs={12}>
            {strikeThroughPricingEnabled && discountedPrice && (
              <StrikeThroughPriceWrapper
                price={strikePrice as number}
                wasPrice={strikeWasPrice as string}
              />
            )}
            <div
              className={
                displayCounter
                  ? `${styles.priceDetailContainer} ${styles.priceContainerWithQuantityComponent}`
                  : `${styles.priceDetailContainer}`
              }
              data-testid="product-price-container"
            >
              {dollars && (
                <div
                  className={cx(azCommonStyles['az-title-1-heavy'], styles.priceContainer)}
                  data-testid="price-container-dollars"
                  aria-label={ariaLabelForPrice}
                >
                  {showPriceCaption && (
                    <span
                      aria-hidden="true"
                      className={cx(
                        configurableSku ? styles.startingAt : styles.prefix,
                        styles.lowercase
                      )}
                    >
                      Starting at
                    </span>
                  )}
                  <span
                    aria-hidden="true"
                    className={strikethroughPrice ? styles.strikePrefix : styles.prefix}
                  >
                    $
                  </span>
                  <span
                    aria-hidden="true"
                    className={strikethroughPrice ? styles.strikePrice : styles.price}
                  >
                    {priceCommaFormat(priceDollars.replace('$', ''))}
                  </span>
                  <span aria-hidden="true" className={styles.centSymbol}>
                    .
                  </span>
                  <span
                    aria-hidden="true"
                    className={strikethroughPrice ? styles.strikeDec : styles.dec}
                  >
                    {priceCents}
                  </span>
                </div>
              )}
              <div className={cx(azCommonStyles['az-margin-top-5xs'])}>
                {clearance && <ClearanceBadgeWrapper />}
                {strikethroughPrice && (
                  <Hidden only={['sm']} className={cx(azCommonStyles['az-margin-top-xxs'])}>
                    <StrikeThroughPriceWrapper
                      price={strikePrice as string}
                      wasPrice={strikeWasPrice as string}
                    />
                  </Hidden>
                )}
              </div>
            </div>
            {isMobile && showDealDrawerButton && dealDrawerShelfEnabled && !discountedPrice && (
              <div className={cx(styles.dealsDrawerWrapper)}>
                <DealsDrawerButton
                  seeDealsButtonRef={seeDealsButtonRef}
                  handleClick={onDealClick}
                  dealConfigurableLabel={dealConfigurableLabel}
                  customClass=""
                />
              </div>
            )}
            {clearance && strikethroughPrice && (
              <Hidden only={['lg', 'md', 'xl']}>
                <div>
                  {
                    <StrikeThroughPriceWrapper
                      price={strikePrice as string}
                      wasPrice={strikeWasPrice as string}
                    />
                  }
                </div>
              </Hidden>
            )}
            <div
              className={
                displayCounter
                  ? `${styles.priceDetailContainer} ${styles.priceContainerWithQuantityComponent}`
                  : `${styles.priceDetailContainer}`
              }
            ></div>
            <div
              className={
                displayCounter
                  ? `${styles.priceDetailContainer} ${styles.priceContainerWithQuantityComponent}`
                  : `${styles.priceDetailContainer}`
              }
            >
              {showCartDiscountCaption() && (
                <div className={styles.addToCartDiscount}>
                  {itemPresentInCart ? labels.lblSeeSavingsInCart : labels.lblAddToCartDiscount}
                </div>
              )}
            </div>
            {isBopusMexicoStoreEnabled && !isMobile && (
              <CorePrice corePrice={corePriceForSpecificProduct} />
            )}

            {isBopusNewMsgApplicable
              ? displayCounter &&
                !configurableSku &&
                itemPresentInCart && (
                  <Grid
                    item
                    xs={12}
                    className={cx(styles.partWrapper, {
                      [styles.quantityWithCorePrice]: !!corePriceForSpecificProduct,
                    })}
                  >
                    <CartCommonCounterPart
                      data={counterData}
                      fulfillmentId={fulfillmentId}
                      corePrice={corePriceForSpecificProduct}
                      discountedStrikeThroughPrice={discountedPriceMiniCart}
                      availabilityCheck={skuAvailableQuantities}
                      sisterStoreAvailable={sisterStoreAvailable}
                      handleQuantityChange={handleQuantityChange}
                      pageType={'test'}
                      setFulfillmentId={setFulfillmentId}
                      isNewMessagingShown={false}
                      disabled={!isAvailable}
                      isMini
                    />
                  </Grid>
                )
              : displayCounter &&
                !configurableSku && (
                  <Grid item xs={12} className={styles.partWrapper}>
                    <CartCommonCounterPart
                      data={counterData}
                      fulfillmentId={fulfillmentId}
                      corePrice={corePriceForSpecificProduct}
                      discountedStrikeThroughPrice={discountedPriceMiniCart}
                      availabilityCheck={skuAvailableQuantities}
                      sisterStoreAvailable={sisterStoreAvailable}
                      handleQuantityChange={handleQuantityChange}
                      setFulfillmentId={setFulfillmentId}
                      pageType={'test'}
                      isNewMessagingShown={false}
                      disabled={!isAvailable}
                      isMini
                    />
                  </Grid>
                )}
          </Grid>
        </>
      )}
      {hasPriceAvailable && (locale === countryCodes.us || locale === countryCodes.ptBr) && (
        // this is for the new version without the counter
        <PriceWithSavingsInfo
          strikePrice={strikePrice}
          strikeWasPrice={strikeWasPrice}
          showDiscountedPrice={showDiscountedPrice}
          configurableSku={configurableSku}
          showPriceCaption={showPriceCaption}
          dollars={dollars}
          cents={cents}
          clearance={clearance}
          showCartDiscountCaption={showCartDiscountCaption}
          itemPresentInCart={itemPresentInCart}
          corePriceForSpecificProduct={corePriceForSpecificProduct}
          isBopusMexicoStoreEnabled={isBopusMexicoStoreEnabled}
          isMobile={isMobile}
          showDealDrawerButton={showDealDrawerButton}
          dealDrawerShelfEnabled={dealDrawerShelfEnabled}
          onDealClick={onDealClick}
          seeDealsButtonRef={seeDealsButtonRef}
          dealConfigurableLabel={dealConfigurableLabel}
        />
      )}
    </Grid>
  );
};

export const ProductPrice = React.memo(ProductPriceComponent);
