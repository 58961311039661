/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FilterDataType, HandleFilterType } from '../../../types/filterTypes';

export const getSelectedFilters = (
  filter: FilterDataType,
  selectedFiltersArray: Array<HandleFilterType>
) => {
  filter?.refinements?.map((refinement) => {
    if (
      refinement.properties?.isSelected === 'true' ||
      refinement.properties?.isSelected === true
    ) {
      selectedFiltersArray.push({
        refinement,
        checked: true,
        displayName: filter.displayName,
        dimensionName: filter.dimensionName,
        quickFilter: filter.quickFilter,
      });
    }
  });
};
