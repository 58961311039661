/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import Head from 'next/head';
import { useLabel } from '@/hooks/useLabels';
import styles from '../viewStyles.module.scss';
type Props = {
  noPartsFoundLabel: string;
  vehicleName?: string;
};

const NoPartFound = ({ noPartsFoundLabel, vehicleName = '' }: Props) => {
  const productsAndVehicleNotAvailable = useLabel('Label_TheseProductsAreCurrentlyNotAvailable');

  return (
    <Fragment>
      <Head>
        <meta key="metaRobots" name="robots" content="noindex, nofollow" />
      </Head>
      {vehicleName ? (
        <div className={styles.noResults} data-testid="avail">
          {`${noPartsFoundLabel}`}
          <strong>{` ${vehicleName}`}.</strong>
        </div>
      ) : (
        <div className={styles.noResults} data-testid="avail">
          {productsAndVehicleNotAvailable}
        </div>
      )}
      <hr />
    </Fragment>
  );
};

export { NoPartFound };
