/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Head from 'next/head';
import { CMSBanner } from '../CMSBanner/CMSBanner';
import { mapCitrusBannerToContentStackBanner } from '@/features/citrus/utils/mapCitrusBannerToContentStackBanner';
import { ContentStackCitrusAd } from '../../interface';
import { useCitrusShelfData } from '@/features/citrus/api/getCitrusShelfData';
import { CMSShelfSpacer } from '../CMSShelfSpacer/CMSShelfSpacer';
import { useFeatureFlag } from '@/features/globalConfig';
import { isCitrusBannerWithLink } from '@/features/citrus/utils/isCitrusBannerWithLink';
import { extractOrigin } from '../../utils/extractOrigin';

type Props = {
  content: Omit<ContentStackCitrusAd, 'title'>;
};

const sizePerDevice = {
  desktop: {
    width: 1166,
    height: 110,
  },
  mobile: {
    width: 668,
    height: 250,
  },
  tablet: {
    width: 1100,
    height: 115,
  },
};

export function CMSCitrusShelf({ content }: Props) {
  const citrusShelfBannersEnabled = useFeatureFlag('IS_CITRUS_SHELF_BANNERS_ENABLED') === 'true';
  const { data: citrusData } = useCitrusShelfData({ enabled: citrusShelfBannersEnabled });

  const bannersToDisplay = citrusData?.ads.find((adSlot) => adSlot.slotId === content.slot_id);
  const citrusImgOrigin =
    !!bannersToDisplay && extractOrigin(bannersToDisplay?.banners[0]?.backgroundImage);

  return (
    <>
      {citrusImgOrigin ? (
        <Head>
          <link key="citrus-preconnect" rel="preconnect" href={citrusImgOrigin} />
        </Head>
      ) : null}
      {bannersToDisplay?.banners.map((item, i) =>
        isCitrusBannerWithLink(item) ? (
          <CMSShelfSpacer key={i} removeHorizontalSpacing={{ s: true, l: true }}>
            <CMSBanner
              key={i}
              content={mapCitrusBannerToContentStackBanner(item)}
              sponsored
              citrusInfo={{ citrusAdId: item.citrusAdId }}
              legalPadding={{ s: true, m: true, l: false }}
              priority
              sizePerDevice={sizePerDevice}
            />
          </CMSShelfSpacer>
        ) : null
      )}
    </>
  );
}
