/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Modal from '@/components/Modal';
import { WarrantyAzModal } from '../ProductAzWarranty';
import { WarrantyProformanceModal } from '../ProductProformanceWarranty';
import BatteryWarranty from './BatteryWarranty';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

type Props = {
  showWarranty: boolean;
  closeWarrantyModal: () => void;
  warrantyType: string | undefined;
};

export const WarrantyModal = (props: Props) => {
  const locale = useLocale();
  const { warrantyType, closeWarrantyModal, showWarranty, ...rest } = props;

  const getModalType = () => {
    if (locale === countryCodes.ptBr) {
      return <WarrantyAzModal />;
    }

    switch (warrantyType) {
      case 'Battery':
        return locale === countryCodes.mx ? (
          <WarrantyAzModal />
        ) : (
          <BatteryWarranty
            {...rest}
            showWarranty={showWarranty}
            closeWarrantyModal={closeWarrantyModal}
          />
        );

      case 'AutoZone':
        return <WarrantyAzModal />;

      case 'PROFormance':
        return <WarrantyProformanceModal />;

      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={showWarranty}
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
      desktopSize="large"
      tabletSize="fullscreen"
      handleClose={closeWarrantyModal}
      showCloseButton
      heading=""
      body={getModalType()}
      ariaDescription="modal-title"
    />
  );
};
