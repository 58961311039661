/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { CMSSpacer, type CMSSpacerProps } from '../CMSSpacer/CMSSpacer';

type Props = {
  children: React.ReactNode;
} & CMSSpacerProps;

const CMSShelfSpacer = ({ children, ...props }: Props) => {
  return (
    <CMSSpacer {...props} shelfPage>
      {children}
    </CMSSpacer>
  );
};

export { CMSShelfSpacer };
