/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { setFilterScrollBrand, setProductFilterEvents } from '../../../actions/shelf/shelf';
import { useRouter } from 'next/router';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import { useGetCustomPriceFilterLabel } from '../../../hooks/useGetCustomPriceFilterLabel';
import { useFacetedUrlEnabled } from '../../../hooks/useFacetedUrlEnabled';
import FormikTextField from '../../AZCustomComponent/FormGroup/TextField/formikTextField';
import { Button } from '@/components/Button';
import { Grid } from '../../Grid';
import { FilterSelectionType, RefinementType } from '../../../types/filterTypes';
import styles from './styles.module.scss';
import { priceFilterDisplayNames } from '@/components/Filter/priceFilterDisplayNames';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { countryCodes, currencyLocales } from '@/constants/locale';
import { getCurrencySymbol } from '@/utils/priceHelpers';
import { useGetFilterAnalytics } from '@/hooks/useGetFilterAnalytics';
import { sessionStorage } from '@/utils/sessionStorage';

type CustomPriceRangeFormStateType = {
  minPriceRange: string;
  maxPriceRange: string;
};

const CustomPriceRangeFormState: CustomPriceRangeFormStateType = {
  minPriceRange: '',
  maxPriceRange: '',
};

const labelMap = {
  lblSetCustomPriceRange: 'label_ShelfPage_leftSideBar_SetCustomPriceRange',
  lblTo: 'label_ShelfPage_leftSideBar_toInLowercase',
  lblApply: 'label_ShelfPage_leftSideBar_Apply',
  lblClearCustomPriceRange: 'label_ShelfPage_leftSideBar_clearCustomPriceRange',
  lblPriceCustom: 'label_Price_Custom',
  lblPriceMax: 'label_Price_Max',
  lblPriceMin: 'label_Price_Min',
};

type Props = {
  quickFilter?: boolean;
};

const CustomPriceRange = ({ quickFilter = false }: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const labels = useLabels(labelMap);
  const getCustomPriceFilterLabel = useGetCustomPriceFilterLabel();
  const getFilterAnalytics = useGetFilterAnalytics();
  const facetedUrlEnabled = useFacetedUrlEnabled();
  const [formvalues, setFormValues] = useState(CustomPriceRangeFormState);
  const { asPath } = router;
  const { query = {} } = parseUrl(asPath);
  const customPriceFilterApplied = query.minPrice || query.maxPrice;
  const locale = useLocale();
  const currencyFormatter = useMemo(() => {
    let formatByLocale = locale;
    if (locale === countryCodes.ptBr) {
      // brazil format with comma as decimal separator
      formatByLocale = countryCodes.enUS;
    }

    return Intl.NumberFormat(formatByLocale, {
      style: 'currency',
      currency: currencyLocales[locale],
      minimumFractionDigits: 0,
    });
  }, [locale]);
  const currencySymbol = getCurrencySymbol(locale);
  const { data: shelfData } = useProductList();
  const shelfLeftNavigation = shelfData?.shelfLeftNav;

  const priceFilterIds = shelfLeftNavigation?.[0]?.staticNavigation
    ?.find(
      (filter) =>
        filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceUS ||
        filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceMX
    )
    ?.refinements?.map((priceFilter: RefinementType) => priceFilter.properties.filters);
  useEffect(() => {
    const { query = {} } = parseUrl(asPath);
    const parsedMinValue = parseInt(query.minPrice);
    const parsedMaxValue = parseInt(query.maxPrice);
    setFormValues((prevState) => ({
      ...prevState,
      minPriceRange: Number.isInteger(parsedMinValue)
        ? currencyFormatter.format(parsedMinValue)
        : '',
      maxPriceRange: Number.isInteger(parsedMaxValue)
        ? currencyFormatter.format(parsedMaxValue)
        : '',
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  const normalizePrice: (value: string) => string = (value: string) => {
    let onlyDigitsValue = value.replaceAll(/[^0-9]/gi, '');

    if (onlyDigitsValue) {
      onlyDigitsValue = currencyFormatter.format(parseInt(onlyDigitsValue));
    }

    return onlyDigitsValue;
  };

  const stripSpecialCharsFromPrice = (price: string) => {
    if (price) {
      return price.replace(currencySymbol, '').replace(/[,\s]/g, '');
    }

    return '';
  };

  const removeListedPriceFilters = (queryOrUrlString: string) => {
    let newString = queryOrUrlString || '';
    priceFilterIds?.forEach((priceFilterId: string) => {
      if (newString.includes(priceFilterId)) {
        newString = newString
          .replace(`-${priceFilterId}`, '')
          .replace(`${priceFilterId}-`, '')
          .replace(priceFilterId, '');
      }
    });
    return newString;
  };

  const removeAppliedPriceFiltersFromUrl = () => {
    const facetedUrlRegex = /(\/bn\/|\/b\/).*/;
    const { url, query } = parseUrl(asPath);
    let tempUrl = url;

    if (facetedUrlEnabled) {
      tempUrl = removeListedPriceFilters(tempUrl);
      const shouldClearFacetedUrl = /(\/bn\/\?|\/b\/\?|\/bn\/$|\/b\/$).*/.test(tempUrl);

      if (shouldClearFacetedUrl) {
        tempUrl = tempUrl.replace(facetedUrlRegex, '');
      }

      if (query.filters) {
        delete query.filters;
      }
    } else {
      query.filters = removeListedPriceFilters(String(query.filters || ''));

      if (query.filters === '') {
        delete query.filters;
      }
    }

    tempUrl = stringifyUrl(
      {
        url: tempUrl,
        query: {
          ...query,
          seourl: null,
          seoUrlPath: null,
          pageNumber: null,
          currentPage: null,
        },
      },
      {
        skipNull: true,
      }
    );
    return tempUrl;
  };

  const dispatchAnalytics = (
    minPrice: string | undefined | null,
    maxPrice: string | undefined | null,
    selected: boolean
  ) => {
    const filterName = getCustomPriceFilterLabel(true, minPrice, maxPrice);
    dispatch(setProductFilterEvents(filterName, 'CustomPrice', selected, quickFilter));
    const filterDataArray = filterName.split(': ');
    const filterObject: FilterSelectionType = {
      type: filterDataArray[0],
      facet: filterDataArray[1],
      value: filterDataArray[2],
    };

    const filterAnalytics = getFilterAnalytics(filterObject, selected);
    sessionStorage.setItem('filterSelectionAnalytics', filterAnalytics);
    if (!filterAnalytics.length) {
      sessionStorage.removeItem('filterSelectionAnalytics');
    }
  };

  const handleApplyCustomPriceFilters = (formData: CustomPriceRangeFormStateType) => {
    const cleanedUrl = removeAppliedPriceFiltersFromUrl();
    const { url: newUrl, query: updatedQuery } = parseUrl(cleanedUrl);
    const formDataHasValues = !!Object.keys(formData).length;
    const minPrice = stripSpecialCharsFromPrice(formData.minPriceRange) || null;
    const maxPrice = stripSpecialCharsFromPrice(formData.maxPriceRange) || null;

    /*Analytics Start*/

    if (!formDataHasValues) {
      dispatchAnalytics(updatedQuery.minPrice, updatedQuery.maxPrice, false);
    } else {
      dispatchAnalytics(minPrice, maxPrice, true);
    }
    /*Analytics End*/

    const newQuery = {
      ...updatedQuery,
      minPrice: minPrice,
      maxPrice: maxPrice,
    };
    const filteredUrl = stringifyUrl(
      {
        url: newUrl,
        query: newQuery,
      },
      {
        skipNull: true,
      }
    );
    dispatch(setFilterScrollBrand(true));
    router.push(filteredUrl);
  };

  return (
    <div className={styles.customPriceWrapper} id="customPriceWrapper">
      <span className={styles.customPriceLabel}>{labels.lblSetCustomPriceRange}</span>
      <div className={styles.customPriceContainer}>
        <Formik
          enableReinitialize
          initialValues={formvalues}
          onSubmit={(formData) => handleApplyCustomPriceFilters(formData)}
        >
          {({ dirty, values }) => (
            <Form>
              <Grid container spacing={0} alignItems="center">
                <Grid item>
                  <Field
                    inputMode="numeric"
                    name="minPriceRange"
                    component={FormikTextField}
                    id="min_price_range"
                    placeholder={`${currencySymbol}0`}
                    classes={{
                      input: styles.priceFieldInput,
                    }}
                    normalize={normalizePrice}
                  />
                </Grid>
                <span className={styles.toLabel}>{labels.lblTo}</span>
                <Grid className={styles.maxPriceInput} item>
                  <Field
                    inputMode="numeric"
                    name="maxPriceRange"
                    component={FormikTextField}
                    id="max_price_range"
                    placeholder="Max"
                    classes={{
                      input: styles.priceFieldInput,
                    }}
                    normalize={normalizePrice}
                  />
                </Grid>
                {dirty && (values.minPriceRange || values.maxPriceRange) && (
                  <Button
                    id="applyCustomPriceBtn"
                    variant="ghost"
                    type="submit"
                    customClass={styles.applyCustomPriceBtn}
                  >
                    {labels.lblApply}
                  </Button>
                )}
                {customPriceFilterApplied && (
                  <div className={styles.clearCustomPriceBtnContainer}>
                    <Button
                      id="clearCustomPriceBtn"
                      variant="ghost"
                      type="button"
                      customClass={styles.clearCustomPrice}
                      onClick={() => {
                        // @ts-expect-error fix type
                        handleApplyCustomPriceFilters({});
                      }}
                    >
                      {labels.lblClearCustomPriceRange}
                    </Button>
                  </div>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CustomPriceRange;
