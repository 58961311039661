/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useDeviceWrapper } from './useDeviceWrapper';
import { getCartridgeType } from '@/utils/getCartridgeType';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';

export function useCustomPriceFilterEnabled() {
  const { data: shelfData } = useProductList();
  const { isCSProductListingPageEnabled } = useContentStackPLPData({ enabled: true });

  const shelfLeftNavigation = shelfData?.shelfLeftNav;
  const customPriceRangeFilter = shelfLeftNavigation?.find(
    (item: any) => getCartridgeType(item['@type']) === 'CustomPriceRangeFilter'
  );
  const shouldShowXMPriceFilter = useDeviceWrapper(customPriceRangeFilter);
  return isCSProductListingPageEnabled || shouldShowXMPriceFilter;
}
