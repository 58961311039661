/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Features
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
// Components
import { FulfillmentStates } from './FulfillmentStates';
// Constants
import { FULFILLMENT_OPTION_TO_RENDER } from '@/constants/fulfillmentConstants';

type IsStorePickupProps = {
  item: FulfillmentGroup;
  address: string | undefined;
  openSisterStore: () => void;
  toggleStoreModal: () => void;
  numberOfSisterStoreAvailable: number;
};

export const IsStorePickup = ({
  item,
  address,
  openSisterStore,
  toggleStoreModal,
  numberOfSisterStoreAvailable,
}: IsStorePickupProps): JSX.Element => {
  const isStoreSelected = useIsStoreSelected();

  if (!isStoreSelected) {
    return (
      <FulfillmentStates
        fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.NO_STORE_SELECTED}
        toggleStoreModal={toggleStoreModal}
      />
    );
  }
  if (
    !item.available &&
    !item.groupFulfillmentOptions.some((option) => option.sisterStoreAvailable)
  ) {
    return (
      <FulfillmentStates
        fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE}
      />
    );
  } else if (
    !item.available &&
    item.groupFulfillmentOptions.some((option) => option.sisterStoreAvailable)
  ) {
    return (
      <FulfillmentStates
        fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE_IN_STORE}
        address={address}
        openSisterStore={openSisterStore}
        numberOfSisterStoreAvailable={numberOfSisterStoreAvailable}
      />
    );
  } else {
    return (
      <FulfillmentStates
        fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.BOPUS_AVAILABLE_IN_STORE}
        address={address}
        toggleStoreModal={toggleStoreModal}
      />
    );
  }
};
