/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import React from 'react';
import cx from 'classnames';
// Components
import { AvailabilitySkeletonLoader } from '@/components/Skeletons/NewFulfillmentSkeleton/AvailabilitySkeletonLoader';
import { Grid } from '../../Grid';
import Skeleton from '../../Skeleton/Skeleton';
// Styles
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from '../styles.module.scss';
import Hidden from '@/components/Hidden';

type NewProductAvailabilitySkeletonProps = {
  isList: boolean;
  finalNotes?: string;
};

export const NewProductAvailabilitySkeleton = ({
  isList,
  finalNotes,
}: NewProductAvailabilitySkeletonProps) => {
  return (
    <>
      <Hidden mdUp implementation="js">
        <div className={`${styles.newFitmentLoader} ${styles.newMobileFitmentLoader}`} />
      </Hidden>
      <Hidden smDown implementation="js">
        <div
          className={cx(azCommonStyles['az-title-1-heavy'], styles.priceContainer)}
          data-testid="priceContainer"
        >
          {isList ? (
            <Hidden smDown implementation="js">
              <div className={styles.newFulfillmentViewPrice}>
                <Skeleton
                  animation="wave"
                  height={40}
                  variant="rect"
                  data-testid="skeleton-loader"
                />
              </div>
            </Hidden>
          ) : (
            <Grid container spacing={0}>
              <Grid item md={12} lg={12}>
                <div className={`${styles.priceLoader} ${styles.noPadding}`}>
                  <Skeleton
                    animation="wave"
                    height={24}
                    variant="rect"
                    data-testid="skeleton-loader"
                  />
                </div>
                <div className={`${styles.priceLoader} ${styles.noPadding}`}>
                  <Skeleton
                    animation="wave"
                    height={24}
                    variant="rect"
                    width="50%"
                    data-testid="skeleton-loader"
                  />
                </div>
              </Grid>
              <div
                className={`${styles.newFulfillmentViewPrice} ${styles.newGridPrice} ${styles.noPadding}`}
              >
                <Skeleton
                  animation="wave"
                  height={42}
                  variant="rect"
                  width={66}
                  data-testid="skeleton-loader"
                />
              </div>
            </Grid>
          )}
        </div>
      </Hidden>
      <AvailabilitySkeletonLoader />
      <div className={styles.newButtonLoader} />
      {!isList && !Boolean(finalNotes) ? (
        <div className={styles.mobileNotesLoader}>
          <Skeleton animation="wave" height={24} variant="rect" data-testid="skeleton-loader" />
        </div>
      ) : null}
    </>
  );
};
