/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import CustomExpandMoreIcon from '@/public/images/select-chevron-down.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import FilterDataRefinement from '../FilterDataRefinement';
import type { FilterDataType, HandleFilterType } from '../../../types/filterTypes';
import { useFilterAndRedirect } from '../../../hooks/useFilterAndRedirect';
import { useShowCustomPriceRangeFilter } from '../../../hooks/useShowCustomPriceRangeFilter';
import CustomPriceRange from '../../AZCustomComponent/CustomPriceRange';
import FilterSubHeading from './../FiltersubHeading';
import styles from './styles.module.scss';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import CustomizedExpansionPanel from '@/components/AZCustomComponent/ExpansionPanel';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useIsAccordionOpen } from '@/features/shelf/hooks/useIsAccordionOpen';
import { priceFilterDisplayNames } from '../priceFilterDisplayNames';
import { countryCodes } from '@/constants/locale';
import { usePriceHiding } from '@/hooks/usePriceHiding';

const labelMap = {
  lblFilterResult: 'label_ShelfPage_body_FilterResults',
  lblWorkingOn: 'label_ShelfPage_leftSideBar_WorkingOn',
  lblSetCustomPriceRangeHeading: 'label_ShelfPage_leftSideBar_SetCustomPriceRangeHeading',
  lblFilter: 'label_ShelfPage_leftSideBar_Filter',
};

export type Props = {
  staticFilterData: FilterDataType[] | undefined | null;
  dynamicFilterData: FilterDataType[] | undefined | null;
  pageType: string;
  onFilterSelect?: (a: HandleFilterType) => void;
};

const FilterDesktopComponent = (props: Props) => {
  const { staticFilterData, dynamicFilterData, pageType, onFilterSelect } = props;
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const isMxSite = locale === countryCodes.mx;
  const handleFilterAndRedirect = useFilterAndRedirect();
  const isStoreSelected = useIsStoreSelected();
  const showCustomPriceRangeFilter = useShowCustomPriceRangeFilter();
  const hidePricing = usePriceHiding();
  const { data: headerData } = useHeaderData();
  const isAccordionOpen = useIsAccordionOpen();
  const hasVehicle = headerData?.vehicleMap?.vehicleId !== '';
  const { accordionDispatch } = useProductListView();

  return (
    <aside data-testid="desktop-filters" className={styles.filterSidebar}>
      <ul>
        {showCustomPriceRangeFilter && !hidePricing && (
          <li key={`${labels.lblSetCustomPriceRangeHeading}`}>
            <CustomizedExpansionPanel
              key={`${labels.lblSetCustomPriceRangeHeading}`}
              theme="white"
              title={`${labels.lblSetCustomPriceRangeHeading} ${labels.lblFilter}`}
              expandIcon={
                <CustomExpandMoreIcon
                  aria-hidden
                  className={cx(iconStyles.defaultIconStyle, styles.customExpandMoreIconDesktop)}
                />
              }
              defaultExpandedProp={true}
              onChange={(expanded: boolean) => {
                accordionDispatch({
                  dimensionName: labels.lblSetCustomPriceRangeHeading,
                  expanded,
                });
              }}
              summary={
                <FilterSubHeading
                  classes={{
                    root: styles.filterSubheadingContainer,
                    firstTextDesktop: styles.customFilterSubHeading,
                  }}
                  title={labels.lblSetCustomPriceRangeHeading}
                />
              }
              classes={{
                summary: styles.filterWrapper,
              }}
              content={
                <div className={styles.customPriceRangeContainer}>
                  <CustomPriceRange />
                </div>
              }
            />
          </li>
        )}
        {staticFilterData?.map((filterItem, index) => {
          const filterName = filterItem.dimensionName?.toLowerCase();
          const isMxPriceFilter =
            filterName === priceFilterDisplayNames.priceMX ||
            (isMxSite && filterName === priceFilterDisplayNames.priceSearchUS);
          const isBrPriceFilter =
            locale === countryCodes.ptBr && filterName === priceFilterDisplayNames.priceSearchUS;

          if (hidePricing && filterName.includes('price')) {
            return;
          }

          if (
            (isBrPriceFilter && !isStoreSelected) ||
            (isMxPriceFilter && !isStoreSelected) ||
            (filterItem.refinements.length === 1 && filterItem.refinements[0]?.label === '0') ||
            filterItem.refinements.length === 0
          ) {
            return;
          } else {
            return (
              <li key={`${filterItem.displayName}${filterItem.dimensionName}`}>
                <CustomizedExpansionPanel
                  key={`${filterItem.displayName}${filterItem.dimensionName}`}
                  theme="white"
                  title={`${filterItem.displayName} ${labels.lblFilter}`}
                  expandIcon={
                    <CustomExpandMoreIcon
                      aria-hidden
                      className={cx(
                        iconStyles.defaultIconStyle,
                        styles.customExpandMoreIconDesktop
                      )}
                    />
                  }
                  defaultExpandedProp={isAccordionOpen(
                    filterItem,
                    showCustomPriceRangeFilter,
                    index
                  )}
                  onChange={(expanded: boolean) => {
                    accordionDispatch({ dimensionName: filterItem.dimensionName, expanded });
                  }}
                  summary={
                    <FilterSubHeading
                      classes={{
                        root: styles.filterSubheadingContainer,
                        firstTextDesktop: styles.customFilterSubHeading,
                      }}
                      title={filterItem.displayName}
                    />
                  }
                  classes={{
                    summary: styles.filterWrapper,
                  }}
                  content={
                    <FilterDataRefinement
                      filterItem={{ ...filterItem, quickFilter: false }}
                      handleFilter={(filter) => handleFilterAndRedirect(filter, onFilterSelect)}
                      pageType={pageType}
                      showFilterSubHeading={false}
                      classes={{ root: styles.customFilterCategoryWrapper }}
                      hasVehicle={hasVehicle}
                    />
                  }
                />
              </li>
            );
          }
        })}
        {dynamicFilterData?.map((filterItem, index) => (
          <li key={`${filterItem.displayName}${filterItem.dimensionName}`}>
            <CustomizedExpansionPanel
              key={`${filterItem.displayName}${filterItem.dimensionName}`}
              theme="white"
              title={`${filterItem.displayName} ${labels.lblFilter}`}
              expandIcon={
                <CustomExpandMoreIcon
                  aria-hidden
                  className={cx(iconStyles.defaultIconStyle, styles.customExpandMoreIconDesktop)}
                />
              }
              defaultExpandedProp={isAccordionOpen(
                filterItem,
                showCustomPriceRangeFilter,
                index,
                staticFilterData?.length
              )}
              onChange={(expanded: boolean) => {
                accordionDispatch({ dimensionName: filterItem.dimensionName, expanded });
              }}
              summary={
                <FilterSubHeading
                  classes={{
                    root: styles.filterSubheadingContainer,
                    firstTextDesktop: styles.customFilterSubHeading,
                  }}
                  title={filterItem.displayName}
                />
              }
              classes={{
                summary: styles.filterWrapper,
              }}
              content={
                <FilterDataRefinement
                  filterItem={{ ...filterItem, quickFilter: false }}
                  handleFilter={(filter) => handleFilterAndRedirect(filter, onFilterSelect)}
                  pageType={pageType}
                  showFilterSubHeading={false}
                  classes={{ root: styles.customFilterCategoryWrapper }}
                  hasVehicle={hasVehicle}
                />
              }
            />
          </li>
        ))}
      </ul>
    </aside>
  );
};

export const FilterDesktop = React.memo(FilterDesktopComponent);
