/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export function priceCommaFormat(value: string): string {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * This function add thousand separator comma to
 * $2000 - $3000
 */
export function formatPriceFilterLabel(value?: string | null) {
  if (value === null || value === undefined) {
    return value;
  } else {
    return value.split('-').map(priceCommaFormat).join('-');
  }
}
