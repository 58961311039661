/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { Button, Text, View } from '@az/starc-ui';
import { Icon } from '@az/starc-ui';
import { ListView, GridView } from '@az/starc-ui-icons';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const trackDisplayViewShelf = (viewName: boolean) => {
  const data = {
    selectedDisplayOption: viewName ? 'List View' : 'Grid View',
    eventType: 'displayViewInteractionShelf',
  };
  clickTrack(data);
};

const containerStyles = {
  'es-MX': styles.mexicoSortIconsContainer,
  'pt-BR': styles.brazilSortIconsContainer,
  'en-US': styles.sortIconsContainer,
};
const buttonSizeStyles = {
  'es-MX': styles.forMx,
  'pt-BR': styles.forBr,
  'en-US': styles.forUs,
};

const colors = {
  selectedIcon: '#f26100',
  selectedText: '#202124',
  icon: '#949494',
  text: '#6e6e6e',
};
const labelMap = {
  lblList: 'label_list',
  lblGrid: 'label_grid',
};

const ListLayoutOptionsV2 = ({
  isList,
  toggleListView,
  triggerShelfAnalytics,
}: {
  isList: boolean;
  toggleListView: (a: boolean) => void;
  triggerShelfAnalytics?: boolean;
}) => {
  const listView = isList ? ' is selected' : '';
  const gridView = isList ? '' : ' is selected';
  const locale = useLocale();
  const isUs = locale === countryCodes.us;

  const labels = useLabels(labelMap);

  const setListView: (view: boolean) => void = (view: boolean) => {
    toggleListView(view);
    if (triggerShelfAnalytics) {
      trackDisplayViewShelf(view);
    }
  };

  return (
    <div className={containerStyles[locale]}>
      <Button
        variant="ghost"
        animateOnClick={false}
        className={`${styles.sortIcons} ${styles.sortIconsList} ${buttonSizeStyles[locale]}`}
        onClick={() => setListView(true)}
        title={`list view${listView}`}
      >
        <View align="center">
          <Icon
            svg={ListView}
            size={6}
            attributes={{
              style: { color: isList ? colors.selectedIcon : colors.icon },
            }}
          />
          <Text
            textCase="capitalize"
            className={cx(styles.buttonText, { [styles.nonUsButtonText]: !isUs })}
            attributes={{ style: { color: isList ? colors.selectedText : colors.text } }}
          >
            {labels.lblList}
          </Text>
        </View>
      </Button>
      <Button
        variant="ghost"
        animateOnClick={false}
        className={`${styles.sortIcons} ${styles.sortIconsList} ${buttonSizeStyles[locale]}`}
        onClick={() => setListView(false)}
        title={`grid view${gridView}`}
      >
        <View align="center">
          <Icon
            svg={GridView}
            size={6}
            className={styles.paddingSVG}
            attributes={{ style: { color: isList ? colors.icon : colors.selectedIcon } }}
          />
          <Text
            textCase="capitalize"
            className={cx(styles.buttonText, { [styles.nonUsButtonText]: !isUs })}
            attributes={{ style: { color: isList ? colors.text : colors.selectedText } }}
          >
            {labels.lblGrid}
          </Text>
        </View>
      </Button>
    </div>
  );
};

export default ListLayoutOptionsV2;
