/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRouter } from 'next/router';
import { parseUrl } from '../utils/urlHelpers';
import { useLabel } from '@/hooks/useLabels';
import { labelMap } from '../components/Filter/FilterMobile/labels';
import { useGetCustomPriceFilterLabel } from '../hooks/useGetCustomPriceFilterLabel';
import { eventTypes } from '../constants/event';
import type { HandleFilterType } from '../types/filterTypes';
import { useCallback } from 'react';

const useAddCustomPriceRangeFilter = (): Array<HandleFilterType> => {
  const router = useRouter();
  const { asPath } = router;
  const { query } = parseUrl(asPath);
  const getCustomPriceFilterLabel = useGetCustomPriceFilterLabel();
  const lblPrice = useLabel(labelMap.lblCustomPrice);

  const addCustomPriceRangeFilter = useCallback((): Array<HandleFilterType> => {
    const filtersArray = [];

    if (query.minPrice || query.maxPrice) {
      const label = getCustomPriceFilterLabel(false, query.minPrice, query.maxPrice);
      filtersArray.push({
        refinement: {
          label: label,
          '@class': '',
          count: 0,
          multiSelect: false,
          navigationState: '',
          properties: {
            optimizedSeoUrl: '',
            seoUrl: '',
            isSelected: false,
            filters: eventTypes.customPriceEvent,
            unCheckedOptimizedUrl: '',
            botsOptimizedSeoUrl: '',
          },
        },
        checked: true,
        displayName: lblPrice,
        dimensionName: 'CustomPrice',
      });
    }

    return filtersArray;
  }, [getCustomPriceFilterLabel, lblPrice, query.maxPrice, query.minPrice]);

  return addCustomPriceRangeFilter();
};

export { useAddCustomPriceRangeFilter };
