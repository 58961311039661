/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import CustomRadioInput from '../FormGroup/RadioButton';
import Modal from '@/components/Modal';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '../../../theme/globals.module.scss';
import SelectField from '../StandaloneFields/SelectField';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import styles from './styles.module.scss';

const labelMap = {
  lblOf: 'label_checkout_shipping_Of',
  lblResults: 'label_Search_Results_Results',
  lblShowing: 'label_Showing',
  lblItems: 'label_MyAccountMyProfile_maintguide_Items',
  lblItemsPerPage: 'label_itemsPerPage',
};

type PageRecordProps = {
  recordsPerPage: number;
  totalRecords: number;
  recordsPerPageOptions: string;
  enableRecordsPerPageOptions: boolean;
  disableTotalMarkup?: boolean;
  focusItem?: boolean;
  setFocus?: (focusSortBy: boolean, focusItemsPerPage: boolean) => void;
};

const PageRecordCounter: React.FC<PageRecordProps> = ({
  recordsPerPage,
  totalRecords,
  recordsPerPageOptions,
  enableRecordsPerPageOptions,
  disableTotalMarkup,
  focusItem,
  setFocus,
}) => {
  const router = useRouter();
  const labels = useLabels(labelMap);
  const [openModal, setOpenModal] = useState(false);

  const parsedRecordsPerPageOptions = recordsPerPageOptions
    .split(',')
    .filter((item) => Number(item) <= totalRecords);

  useEffect(() => {
    const { url, query } = parseUrl(router.asPath);
    let highestOptionAvailable = query.recsPerPage;
    parsedRecordsPerPageOptions.forEach((option) => {
      if (totalRecords >= Number(option) && Number(query.recsPerPage) >= totalRecords) {
        highestOptionAvailable = option;
      }
    });

    if (highestOptionAvailable !== query.recsPerPage) {
      query.recsPerPage = highestOptionAvailable;
      const newPathName = stringifyUrl({
        url,
        query,
      });

      router.push(newPathName);
      setOpenModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedRecordsPerPageOptions, router.asPath, totalRecords]);

  const handleModalClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleSelectionChange = useCallback(
    (event: any) => {
      if (setFocus) {
        setFocus(false, true);
      }
      const { url, query } = parseUrl(router.asPath);
      delete query.pageNumber;
      delete query.currentPage;
      query.recsPerPage = event.target.value;
      const newPathName = stringifyUrl({
        url,
        query,
      });

      router.push(newPathName);

      setOpenModal(false);
    },
    [router, setFocus]
  );

  const totalMarkup = (totalRecords: number) => (
    <span>
      {enableRecordsPerPageOptions && labels.lblOf} {totalRecords} {labels.lblResults}
    </span>
  );

  const modalBodyContent = () => (
    <>
      <div className={azCommonStyles['az-body-2-regular']}>
        {labels.lblItems} {labels.lblShowing}
      </div>
      <ul>
        {recordsPerPageOptions.split(',').map((item, index) => (
          <li key={index}>
            <CustomRadioInput
              id={`pageCounts${index}`}
              name="recPerPageOptions"
              value={item}
              checked={recordsPerPage === parseInt(item)}
              onChange={(e) => handleSelectionChange(e)}
              label={<span className={styles.radioLabelClass}>{item}</span>}
            />
          </li>
        ))}
      </ul>
    </>
  );

  const pageRecordSelect = () => {
    const recPerPageOptions = parsedRecordsPerPageOptions.map((item, index) => (
      <option key={index} aria-label={`show ${item} items per page`}>
        {item}
      </option>
    ));

    return (
      <>
        {enableRecordsPerPageOptions && (
          <React.Fragment>
            <label htmlFor="at_select_id_for_items-per-page-select">{labels.lblItemsPerPage}</label>
            <div data-testid="per-page-container" id="per-page-container">
              <SelectField
                autoFocus={focusItem || false}
                name="items-per-page-select-field"
                classes={{
                  select: styles.perPageSelect,
                }}
                onChange={(e: any) => handleSelectionChange(e)}
                value={recordsPerPage}
                inputProps={{
                  'data-testid': 'items-per-page-select',
                }}
                id="items-per-page-select"
                hideEmptyOption
              >
                {recPerPageOptions}
              </SelectField>
            </div>
          </React.Fragment>
        )}
        {disableTotalMarkup ? null : totalMarkup(totalRecords)}
      </>
    );
  };

  if (totalRecords <= 0) {
    return null;
  }

  return (
    <>
      <div className={cx(azCommonStyles['az-body-2-regular'], styles.pageRecords)}>
        {pageRecordSelect()}
      </div>
      <Modal
        isOpen={openModal}
        handleClose={handleModalClose}
        disableEscapeKeyDown={false}
        desktopSize="small"
        tabletSize="fullscreen"
        showBackButton={false}
        showCloseButton
        body={modalBodyContent()}
      />
    </>
  );
};

export default PageRecordCounter;
