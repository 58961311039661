/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect, createRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import CheckBox from '../AZCustomComponent/FormGroup/CheckBox';
import { SmartLink as Link } from '../../utils/smartLink';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import type { FilterDataType, RefinementType, HandleFilterType } from '../../types/filterTypes';
import { pageTypes } from '../../constants/page';
import { Rating } from '../Rating/Rating';
import CustomPriceRange from '../AZCustomComponent/CustomPriceRange';
import { useCustomPriceFilterEnabled } from '../../hooks/useCustomPriceFilterEnabled';
import { ReduxState } from '../../types';
import FilterSubHeading from './FiltersubHeading';
import styles from './FilterDesktop/styles.module.scss';
import { priceFilterDisplayNames } from '@/components/Filter/priceFilterDisplayNames';
import Image from '@/components/NextImage';
import { usePageType } from '@/features/category/api/getPageType';
import { getCurrencySymbol } from '@/utils/priceHelpers';
import { formatPriceFilterLabel } from '@/utils/validator/priceCommaFormat';

const labelMap = {
  lblShowLess: 'label_ShelfPage_body_ShowLess',
  lblShowAll: 'hyperlink_ShelfPage_leftSideBar_ShowAll',
  lblItems: 'label_items',
  lblTo: 'labels_order_to',
  lblProducts: 'label_customerservice_contactus_products',
  lblSearch: 'label_Search_Results_Search',
};

//there's no need to cover rating = 2, rating = 1 or 'no rating' for now
const ratingFilterIds = [
  {
    id: '4000000402',
    value: 5,
    facetInternalName: '5',
  },
  {
    id: '4000000403',
    value: 4,
    facetInternalName: '4 to *',
  },
  {
    id: '4000000404',
    value: 3,
    facetInternalName: '3 to *',
  },
];

type Props = {
  classes?: {
    root?: string;
  };
  filterItem: FilterDataType;
  handleFilter: (a: HandleFilterType) => void;
  hasVehicle?: boolean;
  pageType: string;
  showFilterSubHeading?: boolean;
};

export const FilterDataRefinement = ({
  filterItem,
  handleFilter,
  hasVehicle = false,
  pageType,
  showFilterSubHeading = true,
  classes = {},
}: Props) => {
  const labels = useLabels(labelMap);
  const deviceType = useSelector<ReduxState>(({ appData }) => appData.deviceType);
  const isABot = deviceType === 'bot';
  const customPriceRangeEnabled = useCustomPriceFilterEnabled();
  const [refinementRefs, setRefinementRefs] = React.useState<any[]>([]);
  const isShelf = pageType.toLowerCase().includes(pageTypes.shelf);
  const isSearch = pageType.toLowerCase().includes(pageTypes.search);
  const dimensionName = filterItem?.dimensionName ?? '';
  const isPriceFilter = Object.values(priceFilterDisplayNames).includes(
    dimensionName?.toLowerCase()
  );
  const isAvailabilityFilter = dimensionName === 'availability_options_ss';
  const isVehicleFitmentFilter =
    dimensionName === 'Vehicle Fitment' || dimensionName === 'vehicle_fitment_s';
  const refinementSize = 8;
  const refinements = filterItem?.refinements ?? [];

  const [itemRefinements, setItemRefinements] = useState<RefinementType[]>(refinements);
  const { data: pageTypeData } = usePageType();
  const isYMMShelf = !!pageTypeData?.make || !!pageTypeData?.model || !!pageTypeData?.year;

  const refinementsLength = filterItem?.refinements.length;
  const displayName = filterItem?.displayName ?? '';
  const displayNameUpperCase = displayName.toUpperCase();
  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);

  const mapRefinements = useCallback(
    (refinements: RefinementType[]) => {
      if (isPriceFilter) {
        return refinements.map((item) => ({
          ...item,
          label: formatPriceFilterLabel(item?.label) as string,
        }));
      }
      return refinements;
    },
    [isPriceFilter]
  );

  const searchFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    if (searchText === '') {
      setItemRefinements(mapRefinements(refinements));
      return;
    }
    const filteredRefinements = refinements.filter((refinement) => {
      if (refinement.label.toLowerCase().includes(searchText.toLowerCase())) {
        return refinement;
      }
    });
    setItemRefinements(mapRefinements(filteredRefinements));
  };

  useEffect(() => {
    setItemRefinements(mapRefinements(filterItem?.refinements));
  }, [filterItem?.refinements, mapRefinements]);

  useEffect(() => {
    setRefinementRefs((refinementRefs) =>
      Array(refinementsLength)
        .fill(null)
        .map((_, i) => refinementRefs[i] || createRef())
    );
  }, [refinementsLength]);
  return (
    <div
      className={cx(styles.filterCategory, styles.shrinkable, {
        [classes.root || '']: classes.root,
      })}
      key={dimensionName}
      data-testid={`filter-group-${displayNameUpperCase.replace(/\s/g, '')}`}
    >
      {showFilterSubHeading && (
        <FilterSubHeading key={dimensionName} title={displayNameUpperCase} />
      )}
      {isPriceFilter && (customPriceRangeEnabled || isSearch) && (
        <div className={styles.customPriceRangeContainer}>
          <CustomPriceRange quickFilter={filterItem.quickFilter} />
        </div>
      )}
      {!isPriceFilter && refinementsLength > 15 && (
        <>
          <button
            className={styles.searchImage}
            tabIndex={-1}
            role="button"
            aria-label={`${displayName} ${labels.lblSearch}`}
          >
            <Image src="/images/shape-search.svg" alt="" width={36} height={19} />
          </button>
          <div className={styles.searchInputContainer}>
            <input
              name="Search"
              className={cx(styles.searchInput)}
              onChange={(event) => searchFilters(event)}
              placeholder={labels.lblSearch}
              aria-label={displayName}
              data-testid={`search-${displayNameUpperCase.replace(/\s/g, '')}`}
            />
          </div>
        </>
      )}
      <div
        className={cx(styles.filterListContainer, {
          [styles.expandedFilterList]: itemRefinements.length >= refinementSize,
        })}
      >
        <ul
          className={styles.bottomSpacing}
          id={`filterList${displayName}`}
          data-testid={`filterList-${displayNameUpperCase.replace(/\s/g, '')}`}
        >
          {itemRefinements.map((itemRefinement: RefinementType, index) => {
            const itemLabel = itemRefinement.label;
            const labelForToItemIdValue = itemLabel.includes(' ')
              ? itemLabel.split(' ').join('')
              : `${itemRefinement.label}`;
            const replaceRegex = new RegExp(`${currencySymbol}([\\d,]{4,}(?:\\.\\d+)?)`, 'g');
            const itemRefinementLabel = itemRefinement.label.replace(replaceRegex, (match) => {
              const price = parseFloat(match.replace(currencySymbol, '').replace(',', ''));
              return currencySymbol + price.toLocaleString();
            });
            const itemRefinementCount = itemRefinement?.count ?? 0;
            const itemRefinementCountLabel =
              (!hasVehicle && isVehicleFitmentFilter) || isAvailabilityFilter
                ? ''
                : ` (${itemRefinementCount})`;
            const fullLabel = `${itemRefinementLabel}${itemRefinementCountLabel}`;
            const disabled = (!isAvailabilityFilter && itemRefinementCount === 0) || isABot;
            const botSeoUrl = itemRefinement?.properties?.botsOptimizedSeoUrl;
            const filterId = itemRefinement?.properties?.filters;
            const endecaChecked =
              String(itemRefinement?.properties?.isSelected ?? 'false') === 'true';
            const rating = ratingFilterIds.find(
              (rating) =>
                rating.id === filterId ||
                (dimensionName === 'avg_rating_f' &&
                  rating.facetInternalName === itemRefinement?.properties?.internalName)
            );
            return (
              <li
                data-testid={itemLabel}
                key={`${itemLabel}-${itemRefinement.properties.botsOptimizedSeoUrl}`}
              >
                {isABot && isShelf && botSeoUrl && !isYMMShelf ? (
                  <Link to={botSeoUrl} className={styles.botLink}>
                    {fullLabel}
                  </Link>
                ) : (
                  <CheckBox
                    checked={endecaChecked}
                    variant="natural"
                    inputRef={refinementRefs[index]}
                    inputProps={{
                      'data-testid': `filter-refinement-${index}`,
                    }}
                    onChange={(event: any, checked: boolean) => {
                      handleFilter({
                        refinement: itemRefinement,
                        dimensionName,
                        displayName,
                        checked,
                        quickFilter: filterItem.quickFilter,
                        selectionType: filterItem.quickFilter ? 'Quick Filter' : 'Standard',
                      });
                    }}
                    label={
                      rating ? (
                        <div className={styles.ratingFilterLabelWrapper}>
                          <Rating value={rating.value} />
                          <span>&nbsp;{fullLabel}</span>
                        </div>
                      ) : (
                        fullLabel
                      )
                    }
                    id={`${dimensionName}-${labelForToItemIdValue}`}
                    status={!endecaChecked && disabled ? 'disabled' : ''}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FilterDataRefinement;
