/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import Title from '../AZCustomComponent/Title';
import azCommonStyles from '../../theme/globals.module.scss';
import styles from './FilterDesktop/styles.module.scss';

type Props = {
  title: string;
  classes?: { root?: string; firstTextDesktop?: string };
};

const FilterSubHeading = ({ title, classes = {} }: Props): React.ReactElement => {
  if (useMediaQuery((theme) => theme.breakpoints.down('md'))) {
    return (
      <h3
        className={cx(azCommonStyles['az-overline'], styles.mobileSubHeading, {
          [classes.root || '']: classes.root,
        })}
        data-testid={`filterSubHeading-${title}`}
      >
        {title}
      </h3>
    );
  } else {
    const testId = title.toLowerCase().replace(/\s/g, '-');
    return (
      <Title
        dataTestIdFirstText={`filter-header-${testId}`}
        firstText={title}
        variant="xxs"
        swapStyle
        firstTextClass={cx(azCommonStyles['az-title-5-medium'], {
          [classes.firstTextDesktop || '']: classes.firstTextDesktop,
        })}
        stylesClass={cx(styles.subheading, {
          [classes.root || '']: classes.root,
        })}
        overRuleH1="h3"
        id={`filterSubHeading-${title}`}
      />
    );
  }
};

export default FilterSubHeading;
