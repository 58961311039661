/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Components
import Skeleton from '@/components/Skeleton/Skeleton';
// Styles
import styles from '../styles.module.scss';

export const AvailabilitySkeletonLoader = () => {
  const skeletonQty = Array.from({ length: 3 }, (x, i) => i);

  return (
    <div className={styles.availabilitySkeletonContainer}>
      {skeletonQty.map((x) => (
        <div key={x} className={styles.newLoader}>
          <div className={styles.newTitleLoader}>
            <Skeleton animation="wave" height={24} width="100%" data-testid="skeleton-loader" />
          </div>
        </div>
      ))}
    </div>
  );
};
