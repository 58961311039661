/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import ExpandMoreIcon from '@/public/images/expand-more.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import cx from 'classnames';
import styles from './styles.module.scss';

type StandaloneSelectFieldIconProps = {
  className: string;
};

export const StandaloneSelectFieldIcon = ({ className }: StandaloneSelectFieldIconProps) => {
  return (
    <ExpandMoreIcon
      aria-hidden
      className={cx(iconStyles.defaultIconStyle, styles.arrowDownIcon, className)}
    />
  );
};
