/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { getCurrencySymbol } from '@/utils/priceHelpers';
import { priceCommaFormat } from '@/utils/validator/priceCommaFormat';
import { useCallback } from 'react';

const labelMap = {
  lblPriceCustom: 'label_Price_Custom',
  lblPriceMax: 'label_Price_Max',
  lblPriceMin: 'label_Price_Min',
};

export const useGetCustomPriceFilterLabel = (): ((
  includeCustomPriceLabel?: boolean,
  minPrice?: string | null,
  maxPrice?: string | null
) => string) => {
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);

  return useCallback(
    (includeCustomPriceLabel?: boolean, minPrice?: string | null, maxPrice?: string | null) => {
      let filterName = '';

      if (minPrice && !maxPrice) {
        filterName = `${labels.lblPriceMin} ${currencySymbol}${priceCommaFormat(minPrice)}`;
      } else if (!minPrice && maxPrice) {
        filterName = `${labels.lblPriceMax} ${currencySymbol}${priceCommaFormat(maxPrice)}`;
      } else if (minPrice && maxPrice) {
        filterName = `${currencySymbol}${priceCommaFormat(
          minPrice
        )} - ${currencySymbol}${priceCommaFormat(maxPrice)}`;
      }

      return includeCustomPriceLabel
        ? `Standard: ${labels.lblPriceCustom}: ${filterName}`
        : filterName;
    },
    [currencySymbol, labels]
  );
};
