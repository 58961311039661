/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { countryCodes, defaultStoreUS } from '@/constants/locale';
import { analyticsPageType } from '@/constants/analytics/analyticsPageType';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useLocale } from '@/hooks/useLocale';
import { getProductSkuDetailsKey } from '@/features/product/api/getProductSkuDetails';
import { useProductList } from '@/features/shelf/context/useProductList';
import type { ShelfPart } from '@/types/legacy';
import { useState } from 'react';
import useLatestCallback from 'use-latest-callback';
import { DealDrawer } from '../components/DealDrawer/DealDrawer';
import { useQueryClient } from '@tanstack/react-query';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { useFeatureFlag } from '@/features/globalConfig';
import { useRouter } from 'next/router';

export const useDealDrawer = () => {
  const router = useRouter();
  const [dealPart, setDealPart] = useState<ShelfPart | null>(null);
  const [dealSkuPrice, setDealSkuPrice] = useState('');
  const [showBundleDealsDrawer, setShowBundleDealsDrawer] = useState(false);
  const [closeRef, setCloseRef] = useState<React.RefObject<HTMLButtonElement> | null>(null);
  const [dealSkuQuantity, setDealSkuQuantity] = useState(0);
  const [dealFulfillmentTypeId, setDealFulfillmentTypeId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const { data: shelfData } = useProductList();
  const preferredVehicle = usePreferredVehicle();
  const { data: storeDetails } = useStoreDetailsData();
  const enable24ProductView = useIs24ProductViewEnabled();
  const locale = useLocale();
  const storeNumber =
    locale === countryCodes.us
      ? storeDetails?.storeNumber ?? defaultStoreUS
      : storeDetails?.storeNumber;
  const closeDealsDrawer = useLatestCallback(() => {
    setShowBundleDealsDrawer(false);
    if (closeRef?.current) {
      closeRef.current.focus();
    }
  });

  const handleDealClick = useLatestCallback(
    (
      part: ShelfPart,
      price: string,
      quantity: number,
      ref: React.RefObject<HTMLButtonElement>,
      fulfillmentTypeId: number | null
    ) => {
      setDealPart(part);
      setDealSkuPrice(price);
      setDealSkuQuantity(quantity);
      setDealFulfillmentTypeId(fulfillmentTypeId);
      setShowBundleDealsDrawer(true);
      setCloseRef(ref);
    }
  );

  const handleDealsAddToCart = () => {
    const skuIds =
      shelfData?.shelfParts.map((part) => {
        return part.skuNumber.toString();
      }) ?? [];
    if (enable24ProductView) {
      void queryClient.invalidateQueries({ queryKey: ['productSkuDetails'] });
    } else {
      void queryClient.invalidateQueries(
        getProductSkuDetailsKey({
          skuIds,
          vehicleId: preferredVehicle?.catalogVehicleId ?? '',
          storeNumber,
          isNewDealsApiEnabled,
        })
      );
    }
  };

  const renderDealDrawer = () => (
    <>
      {!!dealPart && (
        <DealDrawer
          analyticsPageType={analyticsPageType.catalogShelf}
          open={showBundleDealsDrawer}
          skuId={dealPart.skuNumber.toString()}
          skuRetailPrice={dealSkuPrice}
          skuFulfillmentTypeId={dealFulfillmentTypeId}
          skuQuantity={dealSkuQuantity || 1}
          skuIsVehicleSpecific={dealPart.vehicleSpecific}
          closeDealsDrawer={closeDealsDrawer}
          handleDealsAddToCart={handleDealsAddToCart}
          brand={dealPart.brand}
          partTypeId={dealPart.originalPartTypeId}
          productRepositoryId={dealPart.skuPricingAndAvailability?.productId}
          dealConfigurableLabel={dealPart.skuPricingAndAvailability?.dealConfigurableLabel}
          fittingPartsURL={
            router.route === '/searchresult' ? dealPart.productSeoUrl : router.asPath
          }
        />
      )}
    </>
  );

  return {
    handleDealClick,
    renderDealDrawer,
  };
};
