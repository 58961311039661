/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState } from 'react';
import cx from 'classnames';
import { ProductReviews, RebateOnlyBadge } from '@/features/pdp';
import { SaveNowBadge } from '../SaveNowBadge';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import Hidden from '@/components/Hidden';
import FitmentButton from '@/components/AZCustomComponent/FitmentButton';
import { ProductNote, ProductNotes } from '../ProductNotes';
import { ProductPrice } from '../ProductPrice';
import { ProductTitle } from '../ProductTitle';
import { ProductMetaLabel } from '@/components/ProductMetaLabel';
import CorePrice from '@/components/AZCustomComponent/CorePrice';
import viewStyles from '../viewStyles.module.scss';
import styles from './styles.module.scss';
import WarrantyBadge from '@/components/AZCustomComponent/WarrantyBadge';
import { useFeatureFlag } from '@/features/globalConfig';
import { useProductListView } from '../../../../../context/useProductListView';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import type { DealInfo } from '@/types/availability';
import useLatestCallback from 'use-latest-callback';
import { countryCodes } from '@/constants/locale';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { ProductDeals } from '../ProductDeals';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { ProductCrossReference } from '../ProductCrossReference';
import { useProductList } from '../../../../../context/useProductList';
import { usePriceHiding } from '@/hooks/usePriceHiding';

export type ProductInfoProps = {
  application: string;
  cents: string;
  corePrice: number;
  dealInfo: DealInfo;
  dollars: string;
  fitsVehicle?: boolean;
  hasVehicle?: boolean;
  itemPresentInCart: boolean | undefined;
  notes: string;
  partNumber: string;
  productImage: string;
  productId: string | undefined;
  productName: string;
  recordType: string | undefined;
  showPriceCaption: boolean;
  showWarrantyModalWrapper: () => void;
  skuNumber: number;
  toggleShowYmme: () => void;
  url: string;
  warranty?: string;
  locationFilter?: string;
  storeRetailPrice?: string | number;
  productSpecs?: Record<string, string> | null;
  prodIndex: number;
  count: number;
  price: string;
  clearance: boolean | undefined;
  showDealDrawerButton: boolean;
  onDealClick: (ref: React.RefObject<HTMLButtonElement>) => void;
  isMobile: boolean;
  priceLoaded?: boolean;
  dealConfigurableLabel?: string | null | undefined;
  seoUrl?: string;
  partsThatFitUrl: string;
  oemBrandName?: string | undefined;
  oemPartNumber?: string | undefined;
  onProductClick?: () => void;
  shippingType: string;
  setFulfillmentId?: (fulfillmentId: number) => void;
  handleQuantityChange: (a: number) => void;
  fulfillmentId?: number;
  isSearchPageType: boolean;
  renderProductAttributeChips?: JSX.Element;
  productAttributeChipsEnabled: boolean;
};

const ProductInfo = ({
  application,
  cents,
  corePrice,
  dealInfo,
  dollars,
  fitsVehicle,
  hasVehicle,
  itemPresentInCart,
  notes,
  partNumber,
  productName,
  productImage,
  productId,
  recordType,
  showPriceCaption,
  showWarrantyModalWrapper,
  skuNumber,
  toggleShowYmme,
  url,
  warranty,
  locationFilter,
  storeRetailPrice,
  productSpecs,
  prodIndex,
  count,
  clearance,
  showDealDrawerButton,
  onDealClick,
  isMobile,
  price,
  priceLoaded = true,
  dealConfigurableLabel,
  partsThatFitUrl = '',
  oemBrandName,
  oemPartNumber,
  onProductClick,
  handleQuantityChange,
  fulfillmentId,
  setFulfillmentId,
  shippingType,
  isSearchPageType,
  renderProductAttributeChips,
  productAttributeChipsEnabled,
}: ProductInfoProps) => {
  const router = useRouter();
  const { listViewState } = useProductListView();
  const { data: productListData } = useProductList();
  const isListView = isBrandShelfPage(router.asPath)
    ? listViewState.isBrandListView
    : listViewState.isListView;
  const [dealsModalOpen, setDealsModal] = useState(false);
  const shopByUrlYear = productListData?.shopByUrlYear;
  const shopByUrlMake = productListData?.shopByUrlMake;
  const shopByUrlModel = productListData?.shopByUrlModel;
  const makeOrModelName = productListData?.makeorModelName;
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const hidePricing = usePriceHiding();

  const isShelfYMM = shopByUrlYear && shopByUrlMake && shopByUrlModel;
  const locale = useLocale();
  const { data: storeData } = useStoreDetailsData();
  const { setPageSpecificData } = usePageSpecificData();
  const isBopusEnabled = storeData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  const handleDealsModal = useLatestCallback(() => {
    setDealsModal(true);
  });

  const handleCloseModal = useLatestCallback(() => {
    setDealsModal(false);
  });

  const handleFitmentButtonClick = () => {
    setPageSpecificData({
      productFindingMethod: 'Product Fitment Banner',
      pfmLocation: 'Product Fitment Banner',
      eventType: 'productFitmentBannerClick',
    });
  };

  const fitmentRender =
    recordType === 'app' ? (
      <div className={cx(styles.fitmentButton, 'fitment-button-container')}>
        <FitmentButton
          fitsVehicle={fitsVehicle}
          hasVehicle={hasVehicle}
          toggleShowYmme={toggleShowYmme}
          isList={isListView}
          productName={productName}
          makeOrModelName={makeOrModelName}
          onClickSeeFittingParts={() => {
            handleFitmentButtonClick();
            if (isSearchPageType) {
              void router.push(partsThatFitUrl);
            } else {
              void router.push(parseUrl(router.asPath).url);
            }
          }}
        />
      </div>
    ) : (
      <div
        className={cx(
          'fitment-button-container',
          !isListView && productListData?.shelfParts?.find((part) => part.recordType === 'app')
            ? styles.fitmentGhost
            : ''
        )}
      />
    );
  const locationLabel = <Label label="label_cart_lineItem_Location" />;
  const partNumberLabel = (
    <span>
      <Label label="label_DetailsPage_body_PartNo" /> #
    </span>
  );
  const partNumberLabelBopusMX = (
    <span>
      <Label label="label_DetailsPage_body_PartNumber" />
      &nbsp;
    </span>
  );
  const skuNumberLabel = (
    <span>
      <Label label="Label_Sku_Uppercase" /> #
    </span>
  );
  return (
    <div className={cx({ [styles.productInfoContainerGrid]: !isListView })}>
      <Hidden smDown implementation="js">
        <div className={styles.grow}>
          <ProductTitle
            productName={productName}
            url={url}
            CustomElement={'h3'}
            onProductClick={onProductClick}
            prodIndex={prodIndex}
            isSearchPageType={isSearchPageType}
          />
        </div>
      </Hidden>
      {!hidePricing && (
        <Hidden mdUp implementation="js">
          <ProductPrice
            cents={cents}
            className={viewStyles.productInfoItemContainer}
            dollars={dollars}
            showPriceCaption={showPriceCaption}
            isDiscountShown={dealInfo.dealAvailable}
            itemPresentInCart={itemPresentInCart}
            prodIndex={prodIndex}
            count={count}
            price={price}
            clearance={clearance}
            showDealDrawerButton={showDealDrawerButton}
            onDealClick={onDealClick}
            isMobile={isMobile}
            priceLoaded={priceLoaded}
            fulfillmentId={fulfillmentId}
            shippingType={shippingType}
            setFulfillmentId={setFulfillmentId}
            handleQuantityChange={handleQuantityChange}
            dealConfigurableLabel={dealConfigurableLabel}
          />

          {dealDrawerShelfEnabled && <RebateOnlyBadge badgeDetails={dealInfo} />}
          {!dealDrawerShelfEnabled && (
            <SaveNowBadge badgeDetails={dealInfo} dealsCheck={handleDealsModal} />
          )}
          <ProductDeals
            dealsModalOpen={dealsModalOpen}
            skuNumber={skuNumber}
            productImage={productImage}
            productId={productId}
            productName={productName}
            handleCloseModal={handleCloseModal}
          />
          <CorePrice corePrice={corePrice} />
        </Hidden>
      )}
      <div
        className={cx(
          styles.productPartDetails,
          viewStyles.prodDetails,
          'product-detail-wrapper',
          styles.newFulfillmentDetails
        )}
      >
        <ul className={viewStyles.productNoteUnorderList}>
          {(!isListView || isMobile) &&
            (isShelfYMM || hasVehicle) &&
            !productAttributeChipsEnabled && (
              <li>
                <ProductNote
                  heading={locationLabel}
                  info={locationFilter}
                  application={application}
                  notes={notes}
                  partNumber={partNumber}
                  skuNumber={skuNumber}
                  warranty={warranty}
                  productName={productName}
                  storeRetailPrice={storeRetailPrice}
                  locationFilter={locationFilter}
                  productSpecs={productSpecs}
                  url={url}
                  onProductClick={onProductClick}
                  prodIndex={prodIndex}
                  isSearchPageType={isSearchPageType}
                />
              </li>
            )}
          <li
            className={cx(styles.productLabel, styles.partNumber, {
              [styles.inlineDesktop]: !isBopusMexicoStoreEnabled,
            })}
          >
            {partNumber && (
              <ProductMetaLabel
                label={isBopusMexicoStoreEnabled ? partNumberLabelBopusMX : partNumberLabel}
                value={`${partNumber}`}
                data-testid="product-part-number"
              />
            )}
          </li>
          <li
            className={cx(styles.productLabel, {
              [styles.inlineDesktop]: !isBopusMexicoStoreEnabled,
            })}
          >
            {skuNumber && (
              <ProductMetaLabel
                label={skuNumberLabel}
                value={`${skuNumber}`}
                data-testid="product-sku-number"
              />
            )}
          </li>
          {oemPartNumber && (
            <li className={styles.crossRef}>
              <ProductCrossReference oemBrandName={oemBrandName} oemPartNumber={oemPartNumber} />
            </li>
          )}
          <li>
            <WarrantyBadge
              warranty={warranty}
              onClick={showWarrantyModalWrapper}
              classes={{
                root: cx(styles.warranty, styles.newFulfillmentWarranty),
                button: styles.warrantyButton,
                label: styles.warrantyText,
              }}
            />
          </li>
          {locale !== countryCodes.mx && locale !== countryCodes.ptBr && (
            <li>
              <ProductReviews skuNumber={skuNumber} url={url} onProductClick={onProductClick} />
            </li>
          )}
        </ul>
      </div>
      <ul className={cx(styles.fitmentBtnContainer, styles.newFulfillmentFitment)}>
        {!isMobile && <li>{fitmentRender}</li>}
        {isListView && !isMobile && !productAttributeChipsEnabled && (isShelfYMM || hasVehicle) && (
          <li>
            <ProductNote
              heading={locationLabel}
              info={locationFilter}
              application={application}
              notes={notes}
              partNumber={partNumber}
              skuNumber={skuNumber}
              warranty={warranty}
              productName={productName}
              storeRetailPrice={storeRetailPrice}
              locationFilter={locationFilter}
              productSpecs={productSpecs}
              url={url}
              onProductClick={onProductClick}
              prodIndex={prodIndex}
              isSearchPageType={isSearchPageType}
            />
          </li>
        )}
        {isListView && !isMobile && productAttributeChipsEnabled && renderProductAttributeChips}
        {isListView &&
          (isShelfYMM || hasVehicle) && ( //IMPORTANT: Do not change to implementation css, SEO requested that this be completely hidden
            <Hidden implementation="js" smDown>
              <li>
                <div className={viewStyles.listViewNotes} id="notes">
                  <ProductNotes
                    application={application}
                    notes={notes}
                    partNumber={partNumber}
                    skuNumber={skuNumber}
                    warranty={warranty}
                    productName={productName}
                    storeRetailPrice={storeRetailPrice}
                    locationFilter={locationFilter}
                    productSpecs={productSpecs}
                    url={url}
                    onProductClick={onProductClick}
                    isShelfYMM={!!isShelfYMM}
                    isSearchPageType={isSearchPageType}
                  />
                </div>
              </li>
            </Hidden>
          )}
      </ul>
    </div>
  );
};

export { ProductInfo };
