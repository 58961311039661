/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import type { SVGProps } from 'react';

export const SddIcon = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.84302C0.692336 6.69478 1.35985 5.5877 2.0417 4.45681C2.72326 5.5889 3.38702 6.69144 4.07601 7.83585H2.74659C2.77137 8.0549 2.78041 8.24387 2.81539 8.42789C3.18066 10.3477 4.23372 11.767 5.97743 12.6494C8.31944 13.8346 11.1739 13.316 12.9581 11.3946C14.1596 10.1008 14.6874 8.55728 14.5327 6.80558C14.2972 4.13833 12.1507 1.91055 9.49878 1.54295C9.23399 1.50623 8.9649 1.50141 8.69866 1.47372C8.20922 1.42288 7.98539 1.15384 8.02445 0.672684C8.05786 0.26108 8.37832 -0.0167293 8.81161 0.000783122C10.5943 0.0728932 12.1641 0.683356 13.5022 1.86617C14.8469 3.05487 15.6627 4.5461 15.917 6.31222C16.2193 8.41211 15.6945 10.3171 14.3679 11.9799C13.1679 13.484 11.5958 14.3964 9.69486 14.6722C7.53559 14.9856 5.58643 14.4308 3.90013 13.0417C2.50459 11.8921 1.66797 10.4048 1.34424 8.63042C1.30865 8.43539 1.29688 8.23571 1.28036 8.03763C1.26888 7.89992 1.22023 7.83342 1.06322 7.83923C0.726984 7.85167 0.390005 7.84302 0 7.84302Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.31822 5.52878C9.31835 6.24582 9.31471 6.96286 9.32044 7.67985C9.32229 7.91986 9.24266 8.11489 9.06428 8.28453C8.28506 9.0258 7.51264 9.77376 6.73239 10.514C6.35893 10.8684 5.80512 10.7449 5.63025 10.2732C5.52932 10.0009 5.64002 9.78077 5.8396 9.58956C6.50021 8.95683 7.15919 8.32246 7.82399 7.69371C7.92886 7.59452 7.97118 7.49335 7.97062 7.3525C7.96574 6.06867 7.96176 4.78476 7.97409 3.50106C7.97598 3.30435 8.02567 3.09055 8.1185 2.91652C8.24209 2.68491 8.58274 2.59097 8.82773 2.66593C9.10733 2.75143 9.316 3.013 9.31711 3.29574C9.31998 4.04009 9.3181 4.78444 9.31822 5.52878Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92447 11.6388C7.92447 11.5492 7.91906 11.4595 7.92559 11.3702C7.9386 11.1932 8.13059 11.0449 8.35774 11.0306C8.59561 11.0155 8.81586 11.132 8.83769 11.3225C8.86315 11.5445 8.85713 11.7699 8.83564 11.9924C8.82043 12.1495 8.58904 12.2749 8.37756 12.272C8.1748 12.2692 7.95329 12.1203 7.93072 11.9574C7.91612 11.8522 7.92796 11.7451 7.92796 11.6388H7.92447Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2102 7.45528C13.1142 7.45528 13.0181 7.45832 12.9222 7.45462C12.7471 7.44782 12.598 7.26664 12.5839 7.04824C12.5678 6.79894 12.6943 6.56067 12.8831 6.54074C13.0963 6.51829 13.3125 6.51974 13.526 6.54051C13.7033 6.55776 13.8393 6.80326 13.8241 7.02775C13.8079 7.26603 13.6623 7.44876 13.4812 7.45476C13.3909 7.45776 13.3005 7.45528 13.2102 7.45528Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1383 3.50058C12.2939 3.6447 12.3171 3.89288 12.1901 4.06387C12.1829 4.07351 12.1754 4.08274 12.1674 4.09153C12.0175 4.25638 11.8571 4.41266 11.69 4.55946C11.4929 4.73241 11.1801 4.67682 11.0671 4.43759C11.007 4.3103 11.0157 4.16123 11.1135 4.047C11.2872 3.84384 11.4681 3.64023 11.6767 3.47842C11.7657 3.40937 11.9519 3.39945 12.0588 3.44638C12.0876 3.45902 12.1137 3.47777 12.1383 3.50058Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1813 9.88407C11.3137 9.76712 11.5102 9.75132 11.6497 9.85911C11.6571 9.86472 11.664 9.87061 11.6708 9.87682C11.8378 10.0313 11.9958 10.1967 12.1495 10.3655C12.3128 10.5448 12.3107 10.7303 12.1562 10.8908C11.9906 11.0629 11.7994 11.0782 11.6292 10.9168C11.4593 10.7556 11.297 10.5855 11.1386 10.4122C11.0274 10.2905 10.9884 10.1323 11.0815 9.99393C11.1088 9.95321 11.1433 9.91762 11.1813 9.88407Z"
      fill="currentColor"
    />
  </svg>
);
