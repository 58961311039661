/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { FulfillmentStates } from './FulfillmentStates';
import { FULFILLMENT_OPTION_TO_RENDER } from '@/constants/fulfillmentConstants';
import styles from './styles.module.scss';

type IsSTHProps = {
  item: FulfillmentGroup;
  getOptionsToDisplay: (groupFulfillmentOptions: FulfillmentOption[]) => FulfillmentOption[];
  renderFulfillmentStickers: (option: FulfillmentOption) => JSX.Element;
};

export const IsSTH = ({ item, getOptionsToDisplay, renderFulfillmentStickers }: IsSTHProps) => {
  if (!item.groupFulfillmentOptions.some((option) => option.available)) {
    return (
      <FulfillmentStates fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE} />
    );
  } else {
    const optionsToDisplay = getOptionsToDisplay(item.groupFulfillmentOptions);

    return (
      <div className={styles.sthGap}>
        {optionsToDisplay.map((fulfillmentOption) => {
          return renderFulfillmentStickers(fulfillmentOption);
        })}
      </div>
    );
  }
};
