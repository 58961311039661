/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import useLatestCallback from 'use-latest-callback';
import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { ReduxState } from '@/types';
import actionTypes from '@/types/action';
import { addToCart } from '@/actions/shelf/shelf';
import { productInfoConstants } from '@/constants/productInfoConstants';
import { useRouter } from 'next/router';
import { ProductNotes } from '../ProductNotes';
import { ProductInfo } from '../ProductInfo';
import { ProductImage } from '../ProductImage';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { cartConstants } from '@/constants/cart';
import { routePaths } from '@/constants/routePaths';
import { checkAvailabilityByGroupTypeId } from '@/features/orders/utils/checkAvailabilityByGroupTypeId';
import { getStaticFormattedPrice } from '@/utils/priceHelpers';
import { fulfillmentGroupTypeIds } from '@/constants/fulfillmentConstants';
import { ProductPricingAndAvailability } from '../ProductPricingAndAvailability';
import { useDispatch } from '@/hooks/redux/useDispatch';
import usePrevious from '@/utils/usePrevious';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import { getProductStrikethroughAnalyticValue } from '@/components/ProductDetail/analytics/getProductStrikethroughAnalyticValue';
import { trackQuantityFulfillmentChange } from '@/utils/analytics/track/trackQuantityFulfillmentChange';
import { trackQuantityChange } from '@/utils/analytics/track/trackQuantityChange';
import { trackFulfillmentChange } from '@/utils/analytics/track/trackFulfillmentChange';
import { trackNearbyStoreUpdate } from '@/utils/analytics/track/trackNearbyStoreUpdate';
import {
  getFulfillmentId,
  getFulfillmentOption,
  getSelectedFulfilmentID,
} from '@/utils/getFulfillmentOption';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useFeatureFlag } from '@/features/globalConfig';
import { useProductList } from '../../../../../context/useProductList';
import type { ProductPriceInfoAddToCartAnalyticsData } from '../../../../../interface';
import type { DealInfo } from '@/types/availability';
import type { ShelfPart } from '@/types/legacy';
import { useQueryClient } from '@tanstack/react-query';
import {
  getProductSkuDetailsKey,
  getProductSkuDetailsFromCache,
} from '@/features/product/api/getProductSkuDetails';
import { type ProductSkuDetails } from '@/features/product';
import { sessionStorage } from '@/utils/sessionStorage';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { usePatchOrderItem } from '@/features/orders/api/patchOrderItem';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { NewProductAvailabilitySkeleton } from '@/components/Skeletons/NewFulfillmentSkeleton/NewProductAvailabilitySkeleton';
import { convertOrderTypeToFulfillmentType } from '@/utils/itemHelpers/lineItemHelpers/convertOrderTypeToFulfillmentType';
import { reportCitrusClick } from '@/features/citrus/utils/reportCitrusClick';
import { AttributeChip } from '@/components/AttributeChip';
import ConfigurableSkuBadge from '@/features/configurableSku';

export type ProductPriceInfoContainerComponentProps = {
  productImageAltName: string;
  part: ShelfPart;
  isList: boolean;
  application: string;
  dealInfo: DealInfo;
  cents: string;
  dollars: string;
  hasVehicle: boolean;
  configurableSku: boolean;
  index: number;
  shelfReadyStatus: string;
  isSearchPageType: boolean;
  storeNumber: string | undefined;
  toggleShowYmme: () => void;
  onDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    dealRef: React.RefObject<HTMLButtonElement>,
    fulfillmentGroupId: number | null
  ) => void;
  resizeShelfGrids?: () => void;
  setWarrantyModal: ({
    isShown,
    showModalFrom,
    warrantyType,
  }: {
    isShown: boolean;
    showModalFrom?: string;
    warrantyType?: string;
  }) => void;
  xFusionQueryId?: string;
  renderSponsoredChild?: React.ReactNode;
};

const ProductPriceInfoContainerComponent = ({
  productImageAltName,
  part,
  isList,
  application,
  dealInfo,
  cents,
  dollars,
  hasVehicle,
  configurableSku,
  index,
  shelfReadyStatus,
  storeNumber,
  toggleShowYmme,
  onDealClick,
  setWarrantyModal,
  resizeShelfGrids,
  isSearchPageType,
  xFusionQueryId,
  renderSponsoredChild,
}: ProductPriceInfoContainerComponentProps) => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const productAttributeChipsEnabled = useFeatureFlag('ENABLE_PRODUCT_ATTRIBUTE_CHIPS') === 'true';
  const router = useRouter();
  const ref = useRef<string>();
  const skuPricingAndAvailability = part.skuPricingAndAvailability;
  const corePrice = skuPricingAndAvailability?.corePrice ?? 0;
  const storeRetailPrice = skuPricingAndAvailability?.skuAvailabilityInfo.storeRetailPrice ?? 0;
  const categoryId = skuPricingAndAvailability?.categoryId ?? '';
  const productSpecs = part.productSpecs;
  const isMxWeb = locale === countryCodes.mx;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const fitsVehicle = part.vehicleFit ?? false;
  const fulfillmentOptions = part.skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions;

  const shipToHomeAvailable = !isMxWeb
    ? checkAvailabilityByGroupTypeId(fulfillmentOptions, fulfillmentGroupTypeIds.shipToHome)
    : part.skuPricingAndAvailability?.shipToHomeAvailable;
  const storePickupAvailable = !isMxWeb
    ? checkAvailabilityByGroupTypeId(fulfillmentOptions, fulfillmentGroupTypeIds.storePickup)
    : part.skuPricingAndAvailability?.storePickupAvailable;
  const finalNotes = [part.quickNote, part.techNote].filter(Boolean).join(', ');
  const {
    data: shelfData,
    isSuccess: shelfQuerySuccess,
    productSkuIsFetching,
    secondaryProductSkuIsFetching,
  } = useProductList();
  const enable24ProductView = useIs24ProductViewEnabled();
  const shopByUrlYear = shelfData?.shopByUrlYear;
  const shopByUrlMake = shelfData?.shopByUrlMake;
  const shopByUrlModel = shelfData?.shopByUrlModel;
  const productIdFromShelfData = shelfData?.productId;
  const [showDealDrawerButton, setShowBundleDealsButton] = useState(false);
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const isShelfYMM = shopByUrlYear && shopByUrlMake && shopByUrlModel;
  const [backupQuantityInCart, setBackupQuantityInCart] = useState(0);
  const quantityInCart = shelfData?.shelfParts[index].quantityInCart || backupQuantityInCart;
  const shelfCatLabel = shelfData?.shelfCatLabel;
  const updateCartEnabled = useFeatureFlag('UPDATE_CART_ENABLED') === 'true';
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeSelected = useIsStoreSelected();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const [count, setCount] = useState(
    updateCartEnabled || isBopusMexicoStoreEnabled ? quantityInCart : 0
  );
  const [groupId, setGroupId] = useState<number | null>(null);

  const onAddToCartSuccess = () => {
    const skuIds: string[] = [];
    shelfData?.shelfParts.forEach((part) => {
      skuIds.push(part.skuNumber.toString());
    });
    const skuDetailsQueryOptions = {
      skuIds: skuIds,
      vehicleId: catalogVehicleId,
      storeNumber,
      isNewDealsApiEnabled,
    };
    if (enable24ProductView) {
      const index = skuIds.findIndex((sku) => sku === skuPricingAndAvailability?.skuId);
      if (index >= 12) {
        skuDetailsQueryOptions.skuIds = skuIds.slice(12);
      } else {
        skuDetailsQueryOptions.skuIds = skuIds.slice(0, 12);
      }
    }
    const skuDetails = getProductSkuDetailsFromCache(queryClient, skuDetailsQueryOptions);
    const updatedSkuDetails = skuDetails?.map((skuDetailItem) => {
      if (skuDetailItem.skuPricingAndAvailability.skuId === part.skuNumber.toString()) {
        // Have to convert shippingType because at this point the groupId is not updated
        const currentGroupId = convertOrderTypeToFulfillmentType(shippingType);
        const fulfillmentOptions = part.fulfillmentOptions ?? skuDetailItem.fulfillmentOptions;
        const updatedFulfillmentOptions = fulfillmentOptions.map((option) => {
          if (option.groupId === currentGroupId) {
            return { ...option, selected: true };
          } else {
            return { ...option, selected: false };
          }
        });
        return {
          ...skuDetailItem,
          quantityInCart: count,
          fulfillmentOptions: updatedFulfillmentOptions,
          itemPresentInCart: true,
        };
      }
      return skuDetailItem;
    });
    const skuDetailsKey = getProductSkuDetailsKey(skuDetailsQueryOptions);
    queryClient.setQueryData(skuDetailsKey, updatedSkuDetails);
    setProductEdited(false);
    setProductUpdatedToCart(true);
  };

  const { mutateAsync: patchOrderItem } = usePatchOrderItem({ onAddToCartSuccess });
  const [prevGroupId, setPrevGroupId] = useState<number | null>(null);
  const [preCount, setPreCount] = useState<number | undefined | void>(0);
  const [prevGroupIdInCart, setPrevGroupIdInCart] = useState<number | null>(null);
  const [trackQOHError, setTrackQOHError] = useState(false);
  const staticPrice = getStaticFormattedPrice(
    skuPricingAndAvailability?.configurableSku,
    skuPricingAndAvailability
  );
  const price =
    count > 0 ? (Number(staticPrice) * count).toFixed(2).toString() : staticPrice.toString();

  const [productEdited, setProductEdited] = useState(false);
  const defaultFulfillmentOption = useFeatureFlag('DEFAULT_FULFILLMENT_OPTION') || '';
  const productRepositoryId = shelfData?.shelfParts[index].productRepositoryId;
  const originalPartTypeId = shelfData?.shelfParts[index].originalPartTypeId;
  const {
    readyStatus: orderItemReadyStatus,
    quantity: updatedQuantity,
    skuId: updatedSkuId,
  } = useSelector((state: ReduxState) => state.orders.details);
  const fulfillmentOption = getFulfillmentOption(
    shipToHomeAvailable,
    storePickupAvailable,
    defaultFulfillmentOption
  );
  const defaultShippingId = getFulfillmentId(
    shipToHomeAvailable,
    storePickupAvailable,
    defaultFulfillmentOption,
    !!part.availabilityInfoVO?.nddQuantity && part.availabilityInfoVO.nddQuantity > 0,
    !!part.availabilityInfoVO?.sddQuantity && part.availabilityInfoVO.sddQuantity > 0
  );
  const fulfillmentSelectedInCart = part.fulfillmentOptions?.find((option) => option.selected);
  const shippingOptionInCart =
    fulfillmentSelectedInCart &&
    fulfillmentSelectedInCart.groupId === FULFILLMENT_METHODS.STOREORDER
      ? cartConstants.STOREORDER
      : fulfillmentSelectedInCart?.groupId === FULFILLMENT_METHODS.ONLINEORDER
      ? cartConstants.ONLINEORDER
      : undefined;
  const [shippingType, setShippingType] = useState(shippingOptionInCart ?? fulfillmentOption);
  const [fulfillmentId, setFulfillmentId] = useState(defaultShippingId);
  const addToCartMutation = useAddToCart();
  const { data: headerData } = useHeaderData();
  const catalogVehicleId = headerData?.vehicleMap.catalogVehicleId ?? '';
  const [productUpdatedToCart, setProductUpdatedToCart] = useState(false);
  const [dealClicked, setDealClicked] = useState(false);
  const handleChangeShippingType = useLatestCallback((shippingType: string) => {
    setShippingType(shippingType);
    handleProductEdited();
  });
  const handleChangeShippingTypeV2 = useLatestCallback(
    (
      shippingType: typeof cartConstants.STOREORDER,
      nweGroupId: typeof FULFILLMENT_METHODS.STOREORDER,
      maxAvailableQty: number,
      isSisterStoreAvailable?: boolean,
      fulfillmentId?: number
    ) => {
      setFulfillmentId(fulfillmentId!);
      setShippingType(shippingType);
      setPrevGroupId(groupId);
      setPreCount(prevCount);
      setGroupId(nweGroupId);
      handleProductEdited();
      if (count > maxAvailableQty) {
        if (isBopusNewMsgApplicable) {
          isSisterStoreAvailable ? setCount(1) : setCount(quantityInCart);
        } else {
          handleQuantityChange(maxAvailableQty);
        }
      }
    }
  );

  const handleProductEdited = useLatestCallback(() => {
    setProductEdited(quantityInCart > 0);
  });

  useEffect(() => {
    if (fulfillmentSelectedInCart) {
      if (
        Number(count) === quantityInCart &&
        fulfillmentSelectedInCart.groupId !== groupId &&
        !dealClicked
      ) {
        setProductEdited(true);
      } else if (
        Number(count) === quantityInCart &&
        fulfillmentSelectedInCart.groupId === groupId
      ) {
        setProductEdited(false);
      }
    }
  }, [quantityInCart, count, fulfillmentSelectedInCart, groupId, dealClicked]);

  const handleQuantityChange = useLatestCallback((quantity: number) => {
    const newQuantity = quantity ? Math.abs(quantity) : 1;
    setCount(newQuantity);
    if (count !== quantity) {
      handleProductEdited();
    }
    resizeShelfGrids?.();
  });

  const handleAddToCart = useLatestCallback(async (quantity = 1): Promise<void> => {
    const fulfillmentChange =
      groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH;
    const addToCartRequest = {
      sku_id: String(part.skuNumber),
      product_id: skuPricingAndAvailability?.productId,
      quantity: String(quantity),
      category_id: categoryId !== null ? categoryId : '',
      order_type: shippingType,
      fulfillmentId: fulfillmentId,
      catalog_vehicle_id: part.catalogVehicleId !== null ? part.catalogVehicleId : '',
      store_number: String(storeNumber),
      storePickupAvailable,
      xFusionQueryId,
    };

    let analyticsData: ProductPriceInfoAddToCartAnalyticsData | null = null;

    if (!Boolean(sessionStorage.getItem('addOrUpdateCart') === 'true')) {
      analyticsData = {
        fulfillmentChange: prevGroupId !== 0 ? fulfillmentChange : '',
        customLinkName: router.asPath.includes(routePaths.searchResult)
          ? prevGroupId !== null && prevGroupId !== 0
            ? 'newSearchResultsPageFulfillmentChange'
            : part.isSponsoredCard
            ? 'New Search Results:Sponsored Product:Cart Addition'
            : 'New Search Results:AutoZone:Cart Addition'
          : prevGroupId !== null && prevGroupId !== 0
          ? 'Shelf:Sponsored Product:Cart Addition'
          : 'Shelf:AutoZone:Cart Addition',
        originalPartTypeId,
        productRepositoryId,
        price,
        corePrice,
        brandName: skuPricingAndAvailability?.brandName ?? '',
        repositoryId: part.repositoryId,
        partNumber: part.partNumber,
        productRecommendationTypeList: part.isSponsoredCard ? 'Sponsored Product' : 'AutoZone',
        shelfCatLabel,
        prodIndex: index,
        productStrikeThroughPricing: getProductStrikethroughAnalyticValue(
          part.skuPricingAndAvailability,
          part.skuPricingAndAvailability?.discountedPrice,
          strikeThroughPricingEnabled
        ),
        name: part.name,
        productRepositoryIdList: [part.skuPricingAndAvailability?.productId],
      };
    }

    if (addToCartRequest.product_id && !part.itemPresentInCart) {
      //TODO: once add to cart is removed from redux add this side effect to the add to cart mutation
      const onAddToCartSuccess = () => {
        const skuIds: string[] = [];
        if (enable24ProductView) {
          const index =
            shelfData?.shelfParts.findIndex(
              (item) => item.skuNumber.toString() === skuPricingAndAvailability?.skuId
            ) ?? 0;
          // since there are two productSkuDetails queries we must pick the first or second half depending on its index
          shelfData?.shelfParts
            .slice(index >= 12 ? 12 : 0, index >= 12 ? undefined : 12)
            .forEach((part) => {
              skuIds.push(part.skuNumber.toString());
            });
        } else {
          shelfData?.shelfParts.forEach((part) => {
            skuIds.push(part.skuNumber.toString());
          });
        }

        const skuDetails = queryClient
          .getQueriesData<ProductSkuDetails[]>({ queryKey: ['productSkuDetails'], type: 'active' })
          //@ts-expect-error item[0] is a query key with indeterminate type from react-query
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          .find((item) => item[0][1]?.skuIds?.includes(skuPricingAndAvailability?.skuId));

        const updatedSkuDetails = skuDetails?.[1]?.map(
          (skuDetailItem: {
            skuPricingAndAvailability: {
              productId: string | undefined;
              skuId: string | undefined;
            };
          }) => {
            if (
              skuDetailItem.skuPricingAndAvailability.skuId === skuPricingAndAvailability?.skuId
            ) {
              const updatedFulfillmentOptions = part.fulfillmentOptions?.map((option) => {
                if (option.groupId === groupId) {
                  return { ...option, selected: true };
                } else {
                  return { ...option, selected: false };
                }
              });
              return {
                ...skuDetailItem,
                quantityInCart: quantity,
                itemPresentInCart: true,
                fulfillmentOptions: updatedFulfillmentOptions,
              };
            }
            return skuDetailItem;
          }
        );
        const skuDetailsKey = skuDetails?.[0];
        if (skuDetailsKey) {
          queryClient.setQueryData(skuDetailsKey, updatedSkuDetails);
        }
      };
      await dispatch(
        addToCart(
          addToCartMutation,
          addToCartRequest,
          analyticsData,
          onAddToCartSuccess,
          queryClient,
          typeof router.query.searchText === 'string'
            ? router.query.searchText
            : typeof router.query.filterByKeyWord === 'string'
            ? router.query.filterByKeyWord
            : undefined,
          xFusionQueryId
        )
      );
      setBackupQuantityInCart(quantity);
      setTrackQOHError(true);
      resizeShelfGrids?.();
    } else {
      void router.push(routePaths.cart);
    }
  });

  const updateCart = useLatestCallback(async (quantity: number = count): Promise<void> => {
    if (sessionStorage.getItem('sister_store_update_type') === 'select_store_and_update_cart') {
      trackNearbyStoreUpdate(
        'Update_Quantity',
        'In-Stock Near You: Set Store and Add to Cart: Quantity Change',
        {
          originalPartTypeId: originalPartTypeId,
          quantityInCart,
          brandName: skuPricingAndAvailability?.brandName,
          corePrice: corePrice,
          shippingType,
          skuNumber: part.skuNumber.toString(),
          partNumber: part.partNumber,
          productPrice: price,
          productRepositoryId: productRepositoryId,
          requestQuantity: quantityInCart,
          storeId: storeNumber,
          storeSearchTerm: storeNumber,
          productRank: Number(index),
          productRecommendationTypeList: part.isSponsoredCard ? 'Sponsored Product' : 'AutoZone',
        }
      );
    }

    await patchOrderItem({
      skuId: skuPricingAndAvailability?.skuId ?? '',
      productId:
        productIdFromShelfData ?? part.productId ?? skuPricingAndAvailability?.productId ?? '',
      quantity: quantity.toString(),
      vehicleId: catalogVehicleId,
      storeNumber,
      fulfillmentTypeId: fulfillmentId,
    });
    setBackupQuantityInCart(quantity);

    const fullFillmaxQuantity =
      part.fulfillmentOptions?.find((option) => option.groupId == groupId)?.maxAvailableQty ?? 0;
    if (
      (!Boolean(prevGroupIdInCart) && groupId === FULFILLMENT_METHODS.STOREORDER) ||
      (!Boolean(prevGroupIdInCart) && groupId === FULFILLMENT_METHODS.ONLINEORDER) ||
      (prevGroupIdInCart === groupId && preCount !== quantity && fullFillmaxQuantity !== 1)
    ) {
      if (!sessionStorage.getItem('sister_store_update_type')) {
        trackQuantityChange(
          router.asPath.includes(routePaths.searchResult)
            ? 'newSearchResultsPageQuantityChange'
            : 'shelfPageQuantityChange',
          [String(fullFillmaxQuantity)],
          [String(part.skuNumber.toString())],
          [String(quantity)]
        );
      }

      sessionStorage.removeItem('sister_store_update_type');

      if (prevGroupIdInCart !== groupId) {
        setPrevGroupIdInCart(groupId);
      }
    } else if (
      prevGroupIdInCart !== groupId &&
      preCount !== quantity &&
      fullFillmaxQuantity !== 1
    ) {
      trackQuantityFulfillmentChange(
        groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH,
        'shelfPageFulfillmentAndQuantityChange',
        [String(fullFillmaxQuantity)],
        [String(part.skuNumber.toString())],
        [String(quantity)],
        'fulfillmentAndQuantityChange'
      );
      setPrevGroupIdInCart(groupId);
    } else if (prevGroupIdInCart !== groupId) {
      trackFulfillmentChange(
        groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH,
        'shelfPageFulfillmentChange'
      );
      setPrevGroupIdInCart(groupId);
    }
  });

  useEffect(() => {
    if (
      orderItemReadyStatus === actionTypes.UPDATE_ORDERITEM_SUCCESS &&
      updatedSkuId === part.skuNumber.toString()
    ) {
      setCount(Number(updatedQuantity));
      setPreCount(Number(updatedQuantity));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemReadyStatus]);

  useEffect(() => {
    const countNumber = count ? count : quantityInCart;
    setCount(updateCartEnabled || isBopusMexicoStoreEnabled ? countNumber : 0);
    if (
      !productSkuIsFetching &&
      (!shippingType || !fulfillmentId || (shelfQuerySuccess && ref.current !== shelfReadyStatus))
    ) {
      if (quantityInCart > 0) {
        const selectedFulfillmentId =
          getSelectedFulfilmentID(
            part.skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions ?? []
          ) ??
          getSelectedFulfilmentID(
            part.fulfillmentOptions?.reduce(
              (
                a: Array<{ selected: boolean; fulfillmentTypeId: number; availableQty: number }>,
                b
              ) => [
                ...a,
                ...b.groupFulfillmentOptions.map((opt) => ({ ...opt, selected: b.selected })),
              ],
              []
            ) ?? []
          );
        setFulfillmentId(selectedFulfillmentId!);
        if (groupId) {
          setShippingType(
            groupId === FULFILLMENT_METHODS.ONLINEORDER
              ? cartConstants.ONLINEORDER
              : cartConstants.STOREORDER
          );
        }
      } else {
        const isNotAvailable = fulfillmentOptions?.every((item: { available: boolean }) => {
          return !item.available;
        });
        if (isNotAvailable && (part.availabilityInfoVO?.numberOfSisterStoresAvailable ?? 0) > 0) {
          setShippingType(cartConstants.STOREORDER);
        }
      }
    }

    ref.current = shelfReadyStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityInCart, shelfReadyStatus, updateCartEnabled, groupId, productSkuIsFetching]);

  const showPriceCaptionValue = configurableSku;

  const showWarrantyModalWrapper = () => {
    setWarrantyModal({
      isShown: true,
      showModalFrom: productInfoConstants.ProductSpec,
      warrantyType: part.warrantyType,
    });
  };

  const handleDealClick = useLatestCallback((dealRef: React.RefObject<HTMLButtonElement>) => {
    setDealClicked(true);
    onDealClick?.(part, price, count, dealRef, groupId);
  });

  const renderProductAttributeChips = (
    <div
      className={`${cx(styles.attributeChipWrapper, {
        [styles.hasVehicle]: hasVehicle,
        [styles.attributeChipListView]: isList && !isMobile,
        [styles.attributeChipGridAndMobile]: isMobile || !isList,
      })} product-attributes-container`}
    >
      <AttributeChip
        locationAttribute={hasVehicle ? part.locationFilter : undefined}
        topAttributes={part.topAttributes}
      />
    </div>
  );

  const renderProductNotesComps = () => {
    if (isShelfYMM || hasVehicle) {
      //hide on mdUp for list view
      return (
        //IMPORTANT: Do not change to implementation css, SEO requested that this be completely hidden
        <Hidden implementation="js" mdUp={!!isList}>
          <div
            className={`${
              finalNotes || application ? styles.gridViewNotes : ''
            } product-notes-grid-view`}
            data-testid="product-notes-grid-view"
          >
            <ProductNotes
              isShelfYMM={!!isShelfYMM}
              application={application}
              notes={finalNotes}
              partNumber={part.partNumber}
              productName={part.name}
              skuNumber={part.skuNumber}
              warranty={part.warranty}
              locationFilter={part.locationFilter}
              productSpecs={productSpecs}
              storeRetailPrice={storeRetailPrice}
              onProductClick={onProductClick}
              url={part.seoUrl}
              isSearchPageType={isSearchPageType}
            />
          </div>
        </Hidden>
      );
    }
  };

  useEffect(() => {
    if (shippingType && groupId) {
      const selectedGroupFulfillmentHasDealsAvailableQty = fulfillmentOptions?.some(
        (option) =>
          option.groupTypeId === groupId && option.available && option.dealsAvailableQty > 0
      );
      selectedGroupFulfillmentHasDealsAvailableQty;
      const shouldShowButton =
        dealDrawerShelfEnabled &&
        storeSelected &&
        !!groupId &&
        selectedGroupFulfillmentHasDealsAvailableQty === true;
      //with new deals api we do not need to check with selected group id for deals available qty
      const shouldShowDealsButton =
        (dealDrawerShelfEnabled &&
          storeSelected &&
          !!groupId &&
          part.skuPricingAndAvailability?.dealAvailable) ??
        false;
      setShowBundleDealsButton(isNewDealsApiEnabled ? shouldShowDealsButton : shouldShowButton);
    }
  }, [
    dealDrawerShelfEnabled,
    fulfillmentOptions,
    groupId,
    storeSelected,
    shippingType,
    isNewDealsApiEnabled,
    part.skuPricingAndAvailability?.dealAvailable,
  ]);
  const prevCount = usePrevious(count);

  useEffect(() => {
    if (!productSkuIsFetching && part.fulfillmentOptions && !groupId) {
      const newGroupId =
        part.fulfillmentOptions.find((option) => option.selected)?.groupId ??
        FULFILLMENT_METHODS.ONLINEORDER;
      setGroupId(newGroupId);
    }
  }, [part.fulfillmentOptions, productSkuIsFetching, groupId, fulfillmentOption]);

  useEffect(() => {
    // Prevent updating fulfillment type for items already in cart
    if (!quantityInCart && fulfillmentOption) {
      setShippingType(fulfillmentOption);
      setFulfillmentId(defaultShippingId);
      setGroupId(
        fulfillmentOption === cartConstants.STOREORDER
          ? FULFILLMENT_METHODS.STOREORDER
          : FULFILLMENT_METHODS.ONLINEORDER
      );
    }

    // We need to update the shipping type if it wasn't set and the fulfillment option changes
    // This could happen if page was first rendered without a store
    // The fulfillment option will change to a valid one from empty string after store is set
  }, [fulfillmentOption, quantityInCart, defaultShippingId]);

  const onProductClick = () => {
    sessionStorage.setItem('productRank', String(index + 1));
    if (part.oemBrandName) {
      sessionStorage.setItem('oemBrandName', part.oemBrandName);
    }
    if (part.oemPartNumber) {
      sessionStorage.setItem('oemPartNumber', part.oemPartNumber);
    }
    if (!!part.citrusAdId) {
      void reportCitrusClick(part.citrusAdId);
    }
  };

  return (
    <div className={styles.prodSectionWrapper}>
      <div
        className={cx(
          isList ? styles.prodSectionContainer : styles.prodSectionContainerGrid,
          styles.newProdSection,
          {
            [styles.prodSectionGridHeightUS]: locale === 'en-US' && !isList && !isMobile,
          }
        )}
        data-testid="productInfoSection"
      >
        {part.isSponsoredCard ? (
          <Hidden mdDown={!part.isSponsoredCard} implementation="js" mdUp>
            <div
              className={cx(styles.sponsoredTextMob, azCommonStyles['az-caption'])}
              data-testid="sponsored-label"
            >
              <Label label="label_ShelfPage_sponsored" />
            </div>
          </Hidden>
        ) : null}
        <div
          className={cx(styles.prodImageContainer, {
            [styles.prodImageList]: isList,
          })}
        >
          <div className={styles.imageAndBadgeContainer}>
            <Hidden mdUp smDown={!part.configurableSku}>
              <ConfigurableSkuBadge />
            </Hidden>
            <ProductImage
              isListView={isList}
              alt={productImageAltName}
              src={part.itemImageUrl ?? part.productImageUrl}
              url={part.seoUrl}
              onClick={onProductClick}
              prodIndex={index}
              isSearchPageType={isSearchPageType}
            />
            {!isList && renderSponsoredChild}
          </div>
          <Hidden mdUp implementation="js">
            <div data-testid="mobile-productInfo" className={styles.mobileProdInfoContainer}>
              <ProductInfo
                application={application}
                dealInfo={dealInfo}
                cents={cents.padEnd(2, '0')}
                dollars={dollars}
                corePrice={corePrice}
                fitsVehicle={fitsVehicle}
                hasVehicle={hasVehicle}
                itemPresentInCart={part.itemPresentInCart}
                notes={finalNotes}
                partNumber={part.partNumber}
                productId={part.skuPricingAndAvailability?.productId}
                productName={part.name}
                productImage={part.productImageUrl}
                toggleShowYmme={toggleShowYmme}
                showPriceCaption={showPriceCaptionValue}
                skuNumber={part.skuNumber}
                url={part.seoUrl}
                onProductClick={onProductClick}
                partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
                warranty={part.warranty || part.warrantyText || ''}
                showWarrantyModalWrapper={showWarrantyModalWrapper}
                recordType={part.recordType}
                locationFilter={part.locationFilter}
                productSpecs={part.productSpecs}
                storeRetailPrice={storeRetailPrice}
                prodIndex={index}
                count={count}
                price={price}
                showDealDrawerButton={showDealDrawerButton}
                onDealClick={handleDealClick}
                clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
                isMobile={isMobile}
                oemBrandName={part.oemBrandName}
                oemPartNumber={part.oemPartNumber}
                priceLoaded={
                  shelfQuerySuccess &&
                  !productSkuIsFetching &&
                  (!enable24ProductView || index < 12 || !secondaryProductSkuIsFetching)
                }
                dealConfigurableLabel={skuPricingAndAvailability?.dealConfigurableLabel}
                fulfillmentId={fulfillmentId}
                shippingType={shippingType}
                setFulfillmentId={setFulfillmentId}
                isSearchPageType={isSearchPageType}
                handleQuantityChange={handleQuantityChange}
                renderProductAttributeChips={renderProductAttributeChips}
                productAttributeChipsEnabled={productAttributeChipsEnabled}
              />
            </div>
          </Hidden>
        </div>
        <Hidden smDown implementation="js">
          <div
            data-testid="productInfo"
            className={cx({
              [styles.prodInfoContainer]: isList,
              [styles.productInfoGrid]: !isList,
            })}
          >
            <ProductInfo
              application={application}
              dealInfo={dealInfo}
              cents={cents.padEnd(2, '0')}
              dollars={dollars}
              corePrice={corePrice}
              fitsVehicle={fitsVehicle}
              hasVehicle={hasVehicle}
              itemPresentInCart={part.itemPresentInCart}
              notes={finalNotes}
              partNumber={part.partNumber}
              productId={part.skuPricingAndAvailability?.productId}
              productName={part.name}
              productImage={part.productImageUrl}
              toggleShowYmme={toggleShowYmme}
              showPriceCaption={showPriceCaptionValue}
              skuNumber={part.skuNumber}
              url={part.seoUrl}
              onProductClick={onProductClick}
              partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
              warranty={part.warranty || part.warrantyText || ''}
              showWarrantyModalWrapper={showWarrantyModalWrapper}
              recordType={part.recordType}
              locationFilter={part.locationFilter}
              productSpecs={part.productSpecs}
              storeRetailPrice={storeRetailPrice}
              prodIndex={index}
              count={count}
              price={price}
              showDealDrawerButton={showDealDrawerButton}
              onDealClick={handleDealClick}
              clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
              isMobile={isMobile}
              oemBrandName={part.oemBrandName}
              oemPartNumber={part.oemPartNumber}
              priceLoaded={
                shelfQuerySuccess &&
                !productSkuIsFetching &&
                (!enable24ProductView || index < 12 || !secondaryProductSkuIsFetching)
              }
              dealConfigurableLabel={skuPricingAndAvailability?.dealConfigurableLabel}
              fulfillmentId={fulfillmentId}
              shippingType={shippingType}
              setFulfillmentId={setFulfillmentId}
              isSearchPageType={isSearchPageType}
              handleQuantityChange={handleQuantityChange}
              renderProductAttributeChips={renderProductAttributeChips}
              productAttributeChipsEnabled={productAttributeChipsEnabled}
            />
            {!isList && productAttributeChipsEnabled && renderProductAttributeChips}
          </div>
        </Hidden>
        {/* shelf ff option */}
        <div
          className={cx(
            styles.prodPriceContainer,
            styles.prodPriceContainerforFulfillmentStickers,
            {
              [styles.gridView]: !isList,
              [styles.priceContainerList]: isList,
              [styles.gridPriceFitmentSpacing]: !isList && !hasVehicle && part.recordType === 'app',
              [styles.noNotesMargin]:
                isMobile && (!(isShelfYMM || hasVehicle) || !(finalNotes || application)),
            }
          )}
        >
          {shelfQuerySuccess &&
          !productSkuIsFetching &&
          (!enable24ProductView || index < 12 || !secondaryProductSkuIsFetching) ? (
            <ProductPricingAndAvailability
              cents={cents.padEnd(2, '0')}
              corePrice={corePrice}
              dealInfo={dealInfo}
              configurableSku={configurableSku}
              dollars={dollars}
              fitsVehicle={fitsVehicle}
              hasVehicle={hasVehicle}
              itemPresentInCart={part.itemPresentInCart}
              productId={skuPricingAndAvailability?.productId}
              productName={part.name}
              productImage={part.productImageUrl}
              shipToHomeAvailable={shipToHomeAvailable}
              skuNumber={part.skuNumber}
              storePickupAvailable={storePickupAvailable}
              skuPricingAndAvailability={skuPricingAndAvailability}
              toggleShowYmme={toggleShowYmme}
              url={part.seoUrl}
              partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
              vehicleSpecific={part.vehicleSpecific ?? false}
              storeSelected={part.storeSelected}
              prodIndex={index}
              fulfillmentOptions={part.fulfillmentOptions}
              price={price}
              shippingType={shippingType}
              productEdited={productEdited}
              count={count}
              updateCart={updateCart}
              handleChangeShippingType={handleChangeShippingType}
              handleChangeShippingTypeV2={handleChangeShippingTypeV2}
              handleAddToCart={handleAddToCart}
              trackQOHError={trackQOHError}
              setTrackQOHError={setTrackQOHError}
              showDealDrawerButton={showDealDrawerButton}
              onDealClick={handleDealClick}
              clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
              groupId={groupId}
              part={part}
              productUpdatedToCart={productUpdatedToCart}
              setProductUpdatedToCart={setProductUpdatedToCart}
              setFulfillmentId={setFulfillmentId}
              dealConfigurableLabel={part.skuPricingAndAvailability?.dealConfigurableLabel}
              handleQuantityChange={handleQuantityChange}
              fulfillmentId={fulfillmentId}
              renderProductAttributeChips={renderProductAttributeChips}
              productAttributeChipsEnabled={productAttributeChipsEnabled}
            />
          ) : (
            <NewProductAvailabilitySkeleton isList={isList} finalNotes={finalNotes} />
          )}
        </div>
      </div>
      {renderProductNotesComps()}
    </div>
  );
};

export const ProductPriceInfoContainer = memo(ProductPriceInfoContainerComponent);
