/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import cx from 'classnames';
// STARC Components
import { Icon, Link, Text, View } from '@az/starc-ui';
// Icon Components
import { SddIcon } from './Icons/SddIcon';
import { BopusIcon } from './Icons/BopusIcon';
import { StdDeliveryIcon } from './Icons/StdDeliveryIcon';
// Features
import { useFeatureFlag } from '@/features/globalConfig';
// Utils
import { capitalize } from '@/utils/capitalize';
// Constants
import { FULFILLMENT_OPTION_TO_RENDER, fulfillmentLabels } from '@/constants/fulfillmentConstants';
// Styles
import styles from './styles.module.scss';
import { useLabel } from '@/hooks/useLabels';
import NddIcon from '@/public/images/next-day-delivery-shelf.svg';

type FulfillmentStatesProps = {
  fulfillmentOptionToRender: string;
  fulfillmentTypeLabel?: string;
  address?: string;
  numberOfSisterStoreAvailable?: number;
  estDeliveryDate?: string;
  openSisterStore?: () => void;
  toggleStoreModal?: () => void;
};

export const FulfillmentStates = ({
  fulfillmentOptionToRender,
  fulfillmentTypeLabel,
  address,
  numberOfSisterStoreAvailable,
  openSisterStore,
  toggleStoreModal,
  estDeliveryDate,
}: FulfillmentStatesProps) => {
  const sisterStoreSearchRadius = useFeatureFlag('SISTER_STORE_SEARCH_RADIUS') ?? '150';

  const labelHomeDeliveryUnavailable = useLabel(
    fulfillmentLabels.label_new_fulfillment_home_delivery_unavailable
  );
  const labelFree = useLabel('label_fulfilment_options_free');
  const label35Plus = useLabel(fulfillmentLabels.label_new_fulfillment_35_plus);
  const labelUnavailableWithin = useLabel(
    fulfillmentLabels.label_new_fulfillment_pickup_unavailable_within
  );
  const labelMiles = useLabel('label_MyAccountMyProfile_vehicle_Miles');
  const labelNotAvailable = useLabel('label_fulfilment_options_not_available');
  const labelPickUp = useLabel('label_cart_lineItem_PickUp');
  const labelSelectStore = useLabel('label_shelf_select_store');
  const labelForPickupAvailability = useLabel(
    fulfillmentLabels.label_new_fulfillment_for_pickup_availability
  );

  const iconSelector = (option: string) => {
    switch (option) {
      case FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE:
      case FULFILLMENT_OPTION_TO_RENDER.BOPUS_AVAILABLE_IN_STORE:
      case FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE_IN_STORE:
      case FULFILLMENT_OPTION_TO_RENDER.NO_STORE_SELECTED:
        return (
          <Icon
            svg={BopusIcon}
            size={5}
            color="600"
            attributes={{ 'aria-label': 'bopus-icon', style: { marginLeft: '8px' } }}
          />
        );

      case FULFILLMENT_OPTION_TO_RENDER.NDD_AVAILABLE:
        return (
          <Icon
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            svg={NddIcon}
            size={8}
            color="600"
            attributes={{ 'aria-label': 'ndd-icon', style: { margin: '-6px auto' } }} // this icon should be the same size as a size={5} <Icon /> hence style attribute
          />
        );

      case FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE:
      case FULFILLMENT_OPTION_TO_RENDER.STD_DELIVERY:
        return (
          <Icon
            svg={StdDeliveryIcon}
            size={5}
            color="600"
            attributes={{ 'aria-label': 'std-delivery-icon', style: { marginLeft: '8px' } }}
          />
        );

      default:
        return (
          <Icon svg={SddIcon} size={5} color="600" attributes={{ 'aria-label': 'sdd-icon' }} />
        );
    }
  };

  const messageSelector = (option: string) => {
    switch (option) {
      case FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE:
        return (
          <Text
            size={'087'}
            className={cx(styles.textLineHeight, {
              [styles.redText]: option === FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE,
              [styles.grayText]: option !== FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE,
            })}
          >
            {option === FULFILLMENT_OPTION_TO_RENDER.STH_UNAVAILABLE
              ? labelHomeDeliveryUnavailable
              : fulfillmentTypeLabel}
          </Text>
        );
      case FULFILLMENT_OPTION_TO_RENDER.STD_DELIVERY:
      case FULFILLMENT_OPTION_TO_RENDER.SDD_DELIVERY:
        const sddArr = fulfillmentTypeLabel?.split(' ');
        return (
          <Text size={'087'} className={cx(styles.textLineHeight, styles.grayText)}>
            <span className={cx(styles.greenText, styles.coloredText)}>
              {sddArr?.slice(0, -1).join(' ')}
            </span>
            <span> {sddArr?.slice(-1)}</span>
            {option === FULFILLMENT_OPTION_TO_RENDER.STD_DELIVERY && (
              <span className={styles.nonBreakingText}>{` by ${estDeliveryDate}`} </span>
            )}
          </Text>
        );
      case FULFILLMENT_OPTION_TO_RENDER.NDD_AVAILABLE:
        const textArr = fulfillmentTypeLabel?.split(' ');
        return (
          <Text
            size={'087'}
            color="600"
            className={cx(styles.textLineHeight, styles.nonBreakingText)}
            attributes={{ 'aria-label': 'ndd-available-msg' }}
          >
            <span className={cx([styles.coloredText, styles.greenText])}>
              {labelFree} {textArr?.slice(0, -1).join(' ')}
            </span>
            <span> {textArr?.slice(-1)}</span>
            <span> {label35Plus}</span>
          </Text>
        );

      case FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE:
        return (
          <Text
            size={'087'}
            className={cx(styles.redText, styles.textLineHeight)}
            attributes={{ 'aria-label': 'bopus-unavailable-msg' }}
          >
            {`${labelUnavailableWithin} `}
            <span>{sisterStoreSearchRadius}</span>
            <span>{` ${labelMiles.toLowerCase()}`}</span>
          </Text>
        );

      case FULFILLMENT_OPTION_TO_RENDER.BOPUS_UNAVAILABLE_IN_STORE:
        return (
          <Text
            size={'087'}
            color="600"
            className={styles.textLineHeight}
            attributes={{ 'aria-label': 'bopus-unavailable-in-store-selected-msg' }}
          >
            <div>
              <span className={cx([styles.redText])}>{labelNotAvailable}</span>
              <span> at </span>
              <span className={styles.address}>{address}</span>
            </div>
            <div>
              <span>
                <span className={cx([styles.coloredText, styles.greenText])}>{labelPickUp}</span>
                <span> at </span>
                <Link
                  preventHoverOpacityChange
                  className={styles.linkAddress}
                  onClick={openSisterStore}
                >
                  <span>{numberOfSisterStoreAvailable}</span>
                  <span> nearby </span>
                  <span>{numberOfSisterStoreAvailable! > 1 ? 'stores' : 'store'}</span>
                </Link>
              </span>
            </div>
          </Text>
        );

      case FULFILLMENT_OPTION_TO_RENDER.NO_STORE_SELECTED:
        return (
          <Text
            size={'087'}
            color="600"
            className={styles.textLineHeight}
            attributes={{ 'aria-label': 'bopus-no-store-selected-msg' }}
          >
            <Link preventHoverOpacityChange onClick={toggleStoreModal}>
              <span className={styles.linkAddress}>{labelSelectStore}</span>
            </Link>
            <span>{` ${labelForPickupAvailability} `}</span>
          </Text>
        );

      default:
        return (
          <Text
            size={'087'}
            color="600"
            className={styles.textLineHeight}
            attributes={{ 'aria-label': 'bopus-available-in-store-selected-msg' }}
          >
            <span className={cx([styles.coloredText, styles.greenText])}>
              {capitalize(labelPickUp)}
            </span>
            <span> at </span>
            <Link preventHoverOpacityChange onClick={toggleStoreModal}>
              <span className={cx(styles.linkAddress, styles.address)}>{address}</span>
            </Link>
          </Text>
        );
    }
  };

  return (
    <View direction="row" className={styles.stateContainer}>
      <View.Item className={styles.iconSpace}>{iconSelector(fulfillmentOptionToRender)}</View.Item>
      <View.Item className={styles.textContainer}>
        {messageSelector(fulfillmentOptionToRender)}
      </View.Item>
    </View>
  );
};
