/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  CMSSpacer,
  type CMSSpacerProps,
} from '@/features/contentstack/components/CMSSpacer/CMSSpacer';

const CMSPLPSpacer = ({ children, ...props }: CMSSpacerProps) => {
  return (
    <CMSSpacer shelfPage {...props}>
      {children}
    </CMSSpacer>
  );
};

export { CMSPLPSpacer };
