/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const whiteCheckMark = {
  src: '/images/Checkmark.svg',
  alt: '',
  width: 8,
  height: 6.3,
};
