/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { usePageType } from './usePageType';
import { pageTypes } from '@/constants/page';

export const useFacetedUrlEnabled = () => {
  const { pageType } = usePageType();

  return pageType === pageTypes.ProductShelf;
};
