/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import React from 'react';
// Components
import NextImage from '@/components/NextImage';
import Skeleton from '../../Skeleton/Skeleton';
import Hidden from '../../Hidden';
import { NewProductAvailabilitySkeleton } from './NewProductAvailabilitySkeleton';
import { Grid } from '../../Grid';
// Hooks
import { useMediaQuery } from '@/hooks/useMediaQuery';
// Styles
import styles from '../styles.module.scss';

type NewFulfillmentSkeletonProps = {
  isList: boolean;
};

export const NewFulfillmentSkeleton = ({ isList }: NewFulfillmentSkeletonProps) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));

  return (
    <div
      className={
        isMobile || !isList
          ? styles.listLoaderContainerGrid
          : `${styles.listLoaderContainer} ${styles.listGapLoaderContainer}`
      }
    >
      <Hidden mdUp>
        <Grid item xs={12}>
          <div className={styles.newMobileTitleLoader}>
            <Skeleton
              animation="wave"
              width="100%"
              height={22}
              variant="rect"
              data-testid="skeleton-loader"
            />
            <div className={isMobile ? styles.mobileBottomTitle : styles.bottomTitle}>
              <Skeleton
                animation="wave"
                width="50%"
                height={22}
                variant="rect"
                data-testid="skeleton-loader"
              />
            </div>
            <div className={isMobile ? styles.mobileBottomTitle : styles.bottomTitle}>
              <Skeleton
                animation="wave"
                width="100%"
                height={22}
                variant="rect"
                data-testid="skeleton-loader"
              />
            </div>
          </div>
        </Grid>
      </Hidden>
      <div
        className={
          isMobile
            ? `${styles.newMobileImageContainer} ${styles.imageContainer}`
            : isList
            ? styles.imageContainer
            : styles.imageContainerGrid
        }
      >
        <NextImage
          alt="placeholder-product-image"
          src="/images/image-placeholder-skeleton-load.svg"
          className={`${styles.placeholderImage} ${styles.imageCenter}`}
          height={171}
          width={171}
        />
        <Grid
          className={
            isMobile
              ? `${styles.mobilePriceSection} ${styles.newListInfoContainer}`
              : isList
              ? styles.newListInfoContainer
              : styles.newGridInfoContainer
          }
          item
          xs={12}
          md={6}
          lg={8}
        >
          <div>
            <Hidden mdUp>
              <div className={styles.priceTitle}>
                <Skeleton
                  animation="wave"
                  width="30%"
                  height={31}
                  variant="rect"
                  data-testid="skeleton-loader"
                />
              </div>
            </Hidden>
            <div className={styles.priceTitle}>
              <Skeleton
                animation="wave"
                width="100%"
                height={22}
                variant="rect"
                data-testid="skeleton-loader"
              />
            </div>
            <Skeleton
              animation="wave"
              width="60%"
              height={22}
              variant="rect"
              data-testid="skeleton-loader"
            />
            <Hidden smDown>
              <div className={styles.newFitmentLoader} />
            </Hidden>
          </div>
        </Grid>
      </div>
      <Grid item xs={12} md={isList ? 4 : 12} lg={isList ? 4 : 12}>
        <NewProductAvailabilitySkeleton isList={isList} />
      </Grid>
    </div>
  );
};
