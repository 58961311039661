/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { pageTypes } from '@/constants/page';
import { ListOptions } from '../ListOptions/ListOptions';
import { HandleFilterType } from '@/types/filterTypes';
import { useCMSShelfPageConfig } from '@/features/contentstack/hooks/useCMSShelfPageConfig';

type Props = {
  refQuickFilter?: React.Ref<HTMLElement>;
  onFilterSelect?: (a: HandleFilterType) => void;
};

export const CMSSortingOptions = ({ refQuickFilter, onFilterSelect }: Props) => {
  const { disableRecordsPerPage, recordsPerPage, rppOptions } = useCMSShelfPageConfig();
  return (
    <ListOptions
      refQuickFilter={refQuickFilter}
      pageType={pageTypes.shelf}
      onFilterSelect={onFilterSelect}
      rppOptionsData={rppOptions}
      recordsPerPage={recordsPerPage}
      disableRecordsPerPage={disableRecordsPerPage}
      removeMargin
    />
  );
};
