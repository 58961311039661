/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import { Grid } from '@/components/Grid';

type Props = {
  children: React.ReactNode;
  renderProductBadge?: React.ReactNode;
  renderSponsoredChild?: React.ReactNode;
  renderConfigSkuBadge: React.ReactNode;
  isList: boolean;
  isListItem?: boolean;
  classes: string | undefined;
  articleClass?: string | undefined;
};

const ProductContainer = ({
  children,
  isList,
  isListItem,
  classes,
  articleClass,
  renderProductBadge,
  renderSponsoredChild,
  renderConfigSkuBadge,
}: Props) => {
  const xs = 12;
  let md = 6,
    lg = 4;

  if (isList) {
    md = xs;
    lg = xs;
  }

  return (
    <Grid
      data-testid="product-container"
      isListItem={isListItem}
      item
      xs={xs}
      md={md}
      lg={lg}
      className={classes}
    >
      {renderProductBadge}
      {renderSponsoredChild}
      {renderConfigSkuBadge}
      <article className={articleClass}>{children}</article>
    </Grid>
  );
};

export { ProductContainer };
