/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const labelMap = {
  lblSortAndFilter: 'label_sort_and_filter',
  lblFilterResults: 'label_ShelfPage_body_FilterResults',
  lblFilter: 'hyperlink_Search_Filter_Filter',
  lblOf: 'label_checkout_shipping_Of',
  lblResults: 'label_Search_Results_Results',
  lblNoVehicleSet: 'label_Deals_DealsPage_NoVehicleSet',
  lblSelectVehicle: 'label_search_no_vehicle_narrow_results',
  lblDoneFilters: 'label_mobile_filters_done',
  lblApplyFilters: 'label_Deals_DealsPage_ApplyFilters',
  lblShowing: 'label_Showing',
  lblSetCustomPriceRangeHeading: 'label_ShelfPage_leftSideBar_SetCustomPriceRangeHeading',
  lblPrice: 'label_Price',
  lblCustomPrice: 'label_Price_Custom',
  lblPriceMax: 'label_Price_Max',
  lblPriceMin: 'label_Price_Min',
  lblSelectedFilters: 'label_Selected_Filters',
  lblCloseDrawerBtn: 'label_ShelfPage_Sort_And_Filter_Close_Drawer',
};
