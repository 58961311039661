/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import { Label } from '@/features/i18n';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { ReduxState } from '@/types';
import { Button } from '@/components/Button';

type Props = {
  content: string;
};

export function ExpandableTopDescription({ content }: Props) {
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);
  const isABot = deviceType === 'bot';
  const [expanded, setExpanded] = React.useState(isABot);

  const handleExpansionButton = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div
        className={cx(
          azCommonStyles['az-body-1-regular'],
          azCommonStyles['az-margin-top-4xs'],
          styles.topDescription,
          {
            [styles.topDescriptionExpanded]: expanded,
          }
        )}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <Button
        variant="ghost"
        className={cx(azCommonStyles['az-body-1-regular'], styles.topDescriptionExpandButton)}
        onClick={handleExpansionButton}
      >
        <Label
          label={
            expanded
              ? 'hyperlink_CategoryPage_body_ReadLess'
              : 'hyperlink_CategoryPage_body_ReadMore'
          }
        />
      </Button>
    </div>
  );
}
