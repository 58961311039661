/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Card, View, Text, Actionable, Loader } from '@az/starc-ui';
import { useSearchProductData } from '../../api/getSearchProductData';
import type { MappedSearchProductData } from '../../interface';
import styles from './styles.module.scss';
import { SmartLink as Link } from '../../../../utils/smartLink';
import { useLabels } from '@/hooks/useLabels';
import { useRouter } from 'next/router';
import { comingSoonImg } from '@/constants/images';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { parseUrl, stringifyUrl } from '@/utils/urlHelpers';
import { useRemoveUnsupportedSearchChars } from '@/hooks/useRemoveUnsupportedSearchChars';
import cx from 'classnames';
import { sessionStorage } from '@/utils/sessionStorage';
import NextImage from '@/components/NextImage';
import { useState } from 'react';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';

const labelMap = {
  label_shop_by_category: 'label_shop_by_category',
  label_view_all: 'label_view_all',
};

const CategoryCard = ({
  partType,
  searchText,
  itemID,
  isMobile,
  onClick,
}: {
  partType: NonNullable<MappedSearchProductData['partTypeRecords']>[0];
  searchText: string;
  itemID: number;
  isMobile: boolean;
  onClick: () => void;
}) => {
  const [image, setImage] = useState(partType.productImageUrl ?? comingSoonImg.src);
  return (
    <Card className={styles.categoryCard} hasHoverEffect={false}>
      <Link
        samePage
        to={`${partType.productCanonicalUrl}?searchText=${searchText}`}
        data-testid={`category-card-${itemID}`}
        onClick={onClick}
      >
        <View align="center" padding={{ s: [0, 2], m: 2 }} gap={{ s: 1, m: 3 }}>
          <NextImage
            className={styles.categoryCardImage}
            width={70}
            height={70}
            src={image}
            alt={partType.itemDescription}
            onError={() => {
              setImage(comingSoonImg.src);
            }}
          />
          <View.Item>
            <Text maxLines={isMobile ? 2 : 4} size={isMobile ? '075' : '087'} align="center">
              {partType.partTypeName}
            </Text>
          </View.Item>
        </View>
      </Link>
    </Card>
  );
};

export const VisualCategoryFilters = ({
  setViewingPartTypes,
  viewingPartTypes,
}: {
  setViewingPartTypes: (viewingPartTypes: boolean) => void;
  viewingPartTypes: boolean;
}) => {
  const labels = useLabels(labelMap);
  const router = useRouter();
  const { url, query } = parseUrl(router.asPath);
  const { data: searchProductData, isLoading: isLoadingPartTypes } = useSearchProductData({
    enabled: !viewingPartTypes,
  });
  const { isCSProductListingPageEnabled } = useContentStackPLPData({ enabled: true });
  const removeUnsupportedSearchChars = useRemoveUnsupportedSearchChars();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));

  if (!searchProductData && isLoadingPartTypes) {
    return (
      <View.Item grow>
        <Loader variant="spinner" />
      </View.Item>
    );
  }
  if (!searchProductData?.partTypeRecords) {
    return null;
  }
  const recordsLengthToDisplay = isMobile ? 12 : isTablet ? 5 : 7;
  const records = searchProductData?.partTypeRecords.slice(0, recordsLengthToDisplay);

  return (
    <View
      gap={4}
      className={cx(styles.visualCategories, {
        [styles.topSpacing]: !isCSProductListingPageEnabled,
        [styles.topSpacingForCS]: isCSProductListingPageEnabled,
      })}
      padding={[3, 0]}
    >
      <View
        direction="row"
        align="center"
        attributes={isMobile ? { style: { margin: '4px' } } : undefined}
      >
        <View.Item grow>
          <Text weight="bold" size="112" lineHeight="140">
            {labels.label_shop_by_category}
          </Text>
        </View.Item>
        {searchProductData.partTypeRecords.length >
          (isMobile ? recordsLengthToDisplay - 1 : recordsLengthToDisplay) && (
          <View.Item attributes={isMobile ? { style: { marginRight: '12px' } } : undefined}>
            <Actionable
              type="button"
              onClick={() => {
                query.partTypesView = 'true';
                const newPathName = stringifyUrl({
                  url,
                  query,
                });
                router.push(newPathName);
                setViewingPartTypes(true);
              }}
            >
              <Text
                size="087"
                lineHeight="140"
                align="center"
                decoration="underline"
                weight="medium"
              >
                {labels.label_view_all}
              </Text>
            </Actionable>
          </View.Item>
        )}
      </View>
      <View
        className={styles.cardsList}
        gap={2}
        padding={[isMobile ? 0 : 1, 0]}
        wrap={false}
        align="stretch"
      >
        <View
          className={styles.cardsWidth}
          direction="row"
          wrap={false}
          height={isMobile ? '82px' : undefined}
          attributes={isMobile ? { style: { width: `${records.length * 105}px` } } : undefined}
        >
          {records.map((item, i) => (
            <View.Item
              className={cx(styles.categoryItem, {
                [styles.cardItem]: records.length < recordsLengthToDisplay,
              })}
              grow={{ s: false, m: records.length === recordsLengthToDisplay }}
              key={`${item.itemId}${i}`}
            >
              <CategoryCard
                onClick={() => sessionStorage.setItem('visualCategoryRank', `${i + 1}`)}
                partType={item}
                searchText={encodeURIComponent(
                  removeUnsupportedSearchChars(String(query.searchText))
                )}
                itemID={i}
                isMobile={isMobile}
              />
            </View.Item>
          ))}
        </View>
      </View>
    </View>
  );
};
