/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import type { FilterDataType, HandleFilterType } from '../types/filterTypes';
import { getSelectedFilters } from '../components/Filter/utils/getSelectedFilters';
import { useAddCustomPriceRangeFilter } from './useAddCustomPriceRangeFilter';

const getSelectedFiltersArray = (
  staticFilterData: Array<FilterDataType> | null | undefined,
  dynamicFilterData: Array<FilterDataType> | null | undefined,
  addCustomPriceRangeFilter: Array<HandleFilterType>
) => {
  let selectedFiltersArray: Array<HandleFilterType> = [];
  staticFilterData?.forEach((filter: FilterDataType) =>
    getSelectedFilters(filter, selectedFiltersArray)
  );
  dynamicFilterData?.forEach((filter: FilterDataType) =>
    getSelectedFilters(filter, selectedFiltersArray)
  );
  if (addCustomPriceRangeFilter.length) {
    selectedFiltersArray = selectedFiltersArray.filter(
      (filter) => filter.refinement.label !== addCustomPriceRangeFilter[0].refinement.label
    );
  }
  selectedFiltersArray = [...selectedFiltersArray, ...addCustomPriceRangeFilter];
  return selectedFiltersArray;
};

export const useGetShelfSelectedFilters = (
  staticFilterData?: Array<FilterDataType> | null,
  dynamicFilterData?: Array<FilterDataType> | null
): HandleFilterType[] => {
  const addCustomPriceRangeFilter = useAddCustomPriceRangeFilter();
  const shelfChipSet = useMemo(() => {
    return getSelectedFiltersArray(staticFilterData, dynamicFilterData, addCustomPriceRangeFilter);
  }, [staticFilterData, dynamicFilterData, addCustomPriceRangeFilter]);

  return shelfChipSet;
};
