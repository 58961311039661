/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function buildSEOCopyBlock(
  copyBlockOne: string | null,
  copyBlockTwo: string | null
): string | undefined {
  if (!copyBlockOne && !copyBlockTwo) {
    return undefined;
  }

  return `${copyBlockOne ?? ''}${copyBlockOne && copyBlockTwo ? '<br/><br/>' : ''}${
    copyBlockTwo ?? ''
  }`;
}
