/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { useMemo } from 'react';
import { Text, View } from '@az/starc-ui';

type AttributeChipProps = {
  locationAttribute?: string;
  topAttributes?: Record<string, string>;
};

const labelMap = {
  lblProductLocation: 'label_cart_lineItem_Location',
};

export const AttributeChip = ({ locationAttribute, topAttributes }: AttributeChipProps) => {
  const { lblProductLocation } = useLabels(labelMap);
  const attributes = useMemo(() => {
    const topAttributesArray = Object.entries(topAttributes ?? {});
    const topAndLocationAttributes = locationAttribute
      ? [[lblProductLocation, locationAttribute], ...topAttributesArray]
      : [...topAttributesArray];

    return topAndLocationAttributes.slice(0, 4);
  }, [topAttributes, locationAttribute, lblProductLocation]);

  return (
    <>
      {attributes.map((attribute, index) => (
        <View direction="row" wrap={false} key={index} className={styles.attributeChipContainer}>
          <View.Item>
            <Text weight="bold" className={styles.chipLabel}>
              {`${attribute[0]}: `}
            </Text>
          </View.Item>
          <View.Item grow>
            <Text maxLines={1} className={styles.chipValue}>
              {attribute[1]}
            </Text>
          </View.Item>
        </View>
      ))}
    </>
  );
};
