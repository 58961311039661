/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment, PureComponent } from 'react';
import cx from 'classnames';
import { Table } from '../../Table/Table';
import { TableBody } from '../../TableBody/TableBody';
import { TableRow } from '../../TableRow/TableRow';
import { TableCell } from '../../TableCell/TableCell';
import Hidden from '../../Hidden';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import Title from '../../AZCustomComponent/Title';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type ParentProps = {
  showWarranty: boolean;
  closeWarrantyModal: Function;
};
type ChildProps = {
  labels: any;
  isMobile: boolean;
} & ParentProps;
type State = {
  showWarrantyModal: boolean;
};
const labelMap = {
  lblWarrantiesDuralist: 'label.footer.warranties.duralistGold.header',
  lblWarrantyBatteryType: 'label_ShelfPage_warranty_BatteryType',
  lblBatteryWarranty: 'label_ShelfPage_warranty_BatteryLimitedWarranty',
  lblUnitedStatesOnly: 'label_ShelfPage_warranty_UnitedStatesOnly',
  lblWarrantyFreeReplacement: 'label_ShelfPage_warranty_FreeReplacementPeriod',
  lblWarrantyPlatinum: 'label_ShelfPage_warranty_Platinum',
  lblWarranty3Years36Months: 'label_ShelfPage_warranty_3Years36Months',
  lblWarranty2Years24Months: 'label_ShelfPage_warranty_2Years24Months',
  lblWarranty1Year12Months: 'label_ShelfPage_warranty_1Year12Months',
  lblWarranty4Years48Months: 'label_ShelfPage_warranty_4Years48Months',
  lblWarranty90Days: 'label_ShelfPage_warranty_90Days',
  lblWarranty30Days: 'label_ShelfPage_warranty_30Days',
  lblWarranty2YearsProrated: 'label_ShelfPage_warranty_2YearsProrated5YearsTotal',
  lblWarrantyDuralastGold: 'label_ShelfPage_warranty_DuralastGold',
  lblWarrantyDuralast: 'label_ShelfPage_warranty_Duralast',
  lblWarrantyValuecraft: 'label_ShelfPage_warranty_Valuecraft',
  lblWarrantyEconocraft: 'label_ShelfPage_warranty_Econocraft',
  lblWarrantyOptimaYellowRedTopD31A: 'label_ShelfPage_warranty_OptimaYellowRedTopD31A',
  lblWarrantyOptimaYellowRedTopD31T: 'label_ShelfPage_warranty_OptimaYellowRedTopD31T',
  lblWarrantyOptimaAllOther: 'label_ShelfPage_warranty_OptimaAllOtherPartNoS',
  lblWarrantyOdysseyAllOther: 'label_ShelfPage_warranty_OdysseyAllOtherPartNoS',
  lblWarrantyOdyssey78PC1500: 'label_ShelfPage_warranty_Odyssey78PC1500',
  lblWarrantyOdyssey65PC1750T: 'label_ShelfPage_warranty_Odyssey65PC1750T',
  lblWarrantyOdyssey31MPC2150TM: 'label_ShelfPage_warranty_Odyssey31MPC2150TM',
  lblWarrantyNonAutomotive: 'label_ShelfPage_warranty_NonAutomotiveBatteryWarrantyPeriods',
  lblWarrantyDuralastHeavyDuty: 'label_ShelfPage_warranty_DuralastHeavyDuty',
  lblWarrantyNonBrandedHeavyDuty: 'label_ShelfPage_warranty_NonBrandedHeavyDuty',
  lblWarrantyDuralastGoldLGUtility: 'label_ShelfPage_warranty_DuralastGoldLGUtility',
  lblWarrantyDuralastLGUtility: 'label_ShelfPage_warranty_DuralastLGUtility',
  lblWarrantyValuecraftLGUtility: 'label_ShelfPage_warranty_ValuecraftLGUtility',
  lblWarrantyDuralastGolfCart: 'label_ShelfPage_warranty_DuralastGolfCart',
  lblWarrantyOptimaBlueTopMarine: 'label_ShelfPage_warranty_OptimaBlueTopMarine',
  lblWarrantyDuralastMarineRV: 'label_ShelfPage_warranty_DuralastMarineRV',
  lblWarrantyDuralastMarineStarting: 'label_ShelfPage_warranty_DuralastMarineStarting',
  lblWarrantyDuralastPowerSport: 'label_ShelfPage_warranty_DuralastPowerSport',
  lblWarrantyDuralastGoldPowerSport: 'label_ShelfPage_warranty_DuralastGoldPowerSport',
  lblWarrantyPageContentDuralistGoldBody:
    'pagecontent.footer.warranties.duralistGold.duralistGoldBody',
  lblWarrantyPageContentDuralistGoldBodyContent:
    'pagecontent.footer.warranties.duralistGold.duralistGoldBodyContent',
  lblWarrantyPageDuralistGoldBodyOne:
    'pagecontent.footer.warranties.duralistGold.duralistGoldBodyOne',
  lblWarrantyPageLimtedBodyThree: 'pagecontent.footer.warranties.limtedWarrantiesBodyThree',
  lblWarrantyPageFrp: 'label_ShelfPage_warranty_Frp',
  lblBackToTop: 'label.rulesandregulations.mainbody.backtotop',
};

type Ref = {
  current: any;
};
class BatteryWarrantyComponent extends PureComponent<ChildProps, State> {
  constructor(props: ChildProps) {
    super(props);
    this.batteryWarrantyContainer = React.createRef();
  }

  static getDerivedStateFromProps(nextProps: ChildProps) {
    return {
      showWarrantyModal: nextProps.showWarranty,
    };
  }

  batteryWarrantyContainer: Ref;
  handleClick = (e: any) => {
    e.preventDefault();
    const scrollable = this.batteryWarrantyContainer.current.parentNode.parentNode.parentNode ?? {};
    scrollable.scrollIntoView({
      behavior: 'smooth',
    });
  };
  tableContent1 = (label1: any, label2: any, label3: any) => (
    <TableRow className={styles.warrantyRow}>
      <TableCell
        align="left"
        component="td"
        scope="row"
        className={cx(azCommonStyles['az-body-2-regular'])}
      >
        <span>{label1}</span>
      </TableCell>
      <TableCell align="left" component="td" scope="row">
        <Hidden only={['sm']}>
          <span className={cx(azCommonStyles['az-body-1-heavy'], styles.warrantyContentBold)}>
            {label2}
          </span>
        </Hidden>
        <Hidden only={['xl', 'md', 'lg']}>
          <Fragment>
            <p className={cx(azCommonStyles['az-body-2-regular'], styles.warrantyContent)}>
              {label3}
            </p>
          </Fragment>
        </Hidden>
      </TableCell>
      {this.props.isMobile && (
        <TableCell
          size="small"
          component="td"
          scope="row"
          className={cx(azCommonStyles['az-body-1-heavy'], styles.warrantyContentBold)}
        >
          <Fragment>
            <p className={cx(azCommonStyles['az-body-1-heavy'], styles.warrantyContentBold)}>
              {label2}
            </p>
          </Fragment>
        </TableCell>
      )}
    </TableRow>
  );
  tableContent2 = (label1: any, label2: any, label3: any) => (
    <TableRow className={styles.warrantyRow}>
      <TableCell
        component="td"
        scope="row"
        className={cx(azCommonStyles['az-body-2-regular'], styles.warrantyContent)}
      >
        <span>{label1}</span>
      </TableCell>
      {!this.props.isMobile && <TableCell component="td"></TableCell>}
      {this.props.isMobile && (
        <TableCell
          component="td"
          className={cx(azCommonStyles['az-body-2-regular'], styles.warrantyContent)}
        >
          <p className={cx(azCommonStyles['az-body-2-regular'], styles.warrantyContent)}>
            {label2}
          </p>
        </TableCell>
      )}
      <TableCell
        component="td"
        className={cx(azCommonStyles['az-body-1-heavy'], styles.warrantyContentBold)}
        scope="row"
      >
        <span>{label3}</span>
      </TableCell>
    </TableRow>
  );
  table1 = (labels: any) => (
    <React.Fragment>
      {this.tableContent1(
        labels.lblWarrantyPlatinum,
        labels.lblWarranty3Years36Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyDuralastGold,
        labels.lblWarranty3Years36Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyDuralast,
        labels.lblWarranty2Years24Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyValuecraft,
        labels.lblWarranty1Year12Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyEconocraft,
        labels.lblWarranty90Days,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOptimaYellowRedTopD31A,
        labels.lblWarranty2Years24Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOptimaYellowRedTopD31T,
        labels.lblWarranty2Years24Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOptimaAllOther,
        labels.lblWarranty3Years36Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOdyssey78PC1500,
        labels.lblWarranty4Years48Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOdyssey65PC1750T,
        labels.lblWarranty4Years48Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOdyssey31MPC2150TM,
        labels.lblWarranty4Years48Months,
        labels.lblWarrantyPageFrp
      )}
      {this.tableContent1(
        labels.lblWarrantyOdysseyAllOther,
        labels.lblWarranty3Years36Months,
        labels.lblWarrantyPageFrp
      )}
    </React.Fragment>
  );
  table2 = (labels: any) => (
    <div className={styles.warrantyModalPart}>
      <h3
        className={cx(
          azCommonStyles['titleWarranty'],
          azCommonStyles['az-margin-top-m'],
          azCommonStyles['az-margin-bottom-m'],
          azCommonStyles['az-margin-left-xxs'],
          azCommonStyles['az-title-5-medium'],
          styles.warrantyHead,
          styles.warrantyHeadMobile
        )}
        tabIndex={0}
      >
        {labels.lblWarrantyNonAutomotive}
      </h3>
      <div className={styles.specInnerRow}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={cx(
                  azCommonStyles['az-padding-right-xxs'],
                  azCommonStyles['az-padding-bottom-xxs'],
                  styles.warrantyText
                )}
                align="left"
                colSpan={2}
              >
                <Title
                  firstText={labels.lblWarrantyBatteryType}
                  variant="azTableTitle"
                  overRuleH1={'h3'}
                  headerType={React.Fragment}
                />
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={cx(
                  azCommonStyles['az-padding-right-xxs'],
                  azCommonStyles['az-padding-bottom-xxs'],
                  styles.warrantyText
                )}
                align="left"
              >
                <Title
                  firstText={labels.lblWarrantyFreeReplacement}
                  variant="azTableTitle"
                  overRuleH1={'h3'}
                  headerType={React.Fragment}
                />
              </TableCell>
            </TableRow>
            {this.tableContent2(
              labels.lblWarrantyDuralastHeavyDuty,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty1Year12Months
            )}
            {this.tableContent2(
              labels.lblWarrantyNonBrandedHeavyDuty,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty1Year12Months
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastGoldLGUtility,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty90Days
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastLGUtility,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty90Days
            )}
            {this.tableContent2(
              labels.lblWarrantyValuecraftLGUtility,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty30Days
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastGolfCart,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty90Days
            )}
            {this.tableContent2(
              labels.lblWarrantyOptimaBlueTopMarine,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty2Years24Months
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastMarineRV,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty1Year12Months
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastMarineStarting,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty1Year12Months
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastPowerSport,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty90Days
            )}
            {this.tableContent2(
              labels.lblWarrantyDuralastGoldPowerSport,
              labels.lblWarrantyPageFrp,
              labels.lblWarranty90Days
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
  backToTopLink = (labels: any) => (
    <div className={cx(azCommonStyles['az-margin-bottom-l'], styles.backToTop)}>
      <Link
        role="button"
        to="#"
        onClick={this.handleClick}
        className={azCommonStyles['az-body-1-link']}
      >
        {labels.lblBackToTop}
      </Link>
    </div>
  );

  render() {
    const { labels } = this.props;

    return (
      <div className={styles.BatteryWarrantyContainer} ref={this.batteryWarrantyContainer}>
        <div
          className={cx(
            azCommonStyles['az-margin-top-s'],
            azCommonStyles['az-margin-bottom-m'],
            styles.warrantyHead
          )}
          id="BatteryWarrantyContainer"
          tabIndex={0}
        >
          <Title
            firstText={labels.lblBatteryWarranty}
            secondText={labels.lblUnitedStatesOnly}
            variant="sm"
            id="modal-title"
          />
          <Hidden only={['md', 'lg', 'xl']}>
            <div className={styles.warrantyReplacement}>
              <span>{labels.lblWarrantyPageFrp}</span>
              <span> = {labels.lblWarrantyFreeReplacement}</span>
            </div>
          </Hidden>
        </div>
        <div id="warrantyTop">
          <div className={styles.warrantyModalPart}>
            <div className={styles.warrantyHeadDesktop}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={cx(
                        azCommonStyles['az-padding-right-xxs'],
                        azCommonStyles['az-padding-bottom-xxs'],
                        styles.warrantyText
                      )}
                      align="left"
                    >
                      <Title
                        firstText={labels.lblWarrantyBatteryType}
                        variant="azTableTitle"
                        overRuleH1={'h3'}
                        headerType={React.Fragment}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={cx(
                        azCommonStyles['az-padding-right-xxs'],
                        azCommonStyles['az-padding-bottom-xxs'],
                        styles.warrantyText
                      )}
                      align="left"
                      colSpan={2}
                    >
                      <Title
                        firstText={labels.lblWarrantyFreeReplacement}
                        variant="azTableTitle"
                        overRuleH1={'h3'}
                        headerType={React.Fragment}
                      />
                    </TableCell>
                  </TableRow>
                  {this.table1(labels)}
                </TableBody>
              </Table>
            </div>
          </div>
          {this.table2(labels)}
          <div
            className={cx(
              azCommonStyles['az-body-2-regular'],
              azCommonStyles['az-margin-top-s'],
              azCommonStyles['az-margin-bottom-s'],
              styles.warrantyFooter
            )}
            tabIndex={0}
          >
            <p>{labels.lblWarrantyPageContentDuralistGoldBody}</p>
            <p>{labels.lblWarrantyPageContentDuralistGoldBodyContent}</p>
            <p>{labels.lblWarrantyPageDuralistGoldBodyOne}</p>
            <p>{labels.lblWarrantyPageLimtedBodyThree}</p>
          </div>
          {this.backToTopLink(labels)}
        </div>
      </div>
    );
  }
}

const BatteryWarranty = (props: ParentProps) => {
  const labels = useLabels(labelMap);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return <BatteryWarrantyComponent labels={labels} isMobile={isMobile} {...props} />;
};

export default BatteryWarranty;
