/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { Grid } from '@/components/Grid';
import { productInfoConstants } from '@/constants/productInfoConstants';
import { ReduxState } from '@/types';
import azCommonStyles from '@/theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import styles from '../viewStyles.module.scss';
import { ProductTitle } from '../ProductTitle';
import { ProductContainer } from '../ProductContainer';
import { ProductPriceInfoContainer } from '../ProductPriceInfoContainer';
import { ProductPriceInfoContainer as OldProductPriceInfoContainer } from '../ProductPriceInfoContainer/OldProductPriceInfoContainer';
import { getStaticFormattedPrice } from '@/utils/priceHelpers';
import { useFeatureFlag } from '@/features/globalConfig';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useProductList } from '../../../../../context/useProductList';
import type { ShelfPart } from '@/types/legacy';
import { useProductListView } from '../../../../../context/useProductListView';
import { reportCitrusImpression } from '@/features/citrus/utils/reportCitrusImpression';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { pageTypes } from '@/constants/page';
import { sessionStorage } from '@/utils/sessionStorage';
import { useMonetateDecisionFlag } from '@/features/kibo';
import ConfigurableSkuBadge from '@/features/configurableSku';
import { ProductBadging } from '../ProductBadging';

type Props = {
  part: ShelfPart;
  pageType?: string;
  index: number;
  hasVehicle: boolean;
  isListItem?: boolean;
  shelfItemRef: React.RefObject<HTMLDivElement> | undefined;
  shelfReadyStatus: string;
  storeNumber: string | undefined;
  toggleShowYmme: () => void;
  onDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentGroupId: number | null
  ) => void;
  setWarrantyModal: ({ isShown }: { isShown: boolean }) => void;
  resizeShelfGrids?: () => void;
  xFusionQueryId?: string;
};

const ShelfProductComponent = ({
  part,
  index,
  hasVehicle,
  isListItem,
  shelfItemRef,
  shelfReadyStatus,
  storeNumber,
  toggleShowYmme,
  onDealClick,
  setWarrantyModal,
  resizeShelfGrids,
  pageType,
  xFusionQueryId,
}: Props) => {
  const { listViewState } = useProductListView();
  const isBrandPage = pageType === pageTypes.BrandShelf;
  const isShelfPage = pageType === pageTypes.shelf || pageType === pageTypes.ProductShelf;
  const isSearchPageType = pageType === pageTypes.search;
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search ||
    pageType === pageTypes.MerchShelf;
  const isListView = isBrandPage ? listViewState.isBrandListView : listViewState.isListView;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const addToCartError = useSelector(({ shelf }: ReduxState) => shelf.errorMessageDefault);
  const errorSku = useSelector(({ shelf }: ReduxState) => shelf.errorSku);
  const { data: shelfData } = useProductList();
  const makeOrModelName = shelfData?.makeorModelName;
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const locale = useLocale();
  const isMxWeb = locale === countryCodes.mx;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    isMobile &&
    !isMxWeb &&
    isShelfOrSearchPage;
  const application = (part.application ?? part.applicationQuestions?.join(', ') ?? '').replace(
    '@',
    ', '
  );
  const configurableSku = part.skuPricingAndAvailability?.configurableSku ?? false;
  const price = getStaticFormattedPrice(configurableSku, part.skuPricingAndAvailability);
  const [dollars, cents = '00'] = price.toString().split('.');
  const dealInfo = React.useMemo(
    () => ({
      dealAvailable: part.skuPricingAndAvailability?.dealAvailable ?? false,
      rebatesAvailable: part.skuPricingAndAvailability?.rebatesAvailable ?? false,
      rebateUrl: part.skuPricingAndAvailability?.rebateUrl,
    }),
    [
      part.skuPricingAndAvailability?.dealAvailable,
      part.skuPricingAndAvailability?.rebateUrl,
      part.skuPricingAndAvailability?.rebatesAvailable,
    ]
  );
  const panelView = isListView ? productInfoConstants.listView : productInfoConstants.gridView;
  const itemHasError = addToCartError !== '' && part.repositoryId === errorSku;
  const productImageAltName = makeOrModelName ? `${part.name} for ${makeOrModelName}` : part.name;

  const { data: storeData } = useStoreDetailsData();
  const isBopusEnabled = storeData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const isBopusBrazilStoreEnabled = locale === countryCodes.ptBr;
  const bestSellerBadgeEnabled = useMonetateDecisionFlag('bestSellerBadgeEnabled');
  const productBadge =
    part.bestSeller && !isMxWeb && isShelfPage && bestSellerBadgeEnabled ? (
      <ProductBadging />
    ) : undefined;

  useEffect(() => {
    if (!part.citrusAdId) return;
    const currentItemRef = shelfItemRef?.current;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && part.citrusAdId) {
        void reportCitrusImpression(part.citrusAdId);
      }
    }, {});

    if (currentItemRef) {
      observer.observe(currentItemRef);
    }

    return () => {
      currentItemRef && observer.unobserve(currentItemRef);
    };
  }, [part.citrusAdId, shelfItemRef]);

  const renderSponsoredChild = (
    <>
      {part.isSponsoredCard ? (
        <Hidden smDown mdUp={!part.isSponsoredCard} implementation="js">
          <div
            className={cx(styles.sponsoredText, azCommonStyles['az-caption'], {
              [styles.sponsoredListView]: isListView,
            })}
            data-testid="sponsored-label"
          >
            <Label label="label_ShelfPage_sponsored" />
          </div>
        </Hidden>
      ) : null}
    </>
  );

  const renderConfigSkuBadge = (
    <Hidden implementation="js" smDown mdUp={!part.skuPricingAndAvailability?.configurableSku}>
      <div className={styles.configurableSkuBadge}>
        <ConfigurableSkuBadge />
      </div>
    </Hidden>
  );

  const onProductClick = () => {
    if (part.oemBrandName) {
      sessionStorage.setItem('oemBrandName', part.oemBrandName);
    }
    if (part.oemPartNumber) {
      sessionStorage.setItem('oemPartNumber', part.oemPartNumber);
    }
  };

  return (
    <ProductContainer
      key={part.skuNumber}
      isList={isListView}
      isListItem={isListItem}
      classes={cx(
        itemHasError
          ? `${styles.productContainer} ${styles[panelView]} ${styles.errorBorder}`
          : `${styles.productContainer} ${styles[panelView]}`
      )}
      articleClass={cx({ [styles.articlePaddingTop]: !useNewShelfDesignMobile })}
      renderProductBadge={productBadge}
      renderSponsoredChild={isListView ? renderSponsoredChild : undefined}
      renderConfigSkuBadge={renderConfigSkuBadge}
    >
      {itemHasError ? (
        <NotificationBlock
          type="error"
          message={addToCartError}
          customClasses={styles.error}
          itemLevel
        />
      ) : null}
      <Grid
        className={cx({
          [styles.slimProductContainer]: useNewShelfDesignMobile,
        })}
        ref={shelfItemRef}
        container
        spacing={0}
        id={`id_${part.skuNumber.toString()}`}
        direction="column"
      >
        <Hidden mdUp implementation="js">
          <div
            className={cx(
              azCommonStyles['az-padding-right-xxs'],
              part.isSponsoredCard
                ? styles.sponsoredCardPadding
                : azCommonStyles['az-padding-bottom-xxs'],
              {
                [azCommonStyles['az-padding-top-4xs']]: !useNewShelfDesignMobile,
                [styles.productBadgePadding]: !!productBadge,
              }
            )}
          >
            <ProductTitle
              productName={part.name}
              url={part.seoUrl}
              CustomElement={'h3'}
              onProductClick={onProductClick}
              prodIndex={index}
              isSearchPageType={isSearchPageType}
            />
          </div>
        </Hidden>
        {isListView ? (
          <Grid container direction="row">
            <div className={styles.nddBadge}>
              <Grid item sm={12} md={4} className={styles.nddPadding}></Grid>
            </div>
          </Grid>
        ) : null}
        {isBopusNewMsgApplicable || isBopusMexicoStoreEnabled || isBopusBrazilStoreEnabled ? (
          <ProductPriceInfoContainer
            productImageAltName={productImageAltName}
            part={part}
            isList={isListView}
            application={application}
            dealInfo={dealInfo}
            cents={cents}
            dollars={dollars}
            hasVehicle={hasVehicle}
            configurableSku={configurableSku}
            index={index}
            shelfReadyStatus={shelfReadyStatus}
            storeNumber={storeNumber}
            toggleShowYmme={toggleShowYmme}
            onDealClick={onDealClick}
            setWarrantyModal={setWarrantyModal}
            resizeShelfGrids={resizeShelfGrids}
            isSearchPageType={isSearchPageType}
            xFusionQueryId={xFusionQueryId}
            renderSponsoredChild={renderSponsoredChild}
          />
        ) : (
          <OldProductPriceInfoContainer
            productImageAltName={productImageAltName}
            part={part}
            isList={isListView}
            application={application}
            dealInfo={dealInfo}
            cents={cents}
            dollars={dollars}
            hasVehicle={hasVehicle}
            configurableSku={configurableSku}
            index={index}
            shelfReadyStatus={shelfReadyStatus}
            storeNumber={storeNumber}
            toggleShowYmme={toggleShowYmme}
            onDealClick={onDealClick}
            setWarrantyModal={setWarrantyModal}
            resizeShelfGrids={resizeShelfGrids}
            isSearchPageType={isSearchPageType}
          />
        )}
      </Grid>
    </ProductContainer>
  );
};

export const ShelfProduct = React.memo(ShelfProductComponent);
