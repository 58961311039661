/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type MouseEvent } from 'react';
import root from 'window-or-global';
import { useRouter } from 'next/router';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { SmartLink as Link } from '@/utils/smartLink';
import NextImage, { akamaiLoader } from '@/components/NextImage';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { sessionStorage } from '@/utils/sessionStorage';
import viewStyles from '../viewStyles.module.scss';
import styles from './styles.module.scss';

type ProductImageProps = {
  alt: string;
  src: string;
  url: string;
  prodIndex: number;
  isSearchPageType: boolean;
  isListView: boolean;
  onClick?: () => void;
};

export const ProductImage = ({
  alt,
  src,
  url,
  prodIndex,
  isSearchPageType,
  isListView,
  onClick,
}: ProductImageProps) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const router = useRouter();
  const updatedUrl =
    !!router.query.searchText && !url?.includes('searchText')
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${url}?searchText=${router.query.searchText}`
      : router.query.filterByKeyWord &&
        !url.includes('filterByKeyWord') &&
        !url.includes('searchText')
      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${url}?searchText=${router.query.filterByKeyWord}`
      : url;

  const onImageClick = () => {
    if (isSearchPageType && prodIndex !== null && prodIndex !== undefined) {
      sessionStorage.setItem('productRank', String(prodIndex + 1));
    }
    onClick?.();
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.scrollY || root.pageYOffset
    );
  };

  const onAuxClick = (e: MouseEvent<HTMLImageElement>) => {
    if (e.button === 1) {
      onClick?.();
    }
  };

  return (
    <div data-testid="product-image-container" className={styles.productImageContainer}>
      <Link
        samePage
        className={`${styles.productImageLink} ${viewStyles.productImage}`}
        to={updatedUrl}
      >
        <NextImage
          priority={prodIndex === 0}
          src={src}
          alt={alt}
          height={isListView || isMobile ? (isTablet ? 124 : isDesktop ? 180 : 96) : 150}
          width={isListView || isMobile ? (isTablet ? 124 : isDesktop ? 180 : 96) : 150}
          data-testid="shelf-product-image"
          className={styles.image}
          onClick={onImageClick}
          onAuxClick={onAuxClick}
          loader={akamaiLoader}
        />
      </Link>
    </div>
  );
};
