/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  autoFocus?: boolean;
  autoFocusItem?: boolean;
  children: React.ReactNode;
  className?: string;
  variant?: string;
  role?: string;
};

export const MenuList = (props: Props) => {
  const { autoFocusItem = false, children, className, variant = 'selectedMenu', ...other } = props;

  let activeItemIndex = -1;

  React.Children.forEach(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return;
    }

    if (!child.props.disabled) {
      if (variant === 'selectedMenu' && child.props.selected) {
        activeItemIndex = index;
      } else if (activeItemIndex === -1) {
        activeItemIndex = index;
      }
    }
  });

  const items = React.Children.map(children, (child, index) => {
    if (index === activeItemIndex) {
      const newChildProps: { autoFocus?: boolean; tabIndex?: number } = {};

      if (autoFocusItem) {
        newChildProps.autoFocus = true;
      }

      // @ts-expect-error refine type
      if (child.props.tabIndex === undefined && variant === 'selectedMenu') {
        newChildProps.tabIndex = 0;
      }
      // @ts-expect-error refine type
      return child ? React.cloneElement(child, newChildProps) : child;
    }

    return child;
  });

  return (
    <ul className={cx(styles.list, className)} {...other}>
      {items}
    </ul>
  );
};
