/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Button } from '@/components/Button';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';

const trackDisplayViewShelf = (viewName: boolean) => {
  const data = {
    selectedDisplayOption: viewName ? 'List View' : 'Grid View',
    eventType: 'displayViewInteractionShelf',
  };
  clickTrack(data);
};

const ListLayoutOptions = ({
  isList,
  toggleListView,
  triggerShelfAnalytics,
}: {
  isList: boolean;
  toggleListView: (a: boolean) => void;
  triggerShelfAnalytics?: boolean;
}) => {
  const selectedListClass = isList ? styles.selected : '';
  const selectedGridClass = isList ? '' : styles.selected;
  const listView = isList ? 'is selected' : '';
  const gridView = isList ? '' : 'is selected';

  const setListView: (view: boolean) => void = (view: boolean) => {
    toggleListView(view);
    if (triggerShelfAnalytics) {
      trackDisplayViewShelf(view);
    }
  };

  return (
    <div className={styles.sortMain}>
      <Button
        variant="ghost"
        className={`${styles.sortIcons} ${styles.sortIconsList} ${selectedListClass}`}
        onKeyPress={() => false}
        onClick={() => setListView(true)}
        customClass={styles.sortIconsContainer}
        id="listViewOption"
        aria-label={`list view ${listView}`}
      >
        {''}
      </Button>
      <Button
        variant="ghost"
        className={`${styles.sortIcons} ${styles.sortIconsGrid} ${selectedGridClass}`}
        onKeyPress={() => false}
        onClick={() => setListView(false)}
        customClass={styles.sortIconsContainer}
        id="gridViewOption"
        aria-label={`grid view ${gridView}`}
      >
        {''}
      </Button>
    </div>
  );
};

export default ListLayoutOptions;
