/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Ref, useMemo, useState } from 'react';
import cx from 'classnames';
import Hidden from '../../Hidden';
import { Grid } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLocale } from '@/hooks/useLocale';
import { filterIcon } from '@/constants/images/filterIcon';
import FilterDataRefinement from '../FilterDataRefinement';
import ContentContainer from '../../AZCustomComponent/ContentContainer';
import type { FilterDataType, HandleFilterType } from '@/types/filterTypes';
import { useFilteredSortingOptions } from '@/hooks/useFilteredSortingOptions';
import SortBy from '../../AZCustomComponent/SortBy';
import FilterButton from '../FilterButton';
import FilterSubHeading from '../FiltersubHeading';
import CustomPriceRange from '../../AZCustomComponent/CustomPriceRange';
import QuickFilter from '../QuickFilter';
import { useFilterAndRedirect } from '@/hooks/useFilterAndRedirect';
import { useShowCustomPriceRangeFilter } from '@/hooks/useShowCustomPriceRangeFilter';
import { useRemoveAppliedPriceFilters } from '@/hooks/useRemoveAppliedPriceFilters';
import ChipTags from '../ChipTags';
import { useGetShelfSelectedFilters } from '@/hooks/useGetShelfSelectedFilters';
import { getResults } from './helpers';
import { labelMap } from './labels';
import styles from './styles.module.scss';
import { useSearchPartTypes } from '@/features/search/api/getSearchPartTypes';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { ShelfPageBodyComplete } from '@/types/legacy';
import { ShareLinkButton } from '@/features/sharableLink';
import { useFeatureFlag } from '@/features/globalConfig';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { countryCodes } from '@/constants/locale';
import { priceFilterDisplayNames } from '../priceFilterDisplayNames';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useMonetateDecisionFlag } from '@/features/kibo';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import { useLabels } from '@/hooks/useLabels';

export type FilterMobileComponentProps = {
  refQuickFilter?: Ref<HTMLElement>;
  staticFilterData: FilterDataType[] | undefined | null;
  dynamicFilterData: FilterDataType[] | undefined | null;
  pageType: string;
  onFilterSelect?: (a: HandleFilterType) => void;
  viewingPartTypes?: boolean;
  oneClickFilters?: Array<HandleFilterType>;
  clearAllChipTag?: React.ReactNode | null;
};

const FilterMobileComponent = ({
  refQuickFilter,
  staticFilterData,
  dynamicFilterData,
  pageType,
  onFilterSelect,
  viewingPartTypes = false,
  oneClickFilters,
  clearAllChipTag,
}: FilterMobileComponentProps) => {
  const isSharableLinksEnabled = useFeatureFlag('SHARABLE_LINKS_ENABLED') === 'true';
  const handleFilterAndRedirect = useFilterAndRedirect();
  const removeCustomPriceRangeFilter = useRemoveAppliedPriceFilters();
  const showCustomPriceRangeFilter = useShowCustomPriceRangeFilter();
  const shelfSelectedFilters = useGetShelfSelectedFilters(staticFilterData, dynamicFilterData);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const [isQuickFilterOpen, setIsQuickFilterOpen] = useState(false);
  const isStoreSelected = useIsStoreSelected();
  const locale = useLocale();
  const router = useRouter();
  const isBrandPage = isBrandShelfPage(router.asPath);
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandPage });
  const isSearchPageType =
    pageType.includes('search') || router.pathname === routePaths.searchResult;
  const isShelfPageType = pageType.includes('shelf');
  const isUS = locale === countryCodes.us;
  const isBR = locale === countryCodes.ptBr;
  const hidePricing = usePriceHiding();
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    isMobile &&
    isUS &&
    (isSearchPageType || isShelfPageType) &&
    !isBrandPage;

  const { data: searchPartTypesData } = useSearchPartTypes({
    enabled: Boolean(isSearchPageType && viewingPartTypes),
    viewingPartTypes,
  });
  const { data: searchProductData } = useSearchProductData({
    enabled: Boolean(isSearchPageType && !viewingPartTypes),
  });

  const allFilters = [...(dynamicFilterData || []), ...(staticFilterData || [])];

  const quickFilters = isSearchPageType
    ? searchProductData?.staticNavigation ?? searchPartTypesData?.staticNavigation
    : allFilters.filter((filter: FilterDataType) => filter.quickFilter);

  const onRemoveChipTag = (filter: HandleFilterType) => {
    if (filter.dimensionName === 'CustomPrice') {
      removeCustomPriceRangeFilter(() => onFilterSelect?.(filter));
    } else {
      handleFilterAndRedirect(filter, onFilterSelect);
    }
  };

  const [initialSortingOptions = [], sortIsQuickFilter] = useFilteredSortingOptions() || [];
  const sortingOptions = hidePricing
    ? initialSortingOptions.filter((option) => !option.parameter.includes('price'))
    : initialSortingOptions;

  const showSortOptions = !!sortingOptions.length;
  const hasQuickFilters =
    (!!quickFilters?.length || sortIsQuickFilter || showCustomPriceRangeFilter) &&
    (isShelfPageType || isSearchPageType);

  const filterGridWidth = hasQuickFilters ? 12 : 6;
  const { data: shelfData } = useProductList();
  const { isMobileFilterOpen, setIsMobileFilterOpen } = useProductListView();
  const shelfBodyContent: ShelfPageBodyComplete | null | undefined = shelfData?.shelfPageBody;

  const countData = useMemo(() => {
    let countData = {
      recsPerPage: 0,
      total: 0,
      firstRecNum: 0,
      lastRecNum: 0,
    };

    if (shelfBodyContent) {
      const results = getResults(shelfBodyContent);
      if (results) {
        if (isSearchPageType) {
          if ('pagination' in results) {
            const { pagination } = results;
            countData = {
              recsPerPage: pagination?.pageSize,
              total: pagination?.total,
              firstRecNum: pagination?.firstRecordNumberOfCurrentPage,
              lastRecNum: pagination?.lastRecordNumberOfCurrentPage,
            };
          } else if (searchPartTypesData) {
            countData = {
              recsPerPage: searchPartTypesData.recordsPerPage ?? 0,
              total: searchPartTypesData.total ?? 0,
              firstRecNum: searchPartTypesData.firstRecNum ?? 0,
              lastRecNum: searchPartTypesData.lastRecNum ?? 0,
            };
          } else if (searchProductData) {
            countData = {
              recsPerPage: searchProductData.recordsPerPage ?? 0,
              total: searchProductData.total ?? 0,
              firstRecNum: searchProductData.firstRecNum ?? 0,
              lastRecNum: searchProductData.lastRecNum ?? 0,
            };
          }
        } else if ('firstRecNum' in results) {
          const { recsPerPage, totalNumRecs, firstRecNum, lastRecNum } = results;
          countData = {
            recsPerPage: recsPerPage ?? 0,
            total: totalNumRecs ?? 0,
            firstRecNum,
            lastRecNum,
          };
        }
      } else if (brandProductData && isBrandPage) {
        const { recordsPerPage, total, firstRecNum, lastRecNum } = brandProductData;
        countData = {
          recsPerPage: recordsPerPage,
          total: total ?? 0,
          firstRecNum: firstRecNum ?? 0,
          lastRecNum: lastRecNum ?? 0,
        };
      }
    }

    return countData;
  }, [
    isSearchPageType,
    searchPartTypesData,
    searchProductData,
    brandProductData,
    isBrandPage,
    shelfBodyContent,
  ]);

  const { lblOf, lblResults, lblFilter, lblSelectedFilters, lblSortAndFilter } =
    useLabels(labelMap);
  let mobileFilterChipset = null;

  if (!isMobileFilterOpen) {
    mobileFilterChipset = (
      <div role="group" aria-label={lblSelectedFilters} className={styles.mobileChipSection}>
        <ul role="list">
          <ChipTags chipTags={oneClickFilters} handleFilter={onRemoveChipTag} />
        </ul>
      </div>
    );
  }

  const selectedFiltersLength =
    shelfSelectedFilters?.length > 0 ? ` (${shelfSelectedFilters.length})` : '';
  const resultCount =
    countData.recsPerPage && countData.total > 0
      ? `${countData.firstRecNum}-${countData.lastRecNum} ${lblOf.toLowerCase()} ${
          countData.total
        } ${lblResults}`
      : null;
  const showQuickFiltersMobile =
    isSearchPageType && viewingPartTypes ? !Boolean(router.query.searchText) : true;

  return (
    <section ref={refQuickFilter} className={styles.filterSection}>
      <ContentContainer
        classes={cx(styles.filterSectionContainer, {
          [styles.slimFilterSectionContainer]: useNewShelfDesignMobile,
        })}
        withPadding
        isTransparent
      >
        <Grid
          container
          spacing={1}
          direction={hasQuickFilters && !isBR ? 'column-reverse' : 'row'}
          alignItems={hasQuickFilters ? 'flex-start' : 'center'}
          justify="space-between"
          data-testid="filter-and-results-count-container"
          className={cx(styles.filterGridContainer, {
            [styles.quickFilterContainer]: hasQuickFilters,
            [styles.withFilterChipset]: shelfSelectedFilters.length > 0,
          })}
        >
          <Grid item={!useNewShelfDesignMobile} md={filterGridWidth}>
            {!!resultCount && !isSearchPageType && (
              <Hidden mdUp>
                {!useNewShelfDesignMobile && (
                  <div className={styles.resultsAndShareContainer}>
                    <span
                      data-testid="mobile-results-count"
                      id="mobile-results-count"
                      className={cx(azCommonStyles['az-body-2-regular'], styles.resultsText)}
                    >
                      {resultCount}
                    </span>
                    {isSharableLinksEnabled && (
                      <ShareLinkButton
                        pageName="shelf"
                        classes={{ container: styles.shareContainer }}
                      />
                    )}
                  </div>
                )}
              </Hidden>
            )}
          </Grid>

          <Grid
            item={!useNewShelfDesignMobile}
            md={filterGridWidth}
            className={cx(styles.shelfFilterBtnGroup, {
              [styles.noScroll]: isQuickFilterOpen,
              [styles.noFilterPaddingLeft]: viewingPartTypes && !showQuickFiltersMobile,
              [styles.withGap]: useNewShelfDesignMobile,
            })}
          >
            <FilterButton
              className={cx({
                [styles.quickFilter]: !useNewShelfDesignMobile,
                [styles.slimMobileQuickFilter]: useNewShelfDesignMobile,
              })}
              onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
              data-testid="filter-button-mobile"
              icon={filterIcon}
              label={`${
                isShelfPageType && showSortOptions && !sortIsQuickFilter
                  ? lblSortAndFilter
                  : lblFilter
              } ${selectedFiltersLength}`}
            />
            {!isBR && sortIsQuickFilter && showQuickFiltersMobile && (
              <SortBy
                QuickFilterProps={{
                  handleChange: setIsQuickFilterOpen,
                }}
                sortingOptions={sortingOptions}
                isQuickFilter
              />
            )}
            {mobileFilterChipset}
            {showQuickFiltersMobile && (
              <QuickFilterMobile
                handleFilterAndRedirect={handleFilterAndRedirect}
                quickFilters={quickFilters}
                onFilterSelect={onFilterSelect}
                pageType={pageType}
                setIsQuickFilterOpen={setIsQuickFilterOpen}
                showCustomPriceRangeFilter={showCustomPriceRangeFilter}
                isStoreSelected={isStoreSelected}
              />
            )}
            {clearAllChipTag && shelfSelectedFilters?.length > 1 && (
              <li className={styles.inlineListItem}>{clearAllChipTag}</li>
            )}
          </Grid>
        </Grid>
      </ContentContainer>
    </section>
  );
};

export const FilterMobile = React.memo(FilterMobileComponent);
FilterMobile.displayName = 'FilterMobile';

type QuickFilterMobileProps = {
  pageType: string;
  onFilterSelect: ((a: HandleFilterType) => void) | undefined;
  quickFilters: FilterDataType[] | undefined | null;
  showCustomPriceRangeFilter: boolean;
  setIsQuickFilterOpen: (a: boolean) => void;
  isStoreSelected: boolean;

  handleFilterAndRedirect: (
    filter: HandleFilterType,
    onFilterSelect?: ((a: HandleFilterType) => void) | undefined
  ) => void;
};
const QuickFilterMobile = ({
  showCustomPriceRangeFilter,
  setIsQuickFilterOpen,
  quickFilters,
  isStoreSelected,
  handleFilterAndRedirect,
  onFilterSelect,
  pageType,
}: QuickFilterMobileProps) => {
  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const { lblSetCustomPriceRangeHeading } = useLabels(labelMap);

  return (
    <>
      {showCustomPriceRangeFilter && (
        <QuickFilter label={lblSetCustomPriceRangeHeading} handleChange={setIsQuickFilterOpen}>
          <div className={`${styles.filterCategory}`}>
            <FilterSubHeading title={lblSetCustomPriceRangeHeading.toUpperCase()} />
            <div className={styles.customPriceRangeContainer}>
              <CustomPriceRange quickFilter />
            </div>
          </div>
        </QuickFilter>
      )}
      {quickFilters?.map((filterItem: FilterDataType, index) => {
        const isMxPriceFilter =
          filterItem.dimensionName?.toLowerCase() === priceFilterDisplayNames.priceMX ||
          (isMX &&
            filterItem.dimensionName?.toLowerCase() === priceFilterDisplayNames.priceSearchUS);

        const isBrPriceFilter =
          locale === countryCodes.ptBr &&
          filterItem.dimensionName.toLowerCase() === priceFilterDisplayNames.priceSearchUS;

        if (
          !filterItem.refinements.length ||
          (isMxPriceFilter && !isStoreSelected) ||
          (isBrPriceFilter && !isStoreSelected)
        ) {
          return;
        }
        const { displayName } = filterItem;

        return (
          <QuickFilter
            key={`${displayName}${index}`}
            label={displayName}
            handleChange={setIsQuickFilterOpen}
          >
            <FilterDataRefinement
              classes={{
                root: styles.quickFilterRefinementRoot,
              }}
              filterItem={{ ...filterItem, quickFilter: true }}
              handleFilter={(filter) => {
                return handleFilterAndRedirect(filter, onFilterSelect);
              }}
              pageType={pageType}
            />
          </QuickFilter>
        );
      })}
    </>
  );
};
