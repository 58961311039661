/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { VehicleProductAttributesModel } from '@/api/types/browse-search-types';
import type { ProductFinder, ProductFinderItem } from '@/types/legacy';

export function getProductFinderAnalytics(
  productFinderData?: ProductFinder | VehicleProductAttributesModel[] | null
) {
  let productFinderArray: ProductFinderItem[] | undefined;
  if (productFinderData && 'ProductFinder' in productFinderData) {
    productFinderArray = productFinderData.ProductFinder;
  }

  if (productFinderData && Array.isArray(productFinderData)) {
    productFinderArray = productFinderData.map((vehicleProductAttribute) => ({
      attribute: vehicleProductAttribute.vehicleProductAttributeName,
      value: vehicleProductAttribute.vehicleProductAttributeValue,
      subtext: vehicleProductAttribute.vehicleProductAttributeValueNote,
    }));
  }

  if (Array.isArray(productFinderArray)) {
    const productFinderSpcsArray = productFinderArray.reduce<string[]>(
      (result: string[], currentValue: ProductFinderItem): string[] => {
        result.push(
          `${currentValue.attribute ? currentValue.attribute : ''} ${
            currentValue.value ? currentValue.value : ''
          }`
        );
        return result;
      },
      []
    );

    return {
      productGuide: 'true',
      productGuideSpcs: productFinderSpcsArray.join(':'),
    };
  }

  return { productGuide: 'false' };
}
