/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import type { SVGProps } from 'react';

export const StdDeliveryIcon = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5248 3.269L8.3248 0.069C8.1176 -0.023 7.8816 -0.023 7.6744 0.069L0.4744 3.269C0.1856 3.3978 0 3.6842 0 3.9994V11.9994C0 12.3154 0.1856 12.6018 0.4752 12.7306L7.6752 15.9306C7.7784 15.977 7.8888 15.9994 8 15.9994C8.1112 15.9994 8.2216 15.9762 8.3248 15.9306L15.5248 12.7306C15.8144 12.6026 16 12.3162 16 11.9994V3.9994C16 3.6842 15.8144 3.3978 15.5248 3.269ZM8.00002 1.67532L13.2304 3.99932L11.8 4.63532L6.56962 2.31132L8.00002 1.67532ZM8.00016 6.32498L2.76976 3.99938L4.60016 3.18578L9.83056 5.51058L8.00016 6.32498ZM1.59995 5.23139L7.19995 7.72019V13.9698L1.59995 11.481V5.23139ZM8.80009 13.9691V7.7195L14.4001 5.2307V11.4795L8.80009 13.9691Z"
      fill="currentColor"
    />
  </svg>
);
