/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useEffect } from 'react';
import { MenuItem } from '../../MenuItem/MenuItem';
import { MenuList } from '../../MenuList/MenuList';
import type { SortingOption } from '@/types/legacy';
import SelectField from '../StandaloneFields/SelectField';
import { useRouter } from 'next/router';
import { useLabel } from '@/hooks/useLabels';
import { setFilterScroll } from '../../../actions/shelf/shelf';
import type { SortingOptionValue } from '@/types/legacy';
import QuickFilter from '../../Filter/QuickFilter';
import type { CommonQuickFilterProps } from '../../Filter/QuickFilter/types';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import styles from './styles.module.scss';
import { useDispatch } from '@/hooks/redux/useDispatch';

export type SortByProps = {
  classes?: any;
  onChange?: Function;
  redirectOnChange?: boolean;
  isQuickFilter?: boolean;
  QuickFilterProps?: CommonQuickFilterProps;
  sortingOptions: SortingOption[];
  focusItem?: boolean;
  setFocus?: (focusSortBy: boolean, focusItemsPerPage: boolean) => void;
};

const SortBy = (props: SortByProps) => {
  const dispatch = useDispatch();
  const {
    classes = {},
    onChange,
    redirectOnChange = true,
    isQuickFilter,
    QuickFilterProps = {},
    sortingOptions = [],
    focusItem = false,
    setFocus,
  } = props;
  const featuredLabel = useLabel('label.sort.featured');
  const sortByLabel = useLabel('label.sort.sortBy');
  const options = [
    {
      parameter: 'featured' as SortingOptionValue,
      name: featuredLabel,
      enabled: true,
    },
    ...sortingOptions,
  ];
  const router = useRouter();
  const { asPath } = router;
  const { query } = parseUrl(asPath);
  const [sortSelected, setSortOption] = useState<SortingOptionValue>(
    (query.sort as SortingOptionValue) ?? 'featured'
  );

  useEffect(() => {
    if (query?.sort) {
      setSortOption(query.sort as SortingOptionValue);
    } else {
      setSortOption('featured');
    }
  }, [query.sort]);

  const changeHandler = (sortBy: SortingOptionValue) => {
    if (setFocus) {
      setFocus(true, false);
    }

    const { url, query } = parseUrl(asPath);
    setSortOption(sortBy);
    onChange?.(sortBy);

    if (redirectOnChange) {
      if (sortBy === 'featured' && query.sort) {
        delete query.sort;
      } else {
        query.sort = sortBy;
      }

      router.push(
        stringifyUrl(
          {
            url,
            query: { ...query, pageNumber: null },
          },
          {
            skipNull: true,
          }
        )
      );
    }
  };

  const handleQuickFilterChange = (isOpen: boolean) => {
    const { handleChange } = QuickFilterProps || {};
    dispatch(setFilterScroll(false));
    handleChange?.(isOpen);
  };

  if (isQuickFilter) {
    return (
      <QuickFilter label={sortByLabel} handleChange={handleQuickFilterChange} ariaRole="listbox">
        <MenuList autoFocusItem>
          {options.map((option, index) => {
            if (option.enabled) {
              return (
                <li key={`${option.name}-${index}`} tabIndex={-1}>
                  <MenuItem
                    key={`${option.name}-${index}`}
                    data-testid={`filter-${option.parameter}`}
                    selected={sortSelected === option.parameter}
                    disabled={sortSelected === option.parameter}
                    className={styles.menuItem}
                    onClick={() => changeHandler(option.parameter)}
                    tabIndex={0}
                  >
                    {option.name}
                  </MenuItem>
                </li>
              );
            }

            return null;
          })}
        </MenuList>
      </QuickFilter>
    );
  }

  return (
    <SelectField
      classes={classes}
      data-testid="sort-by-select-field"
      id="sort-by-select-field"
      name=""
      onChange={(event: any) => changeHandler(event.target.value)}
      value={sortSelected}
      hideEmptyOption
      autoFocus={focusItem}
    >
      {options.map((option, index) => {
        if (option.enabled) {
          return (
            <option
              data-testid="sort-by-option"
              key={`${option.parameter}-${index}`}
              value={option.parameter}
            >
              {option.name}
            </option>
          );
        }

        return null;
      })}
    </SelectField>
  );
};

export default SortBy;
