/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../Grid';
import azCommonStyles from '../../theme/globals.module.scss';
import Skeleton from '../Skeleton/Skeleton';
import styles from './styles.module.scss';

type Props = {
  isList: boolean;
  finalNotes?: string;
};

const ProductAvailabilitySkeleton = ({ isList, finalNotes }: Props) => {
  return (
    <>
      <div
        className={cx(azCommonStyles['az-title-1-heavy'], styles.priceContainer)}
        data-testid="priceContainer"
      >
        {isList ? (
          <div className={styles.priceLoader}>
            <Skeleton animation="wave" height={40} variant="rect" data-testid="skeleton-loader" />
          </div>
        ) : (
          <Grid container spacing={0}>
            <Grid item md={12} lg={12}>
              <div className={`${styles.priceLoader} ${styles.noPadding}`}>
                <Skeleton
                  animation="wave"
                  height={24}
                  variant="rect"
                  data-testid="skeleton-loader"
                />
              </div>
              <div className={`${styles.priceLoader} ${styles.noPadding}`}>
                <Skeleton
                  animation="wave"
                  height={24}
                  variant="rect"
                  width="50%"
                  data-testid="skeleton-loader"
                />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      <div
        className={
          isList
            ? styles.availabilityLoader
            : `${styles.availabilityLoader} ${styles.mobileAvailabilitySpacing} ${styles.noPadding}`
        }
      >
        <div className={styles.circleLoader} />
        <div className={styles.availabilityTitleLoader}>
          <Skeleton
            animation="wave"
            height={40}
            width={isList ? '100%' : '80%'}
            data-testid="skeleton-loader"
          />
        </div>
      </div>
      <div
        className={
          isList ? styles.availabilityLoader : `${styles.availabilityLoader} ${styles.noPadding}`
        }
      >
        <div className={styles.circleLoader} />
        <div className={styles.availabilityTitleLoader}>
          <Skeleton
            animation="wave"
            height={40}
            width={isList ? '100%' : '80%'}
            data-testid="skeleton-loader"
          />
        </div>
      </div>
      <div className={styles.buttonLoader} />
      {!isList && !Boolean(finalNotes) ? (
        <div className={styles.mobileNotesLoader}>
          <Skeleton animation="wave" height={24} variant="rect" data-testid="skeleton-loader" />
        </div>
      ) : null}
    </>
  );
};

export default ProductAvailabilitySkeleton;
