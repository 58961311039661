/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import StrikeThroughPriceWrapper from '@/components/AZCustomComponent/StrikethroughPricing';
import ClearanceBadgeWrapper from '@/components/AZCustomComponent/ClearanceBadge';
import CorePrice from '@/components/AZCustomComponent/CorePrice';
import { useLabels } from '@/hooks/useLabels';
import PriceDollarsAndCents from '../PriceDollarsAndCents/PriceDollarsAndCents';
import { DealsDrawerButton } from '@/features/deals/components/DrawerOpenButton/DrawerOpenButton';

type PriceDollarsProps = {
  showPriceCaption: boolean;
  configurableSku: boolean | undefined;
  showDiscountedPrice: boolean;
  dollars: string;
  cents: string;
  strikePrice: string | number | undefined;
  strikeWasPrice: string | number | undefined;
};

type DealsProps = {
  showDealDrawerButton: boolean;
  dealDrawerShelfEnabled: boolean;
  onDealClick: (ref: React.RefObject<HTMLButtonElement>) => void;
  seeDealsButtonRef: React.RefObject<HTMLButtonElement>;
  dealConfigurableLabel: string | null | undefined;
};

type Props = PriceDollarsProps &
  DealsProps & {
    clearance: boolean | undefined;
    showCartDiscountCaption: () => boolean;
    itemPresentInCart: boolean | undefined;
    corePriceForSpecificProduct: number;
    isBopusMexicoStoreEnabled: boolean;
    isMobile: boolean;
  };

const labelMap = {
  lblSeeSavingsInCart: 'label_see_savings_in_cart',
  lblAddToCartDiscount: 'label_ShelfPage_AddToCartDiscount',
};

const PriceWithSavingsInfo = (props: Props) => {
  const {
    clearance,
    showCartDiscountCaption,
    itemPresentInCart,
    corePriceForSpecificProduct,
    isBopusMexicoStoreEnabled,
    isMobile,
    strikePrice,
    strikeWasPrice,
    onDealClick,
    seeDealsButtonRef,
    dealConfigurableLabel,
    showDealDrawerButton,
    dealDrawerShelfEnabled,
    showDiscountedPrice,
  } = props;
  const labels = useLabels(labelMap);

  return (
    <div className={styles.priceWithSavingsContainer}>
      <div className={styles.priceAndStrikeThrough}>
        <PriceDollarsAndCents
          showPriceCaption={props.showPriceCaption}
          configurableSku={props.configurableSku}
          showDiscountedPrice={props.showDiscountedPrice}
          dollars={props.dollars}
          cents={props.cents}
          discountedPrice={strikePrice ? `${strikePrice}` : null}
        />
        {strikePrice && strikeWasPrice && (
          <StrikeThroughPriceWrapper price={strikePrice} wasPrice={strikeWasPrice} />
        )}
      </div>
      {clearance && (
        <div
          className={cx(
            azCommonStyles['az-margin-top-4xs'],
            azCommonStyles['az-margin-bottom-5xs']
          )}
        >
          <ClearanceBadgeWrapper />
        </div>
      )}
      {isMobile && showDealDrawerButton && dealDrawerShelfEnabled && !showDiscountedPrice && (
        <div className={cx(styles.dealsDrawerWrapper)}>
          <DealsDrawerButton
            seeDealsButtonRef={seeDealsButtonRef}
            handleClick={onDealClick}
            dealConfigurableLabel={dealConfigurableLabel}
            customClass=""
          />
        </div>
      )}

      {showCartDiscountCaption() && (
        <div className={styles.addToCartDiscount}>
          {itemPresentInCart ? labels.lblSeeSavingsInCart : labels.lblAddToCartDiscount}
        </div>
      )}

      {isBopusMexicoStoreEnabled && !isMobile && (
        <CorePrice corePrice={corePriceForSpecificProduct} />
      )}
    </div>
  );
};

export default PriceWithSavingsInfo;
