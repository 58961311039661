/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const priceFilterDisplayNames = {
  priceUS: 'price_en',
  priceMX: 'price_es',
  priceSearchUS: 'item_price_en_f',
  /**
   * @deprecated
   * LW is sending the same name "item_price_en_f" for MX and BR
   */
  priceSearchMX: 'item_price_es_f',
};
