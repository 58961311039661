/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SortingOption } from '@/types/legacy';
import { useMediaQuery } from './useMediaQuery';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useProductList } from '@/features/shelf/context/useProductList';

export const useFilteredSortingOptions = (): [SortingOption[], boolean] | undefined => {
  const locale = useLocale();
  const { data: shelfData } = useProductList();
  const sortingOptions = shelfData?.sortingOptions;

  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const options = sortingOptions?.options;
  const quickFilter = sortingOptions?.quickFilter || false;
  const currentDevice = matchesMobile ? 'mobile' : matchesTablet ? 'tablet' : 'desktop';
  const isMX = locale === countryCodes.mx;
  const storeSelected = useIsStoreSelected();
  const mxStoreSelected = isMX ? storeSelected : true;
  const optionsForCurrentDevice = getOptionsForCurrentDevice(currentDevice, options || []);

  //filter out price options if on Mexico site and store not selected
  const filteredSortingOptions = mxStoreSelected
    ? optionsForCurrentDevice
    : optionsForCurrentDevice.filter(filterPriceOptions);
  return [filteredSortingOptions, quickFilter];
};

export function getOptionsForCurrentDevice(
  currentDevice: string,
  sortingOptions: SortingOption[]
): SortingOption[] {
  return sortingOptions?.filter(
    (sortingOption: SortingOption) =>
      // @ts-expect-error fix type
      sortingOption.enabled && !!sortingOption[currentDevice]
  );
}

export function filterPriceOptions(sortingOption: SortingOption): boolean {
  const queryParmater = sortingOption?.parameter;
  return queryParmater !== 'price-asc' && queryParmater !== 'price-desc';
}
