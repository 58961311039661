/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ShelfPageBodyProductFinder } from '@/types/legacy';

export const isProductFinderBlock = (item: unknown): item is ShelfPageBodyProductFinder => {
  return Boolean(
    typeof item === 'object' &&
      item &&
      '@type' in item &&
      item['@type'] === 'ShelfPageBody_ProductFinder'
  );
};
