/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import styles from '../ProductAzWarranty/styles.module.scss';
import Title from '../../AZCustomComponent/Title';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const labelMap = {
  lblAZFooterWarranties: 'pagecontent.footer.warranties.limtedWarrantiesBodyOne',
  lblAutozoneWarranty: 'label_TermsAndConditions_Warranties_AutozoneSLimitedWarranty',
  lblUnitedStatesOnly: 'label_ShelfPage_warranty_UnitedStatesOnly',
  lblAZWarrantiesHeader: 'label.footer.warranties.limitedWarranties.header',
  lblWarrantyPageDuralistGoldBodyOne:
    'pagecontent.footer.warranties.duralistGold.duralistGoldBodyOne',
  lblWarrantyPageLimtedBodyThree: 'pagecontent.footer.warranties.limtedWarrantiesBodyThree',
  lblTermsAndConditionsWarranties:
    'label_TermsAndConditions_Warranties_AllOfAutozoneSPartsAndAccessories',
};

export const WarrantyAzModal = () => {
  const locale = useLocale();
  const labels = useLabels(labelMap);

  return (
    <div className={styles.azWarranty}>
      <Title
        firstText={labels.lblAutozoneWarranty}
        secondText={locale === countryCodes.us ? labels.lblUnitedStatesOnly : ''}
        variant="sm"
        stylesClass={azCommonStyles['az-margin-bottom-m']}
        id="modal-title"
      />
      <div className={styles.warrantyContentWrapper}>
        <div className={cx(azCommonStyles['az-body-2-regular'], styles.warrantyFooter)}>
          <p>{labels.lblAZFooterWarranties}</p>
          <p>{labels.lblWarrantyPageDuralistGoldBodyOne}</p>
          <p>{labels.lblWarrantyPageLimtedBodyThree}</p>
          <p>{locale === countryCodes.us ? labels.lblTermsAndConditionsWarranties : ''}</p>
        </div>
      </div>
    </div>
  );
};

export default WarrantyAzModal;
