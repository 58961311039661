/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState } from 'react';
import cx from 'classnames';
import { ClickAwayListener } from '../../ClickAwayListener/ClickAwayListener';
import { Popper } from '../../AZCustomComponent/Popper/Popper';
import { arrowDown } from '../../../constants/images/arrowDown';
import FilterButton from '../FilterButton';
import type { QuickFilterProps } from './types';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import usePrevious from '@/utils/usePrevious';
import { useCallback } from 'react';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo';

const QuickFilter = (props: QuickFilterProps) => {
  const { label, children, className, handleChange, ariaRole } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const { asPath } = useRouter();
  const locale = useLocale();
  const prevPath = usePrevious(asPath);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const { pageType } = usePageType();
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search ||
    pageType === pageTypes.MerchShelf;
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS && isShelfOrSearchPage;
  const handleClick = (event: any) => {
    const anchorVal = anchorEl ? null : event.currentTarget;
    setAnchorEl(anchorVal);
    setTimeout(() => {
      handleChange?.(!!anchorVal);
    }, 0);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
      handleChange?.(false);
    }
  }, [anchorEl, handleChange]);

  useEffect(() => {
    if (asPath !== prevPath && prevPath !== undefined) {
      handleClose();
    }
  }, [asPath, handleClose, prevPath]);

  const open = Boolean(anchorEl);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        data-testid={'quick-filter'}
        className={cx(
          {
            [styles.quickFilter]: !useNewShelfDesignMobile,
            [styles.slimMobileQuickFilter]: useNewShelfDesignMobile,
          },
          className
        )}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Escape' || e.keyCode === 27 || e.which === 27) {
            (e.currentTarget.children[0] as HTMLElement)?.focus();
            handleClose();
          }
        }}
      >
        <FilterButton
          isHeading
          data-testid="quick-filter-button"
          onClick={handleClick}
          icon={arrowDown}
          label={label}
          classes={{
            icon: cx({
              [styles.iconRotate]: open,
            }),
          }}
          aria-expanded={open}
          id={label.replace(/\s/g, '')}
        />
        <Popper
          disablePortal={true}
          isOpen={open}
          popperId={`${label.replace(/\s/g, '')}-region`}
          // @ts-expect-error fix type
          anchorEl={anchorEl}
          modifiers={{
            flip: {
              enabled: false,
            },
            arrow: {
              enabled: false,
            },
          }}
          classes={{
            popper: styles.quickFilterPopper,
            paper: styles.quickFilterPaper,
            root: styles.popperRoot,
          }}
          popperRole={ariaRole}
          popperAriaModal={true}
          popperAriaLabelledByID={label.replace(/\s/g, '')}
          role="region"
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default QuickFilter;
