/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { DealsModal } from '@/features/pdp';

type Props = {
  dealsModalOpen: boolean;
  skuNumber: number;
  productImage: string;
  productId: string | undefined;
  productName: string;
  handleCloseModal: () => void;
};

const ProductDeals = ({
  dealsModalOpen,
  skuNumber,
  productImage,
  productId,
  productName,
  handleCloseModal,
}: Props) => {
  if (!dealsModalOpen) {
    return null;
  } else {
    const productDetails = {
      skuId: String(skuNumber),
      productId: productId,
      skuDescription: productName,
    };
    return (
      <DealsModal
        openDialog={dealsModalOpen}
        handleCloseModal={handleCloseModal}
        mainImage={productImage}
        catalogRefId={skuNumber}
        productId={productId}
        productDetails={productDetails}
      />
    );
  }
};

export default ProductDeals;
