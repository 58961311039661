/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { IsStorePickup } from './IsStorePickup';
import { IsSTH } from './IsSTH';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { fulfillmentGroupTypeIds } from '@/constants/fulfillmentConstants';

type ConditionalFulfillmentStatesProps = {
  item: FulfillmentGroup;
  address: string | undefined;
  openSisterStore: () => void;
  toggleStoreModal: () => void;
  numberOfSisterStoreAvailable: number;
  getOptionsToDisplay: (groupFulfillmentOptions: FulfillmentOption[]) => FulfillmentOption[];
  renderFulfillmentStickers: (option: FulfillmentOption) => JSX.Element;
};

export const ConditionalFulfillmentStates = ({
  item,
  address,
  openSisterStore,
  toggleStoreModal,
  numberOfSisterStoreAvailable,
  getOptionsToDisplay,
  renderFulfillmentStickers,
}: ConditionalFulfillmentStatesProps) => {
  if (item.groupId === fulfillmentGroupTypeIds.storePickup) {
    return (
      <IsStorePickup
        item={item}
        address={address}
        openSisterStore={openSisterStore}
        toggleStoreModal={toggleStoreModal}
        numberOfSisterStoreAvailable={numberOfSisterStoreAvailable}
      />
    );
  } else {
    return (
      <IsSTH
        item={item}
        getOptionsToDisplay={getOptionsToDisplay}
        renderFulfillmentStickers={renderFulfillmentStickers}
      />
    );
  }
};
