/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export function extractOrigin(url: string): string | null {
  try {
    return new URL(url).origin;
  } catch (error) {
    return null;
  }
}
