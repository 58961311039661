/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { Select } from '../../../Select/Select';
import styles from './styles.module.scss';
import { StandaloneSelectFieldIcon } from '../StandaloneSelectFieldIcon';

type Props = {
  errorMessage?: string;
  children: React.ReactNode;
  classes?: {
    select?: string;
  };
  required?: boolean;
  id?: string;
  name?: string;
  inputProps?: any;
  hideEmptyOption?: boolean;
  onChange: Function;
  disabled?: boolean;
  autoFocus?: boolean;
  value: any;
  'data-testid'?: string;
};

const SelectField = ({
  classes,
  errorMessage,
  required,
  children,
  id = '',
  name = id,
  inputProps = {},
  hideEmptyOption,
  onChange,
  value,
  disabled,
  autoFocus,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div className={styles.selectFieldContainer}>
      <Select
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        // @ts-expect-error fix type
        onChange={onChange}
        disabled={disabled}
        data-testid={dataTestId}
        id={`at_select_id_for_${id}`}
        name={name}
        disableUnderline
        value={value}
        classes={{
          select: cx(styles.root, classes?.select),
        }}
        error={required && !!errorMessage}
        IconComponent={StandaloneSelectFieldIcon}
        inputProps={inputProps}
      >
        {!hideEmptyOption ? <option value="" /> : null}
        {children}
      </Select>
    </div>
  );
};

export default SelectField;
