/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMediaQuery } from './useMediaQuery';

export function useDeviceWrapper(contentItem: any): boolean {
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const matchesDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  return (
    (!!matchesMobile && !!contentItem?.mobile) ||
    (!!matchesTablet && !!contentItem?.tablet) ||
    (!!matchesDesktop && !!contentItem?.desktop)
  );
}
