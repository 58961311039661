/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import { useLabels } from '@/hooks/useLabels';
import Title from '../../AZCustomComponent/Title';
import Image from '@/components/NextImage';
import styles from './styles.module.scss';

const labelMap = {
  lblProformanceWarrantiesHeader: 'label_ShelfPage_warranty_Proformance',
  lblWarrantyUnlimitedMileage:
    'label_ShelfPage_warranty_3YrUnlimitedMileageNoFaultWarrantyExclusiveToAutozone',
  lblAZFooterProBodyMessage: 'pagecontent_ShelfPage_warranty_PROFormanceWarrantyContent',
  lblAZFooterAgreementMessage: 'pagecontent_ShelfPage_warranty_PROFormanceWarrantyAgreementContent',
};

export const WarrantyProformanceModal = () => {
  const labels = useLabels(labelMap);
  return (
    <div className={styles.warrantyContentWrapper}>
      <Title
        firstText={labels.lblProformanceWarrantiesHeader}
        secondText={''}
        variant="sm"
        id="modal-title"
      />
      <div className={azCommonStyles['az-body-2-regular']}>
        <div className={cx(azCommonStyles['az-body-2-heavy'], styles.footerTopContent)}>
          {labels.lblWarrantyUnlimitedMileage}
        </div>
        <div className={styles.proformanceLogo}>
          <Image
            src="/images/az-proformance-warranty.png"
            alt="performance"
            height={71}
            width={550}
          />
        </div>

        <div>{labels.lblAZFooterProBodyMessage}</div>
        <div className={styles.footerTopContent}>{labels.lblAZFooterAgreementMessage}</div>
      </div>
    </div>
  );
};

export default WarrantyProformanceModal;
