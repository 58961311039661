/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import type { SVGProps } from 'react';

export const BopusIcon = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2912 1.76616L15.2912 7.0995C15.3672 7.30416 15.3392 7.5335 15.2146 7.71283C15.0899 7.89283 14.8852 8.00016 14.6666 8.00016H13.9999V14.0002C13.9999 14.3682 13.7019 14.6668 13.3332 14.6668H2.66656C2.29789 14.6668 1.99989 14.3682 1.99989 14.0002V8.00016H1.33322C1.11456 8.00016 0.90989 7.89283 0.784557 7.71283C0.660557 7.5335 0.632556 7.30416 0.708556 7.0995L2.70856 1.76616C2.80656 1.50616 3.05522 1.3335 3.33322 1.3335H12.6666C12.9446 1.3335 13.1932 1.50616 13.2912 1.76616ZM11.8546 6.66683H13.7046L12.2046 2.66683H10.8546L11.8546 6.66683ZM6.52056 2.66683L5.52056 6.66683H7.33322V2.66683H6.52056ZM9.47922 2.66683H8.66656V6.66683H10.4792L9.47922 2.66683ZM5.14522 2.66683H3.79522L2.29522 6.66683H4.14522L5.14522 2.66683ZM9.33322 10.0002V13.3335H10.6666V10.0002H9.33322ZM11.9999 13.3335H12.6666V8.00016H3.33322V13.3335H7.99989V9.3335C7.99989 8.9655 8.29789 8.66683 8.66656 8.66683H11.3332C11.7019 8.66683 11.9999 8.9655 11.9999 9.3335V13.3335ZM6.66654 12.0002H4.66654C4.29788 12.0002 3.99988 11.7015 3.99988 11.3335V9.3335C3.99988 8.9655 4.29788 8.66683 4.66654 8.66683H6.66654C7.03521 8.66683 7.33321 8.9655 7.33321 9.3335V11.3335C7.33321 11.7015 7.03521 12.0002 6.66654 12.0002Z"
      fill="currentColor"
    />
  </svg>
);
