/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Grid } from '../Grid';
import azCommonStyles from '../../theme/globals.module.scss';
import Title from '../AZCustomComponent/Title';
import styles from './styles.module.scss';

type Props = {
  title: string;
  boldTitle: string | undefined;
  seoCopyBlock: string | null | undefined;
  descriptionClassName?: string;
};

const SEOCopyBlock = (props: Props) => {
  const { title, boldTitle, seoCopyBlock, descriptionClassName } = props;
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Grid container justify="space-around" spacing={0} className={styles.copyContainer}>
      <Grid item xs={12} lg={4} className={`${styles.header}`}>
        <Title
          firstText={title}
          secondText={boldTitle}
          firstTextClass={azCommonStyles['az-title-2-regular']}
          secondTextClass={styles.secondaryText}
          isSameTitleTag
          swapStyle
        />
        {matchesTablet && <Title underline stylesClass={styles.titleLine} />}
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        className={cx(azCommonStyles['az-body-1-regular'], styles.catDesc, descriptionClassName)}
        dangerouslySetInnerHTML={{
          __html: seoCopyBlock || '',
        }}
      />
    </Grid>
  );
};

export default SEOCopyBlock;
