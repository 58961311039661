/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRouter } from 'next/router';
import { parseUrl, stringifyUrl } from '../utils/urlHelpers';

const useRemoveAppliedPriceFilters = (): ((onRemoveFilter?: Function) => void) => {
  const router = useRouter();

  const removeAppliedPriceFilters = (onRemoveFilter?: Function) => {
    const { asPath } = router;
    const { url, query } = parseUrl(asPath);
    let tempUrl = url;
    delete query.maxPrice;
    delete query.minPrice;
    tempUrl = stringifyUrl(
      {
        url: tempUrl,
        query: {
          ...query,
          seourl: null,
          seoUrlPath: null,
          pageNumber: null,
          currentPage: null,
        },
      },
      {
        skipNull: true,
      }
    );
    onRemoveFilter?.();
    router.push(tempUrl);
  };

  return removeAppliedPriceFilters;
};

export { useRemoveAppliedPriceFilters };
