/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { requestBaseURL } from '@/config/serviceAPI';
import { type AxiosInstance } from 'axios';
import { createQuery } from '@/utils/createReactQuery';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { ProductShelfYearsData, YearsResponse } from '../interface';
import { GetYearsBlockUsingGetParamsModel } from '@/api/types/browse-search-types';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { type QueryClient } from '@tanstack/react-query';

const URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/category-years`;

type Options = {
  seoUrl: string;
  locale: Locale;
  year: boolean;
  preview: boolean;
};

type OptionsQuery = {
  seoUrl?: string;
  enabled?: boolean;
  year: boolean;
};

const getCategoryYears = async (
  { seoUrl, locale, year, preview }: Options,
  axiosInstance?: AxiosInstance
) => {
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';
  const yearBlockType = year ? 'ALTERNATE' : 'ALL';

  const params: GetYearsBlockUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    preview,
    seoUrl: parseUrl(seoUrl).url,
    yearBlockType,
  };
  const response = await getAxios(axiosInstance).get<YearsResponse>(URL, {
    params,
  });
  return selector(response.data);
};

const selector = (data: YearsResponse): ProductShelfYearsData => {
  const response = data.years.map((item) => ({
    name: item.linkDisplayText,
    seoUrl: item.seoUrl,
    makeModelYearPath: item.makeModelYearPath,
  }));

  return {
    shopByMMYList: response,
    title: data.title,
    canonicalPath: data.canonicalPath,
    taxonomyPath: data.taxonomyPath,
    pageType: data.pageType,
  };
};

const { useData: useCategoryYearsData, prefetch: prefetchCategoryYearsData } = createQuery<
  ProductShelfYearsData,
  Options
>('categoryYears', getCategoryYears);

export const useCategoryYears = ({ enabled = true, ...rest }: OptionsQuery) => {
  const router = useRouter();
  const locale = useLocale();

  const options: Options = {
    seoUrl: router.asPath,
    ...rest,
    locale,
    preview: showXMPreviewDate(),
  };
  return useCategoryYearsData({
    enabled: enabled,
    ...options,
  });
};

export const prefetchCategoryYears = (
  options: Options,
  queryClient: QueryClient,
  axios: AxiosInstance
) => {
  return prefetchCategoryYearsData(queryClient, options, axios);
};
