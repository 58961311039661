/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Drawer } from '../../Drawer/Drawer';
import type { FilterDataType, HandleFilterType } from '../../../types/filterTypes';
import { Toolbar } from '../../Toolbar';
import { AppBar } from '../../AppBar';
import { IconButton } from '../../IconButton/IconButton';
import { Grid } from '../../Grid';
import ChipTags from '../ChipTags';
import SortBy from '../../AZCustomComponent/SortBy';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { parseUrl } from '../../../utils/urlHelpers';
import FilterDataRefinement from '../FilterDataRefinement';
import CustomPriceRange from '../../AZCustomComponent/CustomPriceRange';
import CustomizedExpansionPanel from '../../AZCustomComponent/ExpansionPanel';
import { closeLight } from '../../../constants/images';
import { useFilteredSortingOptions } from '../../../hooks/useFilteredSortingOptions';
import { useShowCustomPriceRangeFilter } from '../../../hooks/useShowCustomPriceRangeFilter';
import { useFilterAndRedirect } from '../../../hooks/useFilterAndRedirect';
import { FACETED_URL } from '@/utils/validatorRegex';
import { useRouter } from 'next/router';
import ClearFiltersButton from '../ClearFiltersButton';
import { labelMap } from '../FilterMobile/labels';
import { useRemoveAppliedPriceFilters } from '../../../hooks/useRemoveAppliedPriceFilters';
import azCommonStyles from '../../../theme/globals.module.scss';
import ProgressModal from '../../AZCustomComponent/ProgressModal';
import { ReduxState } from '../../../types';
import { useGetShelfSelectedFilters } from '../../../hooks/useGetShelfSelectedFilters';
import FilterSubHeading from './../FiltersubHeading';
import CustomExpandMoreIcon from '@/public/images/select-chevron-down.svg';
import iconStyles from '@/theme/iconStyles.module.scss';
import { priceFilterDisplayNames } from '../priceFilterDisplayNames';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useIsAccordionOpen } from '@/features/shelf/hooks/useIsAccordionOpen';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import { countryCodes } from '@/constants/locale';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import styles from './styles.module.scss';

type Props = {
  staticFilterData?: Array<FilterDataType> | null;
  dynamicFilterData?: Array<FilterDataType> | null;
  pageType: string;
  showSpinner?: boolean;
  onFilterSelect?: (a: HandleFilterType) => void;
};

const FilterMobileDrawerComponent = ({
  staticFilterData,
  dynamicFilterData,
  pageType,
  showSpinner = false,
  onFilterSelect,
}: Props) => {
  const removeCustomPriceRangeFilter = useRemoveAppliedPriceFilters();
  const handleFilterAndRedirect = useFilterAndRedirect();
  const showCustomPriceRangeFilter = useShowCustomPriceRangeFilter();
  const shelfSelectedFilters = useGetShelfSelectedFilters(staticFilterData, dynamicFilterData);
  const hidePricing = usePriceHiding();
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);
  const isABot = deviceType === 'bot';
  const router = useRouter();
  const { isMobileFilterOpen, setIsMobileFilterOpen, accordionDispatch } = useProductListView();
  const locale = useLocale();
  const isMxSite = locale === countryCodes.mx;

  const isAccordionOpen = useIsAccordionOpen();
  const storeSelected = useIsStoreSelected();
  const lowerCasePageType = pageType?.toLowerCase();
  const isShelfPageType = lowerCasePageType?.includes('shelf');
  const isSearchPageType = lowerCasePageType?.includes('search');
  const [applyBtn, setApplyBtn] = useState(false);
  const [initialSortingOptions = []] = useFilteredSortingOptions() || [];
  const sortingOptions = hidePricing
    ? initialSortingOptions.filter((option) => !option.parameter.includes('price'))
    : initialSortingOptions;
  const showSortOptions = !!sortingOptions.length;
  const {
    lblSortAndFilter,
    lblFilterResults,
    lblApplyFilters,
    lblSetCustomPriceRangeHeading,
    lblSelectedFilters,
    lblCloseDrawerBtn,
  } = useLabels(labelMap);

  const drawerRef = useRef<HTMLDivElement | null>(null);
  const handleFilter = (filter: HandleFilterType) => {
    onFilterSelect?.(filter);
  };
  const { data: headerData } = useHeaderData();
  const hasVehicle = headerData?.vehicleMap?.vehicleId !== '';

  const onRemoveChipTag = (filter: HandleFilterType) => {
    if (filter.dimensionName === 'CustomPrice') {
      removeCustomPriceRangeFilter(() => handleFilter(filter));
    } else {
      handleFilterAndRedirect(filter, handleFilter);
    }
  };

  const { url, query } = parseUrl(router.asPath);

  const handleClose = () => {
    setApplyBtn(false);
    setIsMobileFilterOpen(false);
  };

  const toggleIsMobileFilterOpen = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const showSortByMobile =
    (isShelfPageType || isSearchPageType) && router.query.partTypesView === 'true'
      ? !Boolean(router.query.searchText)
      : true;

  const renderFilterTitle = () => (
    <h1 className={cx(azCommonStyles['az-body-1-regular'], styles.filter)}>
      {(showSortOptions && showSortByMobile ? lblSortAndFilter : lblFilterResults).toUpperCase()}
    </h1>
  );

  const showClearFilterButton =
    applyBtn || shelfSelectedFilters.length > 0 || FACETED_URL.test(url) || query.filters;
  const clearFilterButton = showClearFilterButton ? (
    <ClearFiltersButton
      pageType={pageType}
      selectedFilters={shelfSelectedFilters}
      onClick={toggleIsMobileFilterOpen}
      isMobileDrawer
    />
  ) : null;
  const filterHeader = (
    <Grid item className={styles.filterHeadingSection}>
      <Grid
        container
        className={styles.tabletChipsContainer}
        alignItems="center"
        justify="space-between"
        spacing={0}
      >
        <Grid item md={12} className={styles.drawerChipsSection}>
          <div role="group" aria-label={lblSelectedFilters}>
            {clearFilterButton}
            <ul role="list>">
              <ChipTags
                chipTags={shelfSelectedFilters}
                handleFilter={onRemoveChipTag}
                isMobileDrawer
              />
            </ul>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );

  const displayStaticFilters = () => {
    if (staticFilterData) {
      return (
        <>
          {showCustomPriceRangeFilter && (
            <li key={`${lblSetCustomPriceRangeHeading}`}>
              <CustomizedExpansionPanel
                theme="white"
                title={lblSetCustomPriceRangeHeading}
                onChange={(expanded: boolean) => {
                  accordionDispatch({
                    dimensionName: lblSetCustomPriceRangeHeading.toUpperCase(),
                    expanded,
                  });
                }}
                expandIcon={
                  <CustomExpandMoreIcon
                    aria-hidden
                    className={cx(iconStyles.defaultIconStyle, styles.customExpandMoreIconMobile)}
                  />
                }
                defaultExpandedProp={
                  isABot ||
                  isAccordionOpen({
                    dimensionName: lblSetCustomPriceRangeHeading.toUpperCase(),
                  })
                }
                summary={
                  <FilterSubHeading
                    classes={{
                      root: styles.filterSubheadingContainer,
                    }}
                    title={lblSetCustomPriceRangeHeading}
                  />
                }
                classes={{
                  summary: styles.filterWrapper,
                }}
                content={
                  <div
                    className={styles.filterCategory}
                    data-testid={`filter-group-${lblSetCustomPriceRangeHeading.toUpperCase()}`}
                  >
                    <div className={styles.customPriceRangeContainer}>
                      <CustomPriceRange />
                    </div>
                  </div>
                }
              />
            </li>
          )}
          {staticFilterData.map((filterItem: FilterDataType, index: number) => {
            if (!filterItem.refinements.length) {
              return;
            }
            const filterName = filterItem.dimensionName?.toLowerCase();

            if (hidePricing && filterName.includes('price')) {
              return;
            }

            const isMxPriceFilter =
              filterName === priceFilterDisplayNames.priceMX ||
              (isMxSite && filterName === priceFilterDisplayNames.priceSearchUS);
            const isBrPriceFilter =
              locale === countryCodes.ptBr && filterName === priceFilterDisplayNames.priceSearchUS;
            if (
              (isBrPriceFilter && !storeSelected) ||
              (isMxPriceFilter && !storeSelected) ||
              (filterItem.refinements.length === 1 && filterItem.refinements[0]?.label === '0')
            ) {
              return;
            } else {
              return (
                <li key={`${filterItem.displayName}${filterItem.dimensionName}`}>
                  <CustomizedExpansionPanel
                    key={`${filterItem.displayName}${filterItem.dimensionName}`}
                    theme="white"
                    title={filterItem.displayName}
                    defaultExpandedProp={
                      isABot || isAccordionOpen(filterItem, showCustomPriceRangeFilter, index)
                    }
                    onChange={(expanded: boolean) => {
                      accordionDispatch({ dimensionName: filterItem.dimensionName, expanded });
                    }}
                    expandIcon={
                      <CustomExpandMoreIcon
                        aria-hidden
                        className={cx(
                          iconStyles.defaultIconStyle,
                          styles.customExpandMoreIconMobile
                        )}
                      />
                    }
                    summary={
                      <FilterSubHeading
                        classes={{
                          root: styles.filterSubheadingContainer,
                        }}
                        title={filterItem.displayName}
                      />
                    }
                    classes={{
                      summary: styles.filterWrapper,
                    }}
                    content={
                      <FilterDataRefinement
                        filterItem={{ ...filterItem, quickFilter: false }}
                        handleFilter={(filter) => handleFilterAndRedirect(filter, handleFilter)}
                        pageType={pageType}
                        showFilterSubHeading={false}
                        hasVehicle={hasVehicle}
                      />
                    }
                  />
                </li>
              );
            }
          })}
        </>
      );
    }
  };

  const displayDynamicFilters = () => {
    if (dynamicFilterData) {
      return dynamicFilterData.map((filterItem: FilterDataType, index: number) => (
        <li key={`${filterItem.displayName}${filterItem.dimensionName}`}>
          <CustomizedExpansionPanel
            key={`${filterItem.displayName}${filterItem.dimensionName}`}
            theme="white"
            title={filterItem.displayName}
            onChange={(expanded: boolean) => {
              accordionDispatch({ dimensionName: filterItem.dimensionName, expanded });
            }}
            defaultExpandedProp={
              isABot ||
              isAccordionOpen(
                filterItem,
                showCustomPriceRangeFilter,
                index,
                staticFilterData?.length
              )
            }
            expandIcon={
              <CustomExpandMoreIcon
                aria-hidden
                className={cx(iconStyles.defaultIconStyle, styles.customExpandMoreIconMobile)}
              />
            }
            summary={
              <FilterSubHeading
                classes={{
                  root: styles.filterSubheadingContainer,
                }}
                title={filterItem.displayName}
              />
            }
            classes={{
              summary: styles.filterWrapper,
            }}
            content={
              <FilterDataRefinement
                key={`${filterItem.displayName}${filterItem.dimensionName}`}
                filterItem={filterItem}
                handleFilter={(filter) => handleFilterAndRedirect(filter, handleFilter)}
                pageType={pageType}
                showFilterSubHeading={false}
                hasVehicle={hasVehicle}
              />
            }
          />
        </li>
      ));
    }
  };

  return (
    <>
      {showSpinner && isMobileFilterOpen && <ProgressModal noScroll />}
      <TrapFocus open={isMobileFilterOpen}>
        <div role="dialog" aria-modal="true">
          <Drawer
            drawerRef={drawerRef}
            anchor="right"
            open={isMobileFilterOpen}
            classes={{
              paperAnchorRight: styles.drawerMobile,
            }}
            stickyBtnLabel={lblApplyFilters.toUpperCase()}
            handleStickyButtonClick={toggleIsMobileFilterOpen}
          >
            <AppBar position="static">
              <Toolbar>
                {renderFilterTitle()}
                <IconButton
                  color="inherit"
                  ariaLabel={lblCloseDrawerBtn}
                  role="button"
                  onClick={handleClose}
                  className={styles.toolbarIconButton}
                  data-testid="close-filter-drawer-button"
                >
                  <img src={closeLight.src} alt={closeLight.alt} />
                </IconButton>
              </Toolbar>
            </AppBar>
            {filterHeader}
            {showSortOptions && showSortByMobile && (
              <div className={`${styles.filterContainer} ${styles.sortByFilterSection}`}>
                <h2 className={cx(azCommonStyles['az-overline'], styles.filterSubHeading)}>
                  <Label label="label.sort.sortBy" />
                </h2>
                <SortBy
                  onChange={toggleIsMobileFilterOpen}
                  classes={{
                    select: styles.sortByMobile,
                  }}
                  sortingOptions={sortingOptions}
                />
              </div>
            )}
            <ul>
              {displayStaticFilters()}
              {displayDynamicFilters()}
            </ul>
          </Drawer>
        </div>
      </TrapFocus>
    </>
  );
};

export const FilterMobileDrawer = React.memo(FilterMobileDrawerComponent);
