/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { FilterSelectionType } from '@/types/filterTypes';
import { sessionStorage } from '@/utils/sessionStorage';

export const useGetSessionFiltersList = () => {
  const sessionFilterAnalytics = sessionStorage.getItem('filterSelectionAnalytics');
  const filterSelections = sessionFilterAnalytics?.split(' | ');
  let filterAnalytics: FilterSelectionType[] = [];

  filterSelections?.forEach((filterSelection) => {
    const filterParts = filterSelection.split(': ');
    const parsedValues = filterParts[2].split(', ');

    parsedValues.forEach((value) => {
      filterAnalytics.push({
        type: filterParts[0],
        facet: filterParts[1],
        value: value,
      });
    });
  });

  return filterAnalytics;
};
