/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useRef, useState, useReducer, createRef } from 'react';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';
import { defaultStoreUS, countryCodes } from '@/constants/locale';
import { productInfoConstants } from '@/constants/productInfoConstants';
import usePrevious from '@/utils/usePrevious';
import { ReduxState } from '@/types';
import PaginationComp from '@/components/AZCustomComponent/Pagination/Pagination';
import { useRouter } from 'next/router';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useProductList } from '../../../../context/useProductList';
import { YMMEModal } from '@/features/ymme/components/YMMEModal';
import style from '@/components/PageTemplates/Generic/styles.module.scss';
import { NoPartFound } from './NoPartFound';
import styles from './viewStyles.module.scss';
import { ShelfProduct } from './Product';
import type { InteractionLocation } from '@/types/analytics';
import type { Locale } from '@/types/i18n';
import { useLabel } from '@/hooks/useLabels';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { ShelfPart } from '@/types/legacy';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import useRefDimensions from '@/hooks/useRefDimensions';
import { useProductListView } from '../../../../context/useProductListView';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';
import { WarrantyModal } from '@/components/ProductDetail/ProductWarranty';
import useAreElementsVisible from '@/hooks/useAreElementsVisible';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { CMSCitrusShelf } from '@/features/contentstack/components/CMSCitrus/CMSCitrusShelf';
import { useSeoMetadata } from '@/features/common/api/getSeoMetadata';
import { useCitrusShelfData } from '@/features/citrus/api/getCitrusShelfData';
import { usePageType as usePageTypeLegacy } from '@/hooks/usePageType';
import { usePageType } from '@/features/category/api/getPageType';

export type Props = {
  recsPerPage: number;
  firstRecNum: number;
  totalNumRecs: number;
  locale: Locale | undefined;
  isPaginationEnabled: boolean;
  handleDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentTypeId: number | null
  ) => void;
  xFusionQueryId?: string;
};

type WarrantyModal = {
  isShown: boolean;
  showModalFrom?: string;
  warrantyType?: string;
};

const shelfCheckFits: InteractionLocation = '"Check if this fits your vehicle" label';

export const ResultsList = ({
  recsPerPage,
  firstRecNum,
  totalNumRecs,
  locale,
  handleDealClick,
  isPaginationEnabled,
  xFusionQueryId,
}: Props) => {
  const { pageType } = usePageTypeLegacy();
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const router = useRouter();
  const { query } = router;

  const { listViewState } = useProductListView();
  const isListView = isBrandShelfPage(router.asPath)
    ? listViewState.isBrandListView
    : listViewState.isListView;
  const [warrantyModal, setWarrantyModal] = useState<WarrantyModal>({
    isShown: false,
  });
  const shelfReadyStatus = useSelector(({ shelf }: ReduxState) => shelf.readyStatus);
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();

  const { data: shelfData, setShouldFetchSecondaryProductSkus } = useProductList();
  const [hasFetchedSecondarySkus, setHasFetchedSecondarySkus] = useState(false);

  const parts = shelfData?.shelfParts;

  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const miniCartItemCount = miniCartMap?.itemCount || 0;
  const previousMiniCartItemCount = usePrevious(miniCartItemCount);

  const isSYMMPage = shelfData?.makeModelYearPageType;
  const { data: storeDetails } = useStoreDetailsData();

  const { data: pageTypeData } = usePageType();
  const { data: seoMetadataInfo } = useSeoMetadata({
    enabled: true,
    vehicleMake: pageTypeData?.make ?? undefined,
    vehicleModel: pageTypeData?.model ?? undefined,
    vehicleYear: pageTypeData?.year ?? undefined,
  });

  const storeNumber =
    locale === countryCodes.us
      ? storeDetails?.storeNumber || defaultStoreUS
      : storeDetails?.storeNumber;

  const vehicleId = headerData?.vehicleMap.vehicleId;
  const hasVehicle = Boolean(vehicleId);
  const vehicleName = headerData?.vehicleMap.vehicleName ?? '';

  const shelfCatLabel =
    typeof router.query.searchText === 'string'
      ? router.query.searchText
      : seoMetadataInfo?.pageHeadingName ?? shelfData?.shelfCatLabel;

  const brandNameShelf = shelfData?.brandName;

  const handleCloseWarrantyModal = () => setWarrantyModal({ isShown: false });

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const previousWindowWidth = usePrevious(windowWidth);
  const resultListRef = useRef<HTMLDivElement>(null);
  const shelfGridDimensions = useRefDimensions(resultListRef);
  const [resultListHeight, setResultListHeight] = useState(shelfGridDimensions.height);
  const previousResultListHeight = usePrevious(resultListHeight);

  const [showYmme, toggleShowYmme] = useReducer((show: boolean) => !show, false);
  const [resizedGridContents, setResizedGridContents] = useState(false);
  const shelfItemRefs = useRef([]);
  const isElementVisible = useAreElementsVisible(shelfItemRefs.current.slice(5));

  const setElementHeight = (el: HTMLElement | null | undefined, newHeight?: number) => {
    if (newHeight && el) {
      el.style.minHeight = `${newHeight}px`;
    } else if (el) {
      el.style.minHeight = 'auto';
    }
  };

  const getSectionElement = (
    column: HTMLElement | null,
    section: string
  ): HTMLElement | undefined => {
    const sectionElements = column ? Array.from(column.querySelectorAll<HTMLElement>(section)) : [];
    if (sectionElements.length > 1) {
      return sectionElements.find((elm) => elm?.offsetHeight > 0);
    }

    return sectionElements[0];
  };

  function getSectionHeightForColumn(column: HTMLElement | null, section: string) {
    if (column) {
      const sectionElement = getSectionElement(column, section);
      sectionElement && setElementHeight(sectionElement);

      return sectionElement?.offsetHeight;
    }
  }

  const reSizableSectionClasses = [
    '.fulfillment-option-wrapper',
    '.product-title',
    '.product-price-wrapper',
    '.product-detail-wrapper',
    '.fitment-button-container',
    '.product-notes-grid-view',
    '.product-attributes-container',
  ];
  // TODO replace with useMediaQuery
  const isWidthMobileOrTablet = windowWidth <= 1139 || window.outerWidth <= 1139;
  const isTabletOnly = windowWidth <= 1139 && windowWidth > 719;
  const RR_AZRMOB_SHELF_ENABLED = useFeatureFlag('RR_AZRMOB_SHELF_ENABLED');
  const RR_AZRMOB_SHELF_TAG = useFeatureFlag('RR_AZRMOB_SHELF_TAG');
  const RR_AZRWEB_SHELF_ENABLED = useFeatureFlag('RR_AZRWEB_SHELF_ENABLED');
  const RR_AZRWEB_SHELF_TAG = useFeatureFlag('RR_AZRWEB_SHELF_TAG');
  const enable24ProductView = useIs24ProductViewEnabled();
  const customHeaderScript =
    isWidthMobileOrTablet && RR_AZRMOB_SHELF_ENABLED === 'true'
      ? RR_AZRMOB_SHELF_TAG
      : RR_AZRWEB_SHELF_ENABLED === 'true'
      ? RR_AZRWEB_SHELF_TAG
      : '';

  const citrusShelfBannersEnabled = useFeatureFlag('IS_CITRUS_SHELF_BANNERS_ENABLED') === 'true';
  const { data: citrusData } = useCitrusShelfData({ enabled: citrusShelfBannersEnabled });
  const hasCitrusMidBanner = !citrusShelfBannersEnabled
    ? false
    : citrusData?.ads.find(
        (ads) => ads.slotId === 'category-shelf-midpage-banner' && !!ads.banners.length
      );

  function resizeGridContents(shelfItems: Array<React.RefObject<HTMLElement>>) {
    const shelfItemsLength = shelfItems.length;
    const columnsPerRow = matchesTablet ? 2 : 3;
    const numOfRows = Math.ceil(shelfItemsLength / columnsPerRow);
    let i;

    for (i = 0; i < numOfRows; i++) {
      const columnStartIndex = i * columnsPerRow;
      const columns = shelfItems.slice(columnStartIndex, columnStartIndex + columnsPerRow);
      reSizableSectionClasses.forEach((section) => {
        let longestColumnSectionHeight = 0;
        columns.forEach((column) => {
          const currentColumn = column.current;
          const columnSectionHeight = getSectionHeightForColumn(currentColumn, section);

          if (columnSectionHeight && columnSectionHeight > longestColumnSectionHeight) {
            longestColumnSectionHeight = columnSectionHeight;
          }
        });
        columns.forEach((column) => {
          const currentColumn = column.current;
          const columnSection = getSectionElement(currentColumn, section);
          setElementHeight(columnSection, longestColumnSectionHeight);
        });
      });
    }
  }

  function resetGridHeights(shelfItems: Array<React.RefObject<HTMLElement>>) {
    shelfItems.forEach((shelfItem) => {
      const currentItem = shelfItem.current;
      reSizableSectionClasses.forEach((section) => {
        const shelfItemSection = currentItem?.querySelectorAll<HTMLElement>(section)[0];
        setElementHeight(shelfItemSection);
      });
    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let timeoutId: ReturnType<typeof setTimeout>;
      resizeGrids();
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setWindowWidth(window.innerWidth), 150);
      };

      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      enable24ProductView &&
      isElementVisible &&
      !hasFetchedSecondarySkus &&
      setShouldFetchSecondaryProductSkus
    ) {
      setShouldFetchSecondaryProductSkus(true);
      setHasFetchedSecondarySkus(true);
    }
  }, [
    enable24ProductView,
    isElementVisible,
    hasFetchedSecondarySkus,
    setShouldFetchSecondaryProductSkus,
  ]);

  const resizeGrids = () => {
    const shelfItems = shelfItemRefs.current;
    const shelfItemsLength = shelfItems.length;
    const shelfItemsExist = shelfItems && shelfItemsLength && shelfItemsLength > 1;

    if (shelfItemsExist && !isListView && !matchesMobile) {
      resizeGridContents(shelfItems);
      setResizedGridContents(true);
    } else if (resizedGridContents && (isListView || matchesMobile)) {
      resetGridHeights(shelfItems);
      setResizedGridContents(false);
    }
  };

  useEffect(() => {
    if (resultListRef.current) {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver.observe(resultListRef.current);
      }
    }

    let timeoutId: ReturnType<typeof setTimeout>;

    if (
      (previousResultListHeight === 0 && resultListHeight === 0) ||
      previousResultListHeight !== resultListHeight ||
      windowWidth !== previousWindowWidth ||
      miniCartItemCount !== previousMiniCartItemCount
    ) {
      timeoutId = setTimeout(() => {
        resizeGrids();
      }, 150);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListView, resultListHeight, windowWidth, miniCartItemCount]);

  if (parts && shelfItemRefs.current.length !== parts.length) {
    shelfItemRefs.current = Array(parts.length)
      .fill(null)
      .map((_, i) => shelfItemRefs.current[i] || createRef());
  }

  const fetchPaginatedList = (_: unknown, pageNumber: number) => {
    const {
      // we ignore eslint here because destructuring got used to omit prop from object
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: { cmsPath, slug, seoUrlPath, brandName, ...restQuery },
      asPath,
    } = router;
    setHasFetchedSecondarySkus(false);
    void router.push({
      pathname: getPathnameFromAsPath(asPath.trim()),
      query: { ...restQuery, pageNumber },
    });
  };

  const hasSearchTextQuery = Boolean(query.searchText);
  const isMobileQRExperience = useIsMobileQRExperience();

  const shouldShowSecondBanner = (!isSYMMPage || hasSearchTextQuery) && parts && parts.length > 3;

  const whereToShowBanner = !isListView && isTabletOnly ? 4 : 3;
  const brandPartsNotAvailable = useLabel(
    'label_brandpage_resultsList_isCurrentlyNotAvailableForYour'
  );
  const forLabel = useLabel('label_Search_for');
  const forYourLabel = useLabel('label_Search_for_your');
  const resultsLabel = useLabel('label_Search_results');
  const noPartsFoundLabel = isBrandShelfPage(router.asPath)
    ? `${brandNameShelf ?? ''} ${brandPartsNotAvailable}`
    : `${parts?.length ? String(parts.length) : '0'} ${resultsLabel} ${forLabel} ${
        shelfCatLabel ?? ''
      } ${forYourLabel}`;

  let resizeObserver: ResizeObserver | undefined;

  try {
    resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        entries.forEach((entry) => {
          if (entry.target.getBoundingClientRect().height !== previousResultListHeight) {
            setResultListHeight(entry.target.getBoundingClientRect().height);
          }
        });
      });
    });
  } catch (e) {
    resizeObserver = undefined;
  }

  return (
    <Grid
      container
      spacing={0}
      ref={resultListRef}
      className={style.results}
      id="shelf-result-list"
    >
      {parts?.length === 0 ? (
        <NoPartFound noPartsFoundLabel={noPartsFoundLabel} vehicleName={vehicleName} />
      ) : null}
      <ul>
        {parts?.slice(0, whereToShowBanner)?.map((part, index) => (
          <ShelfProduct
            key={`${part.skuNumber}${index}`}
            hasVehicle={hasVehicle}
            pageType={pageType}
            index={index}
            onDealClick={handleDealClick}
            part={part}
            shelfItemRef={shelfItemRefs.current[index]}
            isListItem
            shelfReadyStatus={shelfReadyStatus}
            storeNumber={storeNumber}
            toggleShowYmme={toggleShowYmme}
            setWarrantyModal={setWarrantyModal}
            resizeShelfGrids={
              !resizeObserver
                ? () =>
                    setTimeout(() => {
                      resizeGrids();
                    }, 150)
                : undefined
            }
            xFusionQueryId={xFusionQueryId}
          />
        ))}
        {shouldShowSecondBanner && !isMobileQRExperience ? (
          <>
            <FeatureFlag flag="IS_CITRUS_ENABLED">
              {hasCitrusMidBanner ? (
                <li className={styles.secondBannerBackGroundCard}>
                  <CMSCitrusShelf content={{ slot_id: 'category-shelf-midpage-banner' }} />
                </li>
              ) : null}
            </FeatureFlag>
          </>
        ) : null}
        {parts?.slice(whereToShowBanner)?.map((part, index) => {
          return (
            <ShelfProduct
              key={part.skuNumber}
              pageType={pageType}
              hasVehicle={hasVehicle}
              index={index + whereToShowBanner}
              onDealClick={handleDealClick}
              part={part}
              isListItem
              shelfItemRef={shelfItemRefs.current[index + whereToShowBanner]}
              shelfReadyStatus={shelfReadyStatus}
              storeNumber={storeNumber}
              toggleShowYmme={toggleShowYmme}
              setWarrantyModal={setWarrantyModal}
              resizeShelfGrids={
                !resizeObserver
                  ? () =>
                      setTimeout(() => {
                        resizeGrids();
                      }, 150)
                  : undefined
              }
              xFusionQueryId={xFusionQueryId}
            />
          );
        })}
      </ul>
      {isPaginationEnabled && parts?.length ? (
        <PaginationComp
          offset={firstRecNum}
          limit={recsPerPage}
          total={totalNumRecs}
          performFetch={fetchPaginatedList}
          pageName={productInfoConstants.productShelfName}
        />
      ) : (
        <div className={styles.noPaginationSection} />
      )}
      <YMMEModal
        onUpdateVehicleSuccess={redirectAfterVehicleChanged}
        showYmme={showYmme}
        closeYmmeWidget={toggleShowYmme}
        pageType={shelfCheckFits}
      />
      {warrantyModal.isShown ? (
        <WarrantyModal
          showWarranty={warrantyModal.isShown}
          closeWarrantyModal={handleCloseWarrantyModal}
          warrantyType={warrantyModal.warrantyType}
        />
      ) : null}
      {customHeaderScript ? <Script src={customHeaderScript} strategy="lazyOnload" /> : null}
    </Grid>
  );
};
