/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { normalizeMakeName } from '@/utils/normalizeMakeName';
import { normalizeModelName } from '@/utils/normalizeModelName';
import { useMemo } from 'react';
import { DynamicLinks } from './DynamicLinks';
import { usePageType } from '@/features/category/api/getPageType';

type Props = {
  seoRepositoryId: string;
};

export const VehicleDynamicLinks = ({ seoRepositoryId }: Props) => {
  const { data: pageTypeData } = usePageType();

  const vehicleData = useMemo(
    () =>
      pageTypeData?.make
        ? {
            make: normalizeMakeName(pageTypeData?.make),
            model: pageTypeData?.model ? normalizeModelName(pageTypeData?.model) : undefined,
            year: pageTypeData?.year ?? undefined,
          }
        : undefined,
    [pageTypeData?.make, pageTypeData?.model, pageTypeData?.year]
  );

  return (
    <DynamicLinks seoRepositoryId={seoRepositoryId} type="vehicle" vehicleData={vehicleData} />
  );
};
