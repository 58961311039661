/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { AxiosInstance } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import {
  GetSeoMetadataUsingGetParamsModel,
  SeoMetadataResponseModel,
} from '@/api/types/seo-data-types';
import { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { useAppState } from '@/stores/AppState';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { QueryClient } from '@tanstack/react-query';
import { usePageType } from '@/features/category/api/getPageType';
import { SeoMetadataInfo } from '../interface';

const URL = `${requestBaseURL}/external/product-discovery/seo-data/v1/seo-metadata`;

export const getSeoMetadataKey = () => ['seoMetadata'] as const;

const allowedRoutes = new Set(['/[...seoUrlPath]', '/products/reviews/[...slug]']);

const getSeoMetadataInfo = async (
  options: GetSeoMetadataUsingGetParamsModel,
  axiosInstance?: AxiosInstance
): Promise<SeoMetadataInfo> => {
  const {
    botEnabledFacetPath,
    country,
    customerType,
    preview,
    seoUrl,
    vehicleMake,
    vehicleModel,
    vehicleYear,
  } = options;

  const params: GetSeoMetadataUsingGetParamsModel = {
    botEnabledFacetPath,
    country,
    customerType,
    preview,
    seoUrl,
    vehicleMake,
    vehicleModel,
    vehicleYear,
  };

  const response = await getAxios(axiosInstance).get<SeoMetadataResponseModel>(URL, {
    params,
  });

  return selector(response.data);
};

const selector = (data: SeoMetadataResponseModel): SeoMetadataInfo => {
  return data;
};

const { useData: useSeoMetadataInfo, prefetch: prefetchSeoMetadataInfo } = createQuery<
  SeoMetadataInfo,
  GetSeoMetadataUsingGetParamsModel
>('seoMetadata', async (options, axiosInstance) =>
  getSeoMetadataInfo({ ...options }, axiosInstance)
);

export const prefetchSeoMetadataInfoResults = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: GetSeoMetadataUsingGetParamsModel
) => {
  return prefetchSeoMetadataInfo(queryClient, options, axiosInstance);
};

export const useSeoMetadata = ({
  enabled = true,
  ...options
}: GetSeoMetadataUsingGetParamsModel & { enabled?: boolean } = {}) => {
  const locale = useLocale();
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const router = useRouter();
  const appState = useAppState();

  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (reqUrl == null) {
    throw new Error(`A 'path' option is required`);
  }

  const parsedUrl = parseUrl(reqUrl).url;
  const brandUrlPart = parsedUrl.split('/b/')[1];

  const {
    data: pageTypeData,
    refetch: refetchPageData,
    isFetching: isPageTypeFetching,
  } = usePageType({ canonicalPath: parsedUrl, enabled });

  if (pageTypeData && !Boolean(pageTypeData.pageType) && !isPageTypeFetching && enabled) {
    void refetchPageData();
  }

  const botEnabledFacetPath = brandUrlPart ? `/b/${brandUrlPart}` : undefined;

  const seoUrl = pageTypeData?.canonicalPath;

  return useSeoMetadataInfo({
    ...options,
    enabled: enabled && (!!seoUrl || !!parsedUrl) && allowedRoutes.has(router.route),
    botEnabledFacetPath: options.botEnabledFacetPath ?? botEnabledFacetPath,
    country: options.country ?? country,
    customerType: options.customerType ?? customerType,
    preview: options.preview ?? showXMPreviewDate(),
    seoUrl: seoUrl ?? parsedUrl,
  });
};
