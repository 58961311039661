/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';

type Props = {
  className: string | undefined | null;
  children?: React.ReactNode;
  id?: string;
  'data-testid'?: string;
};

export const ProductInfoItemContainer = ({ className, ...rest }: Props) => (
  <div className={className || ''} {...rest} />
);
