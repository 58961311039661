/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '@/utils/smartLink';
import azCommonStyles from '@/theme/globals.module.scss';
import NextImage from '@/components/NextImage';
import { orangeReadLessArrow, orangeReadMoreArrow } from '@/constants/images/readMoreLess';
import { useLabels } from '@/hooks/useLabels';
import styles from './ShopByModelList.module.scss';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import useIsOverflow from '@/hooks/useIsOverflow';

const labelMap = {
  hyperlink_label_shelf_See_More: 'hyperlink_label_shelf_See_More',
  hyperlink_label_shelf_See_Less: 'hyperlink_label_shelf_See_Less',
};

export type ShopByModelListProps = {
  data: {
    title: string;
    desktop: boolean;
    tablet: boolean;
    mobile: boolean;
    canonicalPath: string | undefined;
    taxonomyPath: string | undefined;
    pageType: string | undefined;
    shopByMMYList: Array<{
      name: string;
      seoUrl: string;
      taxonomyPath?: string;
      makeModelYearPath?: string;
    }>;
  };
};

type ButtonProps = {
  onClick: () => void;
  label: string;
};
const ReadMoreButton = ({ onClick, label }: ButtonProps) => (
  <button
    className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
    onClick={onClick}
    data-testid="description-read-more"
  >
    {label}
    <div className={styles.seeDetailArrowReadMore}>
      <NextImage
        src={orangeReadMoreArrow.src}
        alt={orangeReadMoreArrow.alt}
        className={styles.readMoreArrow}
        width={8}
        height={12}
      />
    </div>
  </button>
);
const ReadLessButton = ({ onClick, label }: ButtonProps) => (
  <button
    className={cx(azCommonStyles['az-button-text'], styles.readMoreButton)}
    onClick={onClick}
    data-testid="description-read-less"
  >
    <div>
      <NextImage
        src={orangeReadLessArrow.src}
        alt={orangeReadLessArrow.alt}
        className={styles.arrowReadless}
        width={8}
        height={12}
      />
    </div>
    {label}
  </button>
);

const useIsInitialOverflow = (ref: React.RefObject<HTMLDivElement>) => {
  const [isInitialOverflow, setIsInitialOverflow] = useState(false);
  const isOverflow = useIsOverflow(ref, true);

  useEffect(() => {
    if (isOverflow) {
      setIsInitialOverflow(true);
    }
  }, [isOverflow]);

  return isInitialOverflow;
};

export const ShopByModelList = ({ data }: ShopByModelListProps) => {
  const { title, shopByMMYList } = data;
  const shopByMMYListLength = shopByMMYList.length;
  const [readMore, setReadMore] = useState(false);
  const labels = useLabels(labelMap);
  const ref = useRef<HTMLDivElement>(null);

  const toggleReadMore = useCallback(() => setReadMore((prev) => !prev), []);
  const isOverflow = useIsInitialOverflow(ref);

  if (shopByMMYListLength === 0) {
    return null;
  }
  return (
    <>
      <DeviceWrapper
        desktop={Boolean(data.desktop)}
        tablet={Boolean(data.tablet)}
        mobile={Boolean(data.mobile)}
      >
        <div
          className={cx(styles.modelPartList, {
            [azCommonStyles['az-margin-bottom-l']]: !isOverflow,
          })}
        >
          <h3 className={styles.title}>{title}</h3>
          <div
            className={cx(azCommonStyles['az-body-1-regular'], { [styles.mobileDesc]: !readMore })}
            ref={ref}
          >
            <div
              className={cx(styles.columns, { [styles.singleColumn]: shopByMMYListLength <= 4 })}
            >
              <ul>
                {shopByMMYList.map(({ name, seoUrl }) => {
                  return (
                    <li key={seoUrl}>
                      <Link className={styles.modelLink} to={seoUrl}>
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </DeviceWrapper>
      <span className={styles.readMoreSmallProdDesc}>
        {isOverflow &&
          (readMore ? (
            <ReadLessButton
              onClick={toggleReadMore}
              label={labels.hyperlink_label_shelf_See_Less}
            />
          ) : (
            <ReadMoreButton
              onClick={toggleReadMore}
              label={labels.hyperlink_label_shelf_See_More}
            />
          ))}
      </span>
    </>
  );
};
