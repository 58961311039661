/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useDeviceType } from '@/utils/useDeviceType';
import { useProductListView } from '../context/useProductListView';

export const useIsAccordionOpen = () => {
  const { accordionState: accordionFilterOpenStates } = useProductListView();
  const deviceType = useDeviceType();
  const isBot = deviceType === 'bot';

  const isMobileorTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isAccordionOpen = (
    filterItem: {
      dimensionName: string;
    },
    customPriceFilter?: boolean,
    filterIndex?: number,
    staticFilterLength?: number
  ): boolean => {
    const { dimensionName } = filterItem;
    const dynamicFilterCollapsedFilters = customPriceFilter ? 2 : 3;
    let xmCollapsed = false;
    if (customPriceFilter && typeof filterIndex === 'number') {
      filterIndex = filterIndex - 1;
    }
    if (!isMobileorTablet) {
      if (typeof filterIndex === 'number' && !staticFilterLength && filterIndex > 4) {
        xmCollapsed = true;
      } else if (
        typeof filterIndex === 'number' &&
        staticFilterLength &&
        filterIndex + staticFilterLength > 4
      ) {
        xmCollapsed = true;
      }
      //when static filters are not present but custom price and dynamic filters are present
      else if (
        typeof filterIndex === 'number' &&
        staticFilterLength == 0 &&
        filterIndex > dynamicFilterCollapsedFilters
      ) {
        xmCollapsed = true;
      }
    } else {
      xmCollapsed = true;
    }
    const dimensionNamePresent = isKeyOfObject(dimensionName, accordionFilterOpenStates);

    return dimensionNamePresent
      ? Boolean(accordionFilterOpenStates[dimensionName])
      : isBot
      ? true
      : !xmCollapsed;
  };
  return isAccordionOpen;
};

function isKeyOfObject<T extends object>(key: string | number | symbol, obj: T): key is keyof T {
  return key in obj;
}
