/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { ParsedUrlQuery } from 'querystring';
import { stringifyUrl, parseUrl } from '../utils/urlHelpers';
import { useRouter } from 'next/router';
import type { FilterSelectionType, HandleFilterType } from '../types/filterTypes';
import { useFacetedUrlEnabled } from './useFacetedUrlEnabled';
import { useGetFilterAnalytics } from './useGetFilterAnalytics';
import { sessionStorage } from '@/utils/sessionStorage';

export const useFilterAndRedirect = (): ((
  filter: HandleFilterType,
  onFilterSelect?: (a: HandleFilterType) => void
) => void) => {
  const router = useRouter();
  const facetedUrlEnabled = useFacetedUrlEnabled();
  const getFilterAnalytics = useGetFilterAnalytics();

  const handleFilterAndRedirect = (
    filter: HandleFilterType,
    onFilterSelect?: (a: HandleFilterType) => void
  ) => {
    const { query } = router;
    const { brandName, ...currentQuery } = query;
    const selectedFilterType: FilterSelectionType = {
      type: filter.selectionType,
      facet: filter.displayName,
      value: filter.refinement.label,
    };

    const filterAnalytics = getFilterAnalytics(selectedFilterType, filter.checked);

    sessionStorage.setItem('filterSelectionAnalytics', filterAnalytics);
    if (!filterAnalytics.length) {
      sessionStorage.removeItem('filterSelectionAnalytics');
    }

    if (onFilterSelect) {
      onFilterSelect(filter);
    }

    if (brandName) {
      router.push(getBrandShelfUrl(filter, query));
    } else {
      router.push(
        facetedUrlEnabled
          ? getFacetedUrl(filter, currentQuery)
          : getNonFacetedUrl(filter, currentQuery)
      );
    }
  };

  return handleFilterAndRedirect;
};

const getBrandShelfUrl = (filter: HandleFilterType, query: ParsedUrlQuery) => {
  const { brandName, filters, ...currentQuery } = query;
  const { checked, refinement = {} } = filter;
  // @ts-expect-error fix type
  const { properties = {} } = refinement;

  const { filters: selectedFilter } = properties;
  const { query: seoUrlQuery } = parseUrl(properties.seoUrl ?? '');
  // @ts-expect-error fix type
  let filtersArr = filters?.split('-') ?? [];

  if (selectedFilter || filtersArr.length) {
    if (checked) {
      filtersArr.push(selectedFilter);
    } else {
      filtersArr = filtersArr.filter((currentFilter: any) => currentFilter !== selectedFilter);
    }
  }

  return stringifyUrl(
    {
      url: `/brands/${brandName}`,
      query: {
        ...currentQuery,
        facet: null,
        ...seoUrlQuery,
        filters: filtersArr.length ? filtersArr.join('-') : null,
        pageNumber: null,
        currentPage: null,
      },
    },
    {
      skipNull: true,
    }
  );
};

const getFacetedUrl = (
  filter: HandleFilterType,
  currentQuery: { [p: string]: string | string[] | undefined }
): string => {
  const { checked, refinement, dimensionName } = filter;
  const { unCheckedOptimizedUrl, optimizedSeoUrl, seoUrl } = refinement.properties;
  const { query } = parseUrl(seoUrl);

  if (checked === false && currentQuery.facet && !query.facet) {
    delete currentQuery.facet;
  }
  if (!!checked && dimensionName.includes('vehicle_fitment')) {
    delete currentQuery.ignoreVehicleSpecificProductsCheck;
  }
  return stringifyUrl(
    {
      url: parseUrl(checked ? optimizedSeoUrl : unCheckedOptimizedUrl).url,
      query: {
        ...currentQuery,
        facet: null,
        ...query,
        cmsPath: null,
        slug: null,
        seourl: null,
        seoUrlPath: null,
        filters: null,
        pageNumber: null,
        currentPage: null,
        ...(!!dimensionName &&
          !checked &&
          dimensionName.includes('vehicle_fitment') && {
            ignoreVehicleSpecificProductsCheck: true,
          }),
      },
    },
    {
      skipNull: true,
    }
  );
};

const getNonFacetedUrl = (
  filter: HandleFilterType,
  currentQuery: { [p: string]: string | string[] | undefined }
): string => {
  let filteredUrl;
  const { checked, refinement = {}, dimensionName } = filter;
  // @ts-expect-error fix type
  const { properties = {} } = refinement;
  const { filters: filterId, seoUrl } = properties;
  const { query, url } = parseUrl(seoUrl ?? '');

  if (checked === false) {
    // remove filterId with any attached '-'s
    const filters = String(query.filters);
    query.filters = filters
      .replace(`-${filterId}`, '')
      .replace(`${filterId}-`, '')
      .replace(filterId, '');
    if (!query.facet) {
      delete currentQuery.facet;
    }
    if (query.filters === '' || !filterId) {
      delete query.filters;
      delete currentQuery.filters;
    }

    const newQuery = {
      ...currentQuery,
      facet: null,
      ...query,
      cmsPath: null,
      slug: null,
      pageNumber: null,
      currentPage: null,
      seoUrlPath: null,
      ...(!!dimensionName &&
        dimensionName.includes('vehicle_fitment') && { ignoreVehicleSpecificProductsCheck: true }),
    };

    filteredUrl = stringifyUrl(
      {
        url,
        query: newQuery,
      },
      {
        skipNull: true,
      }
    );
  } else {
    if (dimensionName.includes('vehicle_fitment')) {
      delete currentQuery.ignoreVehicleSpecificProductsCheck;
    }
    const newQuery = {
      ...currentQuery,
      facet: null,
      ...query,
      cmsPath: null,
      slug: null,
      pageNumber: null,
      currentPage: null,
    };

    filteredUrl = stringifyUrl(
      {
        url,
        query: newQuery,
      },
      {
        skipNull: true,
      }
    );
  }

  return filteredUrl;
};
