/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import PartsCarousel from '@/components/AZCustomComponent/PartsCarousel';
import { useCitrusShelfCarouselData } from '@/features/citrus/api/getCitrusShelfCarouselData';
import { useLabel } from '@/hooks/useLabels';
import { mapCitrusCarouselToCertonaCarousel } from '../../utils/mapCitrusCarouselToCertonaCarousel';
import { useFeatureFlag } from '@/features/globalConfig';

export function CMSCitrusCarouselShelf() {
  const citrusShelfCarouselEnabled = useFeatureFlag('IS_CITRUS_SHELF_CAROUSEL_ENABLED') === 'true';
  const { data: citrusData } = useCitrusShelfCarouselData({
    enabled: citrusShelfCarouselEnabled,
  });
  const sponsoredProductsTitle = useLabel('label_sponsoredproducts');

  if (!citrusData?.ads.length || !citrusShelfCarouselEnabled) {
    return null;
  }

  return (
    <PartsCarousel
      partsList={mapCitrusCarouselToCertonaCarousel(citrusData.ads)}
      title={sponsoredProductsTitle}
      isCitrusCard
      pageType={''}
    />
  );
}
