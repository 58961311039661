/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { ContentStackCampaign } from '../../interface';
import { YMMEModal } from '@/features/ymme/components/YMMEModal';
import { saveUIState } from '@/stores/actions';
import { useRouter } from 'next/router';
import { useAppState } from '@/stores/AppState';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { useMemo } from 'react';

type Props = {
  content: ContentStackCampaign[];
};

export function CMSCampaignModal({ content }: Props) {
  const router = useRouter();
  const { query = {} } = router;
  const { cmpid: queryStringCampaignId } = query;

  const { state, dispatch } = useAppState();
  const { campaignModalEnabled } = state;

  const selectedCampaign = useMemo(() => {
    return content.find((campaign) => campaign.campaign_id === queryStringCampaignId);
  }, [content, queryStringCampaignId]);
  const shouldDisplayCampaign = selectedCampaign != null && campaignModalEnabled;

  const closeYmmeWidget = () => {
    dispatch(saveUIState({ campaignModalEnabled: false }));
  };

  return (
    <DeviceWrapper mobile desktop={false} tablet={false}>
      <YMMEModal
        showYmme={shouldDisplayCampaign}
        closeYmmeWidget={closeYmmeWidget}
        onUpdateVehicleSuccess={closeYmmeWidget}
        pageType={'campaign'}
        bannerImage={selectedCampaign?.image?.image_url}
        bannerAltText={selectedCampaign?.image?.image_alt_text}
        ymmeCaptionText={selectedCampaign?.caption_text}
        ctaButtonText={selectedCampaign?.cta_button_text}
        campaignFlow
      />
    </DeviceWrapper>
  );
}
