/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View } from '@az/starc-ui';
import { useRouter } from 'next/router';
import { useFeatureFlag } from '@/features/globalConfig';
import { ShareLinkButton } from '@/features/sharableLink';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import styles from './styles.module.scss';
import cx from 'classnames';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo';

const labelMap = {
  ofLabel: 'label_checkout_shipping_Of',
  resultLabel: 'label_Search_Results_Results',
  forLabel: 'label_Search_for',
};

type NumberOfResultsProps = {
  searchText?: string;
  firstPageRec: number;
  recsPerPage: number;
  totalNumRecs: number;
  removeMargin?: boolean;
};

const NumberOfResults = ({
  firstPageRec,
  recsPerPage,
  totalNumRecs,
  searchText,
  removeMargin,
}: NumberOfResultsProps) => {
  const router = useRouter();
  const isSharableLinksEnabled = useFeatureFlag('SHARABLE_LINKS_ENABLED') === 'true';
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const { pageType } = usePageType();
  const isShelfOrSearchPage =
    pageType === pageTypes.shelf ||
    pageType === pageTypes.ProductShelf ||
    pageType === pageTypes.search ||
    pageType === pageTypes.MerchShelf;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS && isShelfOrSearchPage;

  const pageNumber = router.query.pageNumber ? Number(router.query.pageNumber) : 1;
  const maxRec = recsPerPage * pageNumber;
  const lastPageRec = maxRec >= totalNumRecs ? totalNumRecs : maxRec;
  return (
    <>
      {!!recsPerPage && (totalNumRecs || 0) > 0 && (
        <View
          className={cx(styles.numberOfResults, {
            [styles.withMargin]: !removeMargin && !useNewShelfDesignMobile,
            [styles.smallText]: useNewShelfDesignMobile,
          })}
          attributes={{ id: 'search-count-info', 'data-testid': 'search-count-info' }}
        >
          <span>
            <span
              data-testid={isMobile ? 'mobile-results-count' : 'search-count-num'}
              id={isMobile ? 'mobile-results-count' : 'search-count-num'}
              className={styles.countAndTotal}
            >
              <b>
                {firstPageRec}-{lastPageRec}
              </b>{' '}
              {labels.ofLabel.toLowerCase()} <b>{totalNumRecs}</b> {labels.resultLabel}{' '}
            </span>
            {pageType === pageTypes.search && (
              <>
                {labels.forLabel} <b>&quot;{searchText ?? router.query.searchText}&quot;</b>
              </>
            )}
          </span>
          {isSharableLinksEnabled && (
            <View className={styles.shareLinkButtonWrapper}>
              <ShareLinkButton
                pageName="shelf"
                classes={{
                  container: useNewShelfDesignMobile || !isMobile ? styles.sharableLink : undefined,
                  button: useNewShelfDesignMobile || !isMobile ? styles.button : undefined,
                }}
              />
            </View>
          )}
        </View>
      )}
    </>
  );
};

export default NumberOfResults;
