/**
 * Copyright 2021-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRouter } from 'next/router';
import { useCustomPriceFilterEnabled } from './useCustomPriceFilterEnabled';
import { priceFilterDisplayNames } from '@/components/Filter/priceFilterDisplayNames';
import { useProductList } from '@/features/shelf/context/useProductList';
import { usePageType } from './usePageType';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';

const priceRangeParamNames = {
  minPrice: 'minPrice',
  maxPrice: 'maxPrice',
};

export const useShowCustomPriceRangeFilter = (): boolean => {
  const router = useRouter();
  const { pageType } = usePageType();
  const isSearchProductData = Boolean(
    pageType?.toLowerCase()?.includes('search') && router.query.partTypesView !== 'true'
  );
  const { data: searchProductData } = useSearchProductData({ enabled: isSearchProductData });
  const isShelfPageType = pageType?.toLowerCase()?.includes('shelf');
  const customPriceRangeEnabled = useCustomPriceFilterEnabled();
  const { data: shelfData } = useProductList();
  const shelfLeftNavigation = shelfData?.shelfLeftNav;
  const { query: currentQuery } = router;
  const isCustomPriceRangeSet =
    priceRangeParamNames.minPrice in currentQuery || priceRangeParamNames.maxPrice in currentQuery;

  const showCustomPriceRangeFilter =
    isCustomPriceRangeSet &&
    customPriceRangeEnabled &&
    ((isShelfPageType &&
      !shelfLeftNavigation?.[0]?.staticNavigation?.some(
        (filter) =>
          filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceUS ||
          filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceMX ||
          filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceSearchMX ||
          filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceSearchUS
      )) ||
      (isSearchProductData &&
        searchProductData?.staticNavigation?.some(
          (filter) =>
            filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceSearchUS ||
            filter.dimensionName.toLocaleLowerCase() === priceFilterDisplayNames.priceSearchMX
        )));
  return showCustomPriceRangeFilter ?? false;
};
