/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './CMSNullResult.module.scss';
import { ContentStackNullResult } from '../../interface';
import {
  useProductShelfResults,
  useProduleShelfFacets,
} from '@/features/category/api/getProductShelfResults';
import { Divider, Link, Text, View, classNames } from '@az/starc-ui';
import { useDeleteDefaultVehicleMutation } from '@/features/ymme/api/deleteDefaultVehicle';
import { CMSImage } from '../CMSImage/CMSImage';

type CMSNullCartidgeProps = {
  content: ContentStackNullResult;
  setRemoveMargin: (value: boolean) => void;
};

const CMSNullCartidge = ({ content }: Pick<CMSNullCartidgeProps, 'content'>) => {
  const { background_image } = content;
  const { data: shelfData, isLoading, isSuccess } = useProduleShelfFacets();
  const { mutate: removeDefaultVehicle } = useDeleteDefaultVehicleMutation();

  if (isLoading || !isSuccess) {
    return null;
  }
  const facet = shelfData.productShelfResults?.facets?.find(
    (facet) => facet.facetInternalName === content.dimension_name
  );
  if (!facet) {
    return null;
  }
  return (
    <View className={styles.container}>
      <View padding={[0, 4]}>
        <Divider />
      </View>
      <View direction={{ s: 'column', m: 'row' }} className={styles.innerContainer}>
        <View.Item grow>
          <Text
            className={classNames(styles.heading, styles.largeText)}
            as="h2"
            fontFamily="headline"
            weight="medium"
            textCase="uppercase"
          >
            {content.heading}
          </Text>
          <View gap={2} className={styles.lowerContainer}>
            <Text className={styles.mediumText} as="h3" weight="heavy">
              {content.sub_heading}
            </Text>
            <Text
              className={styles.smallText}
              attributes={{ dangerouslySetInnerHTML: { __html: content.description ?? '' } }}
            >
              {null}
            </Text>
          </View>
        </View.Item>
        <View.Item grow>
          <View
            className={styles.facetsContainer}
            direction="row"
            justify={{ s: 'center', m: 'start', l: 'start' }}
          >
            {facet.facetValues?.map(({ seoUrl, facetValueDisplayName, facetValueName }) => (
              <View.Item className={styles.facetContainer} key={facetValueName}>
                <Link onClick={() => removeDefaultVehicle()} href={seoUrl}>
                  <Text className={styles.smallText}>{facetValueDisplayName}</Text>
                </Link>
              </View.Item>
            ))}
          </View>
        </View.Item>
        {background_image?.image_url && (
          <CMSImage image={background_image} className={styles.backgroundImage} />
        )}
      </View>
    </View>
  );
};

export const CMSNullResult = ({ content, setRemoveMargin }: CMSNullCartidgeProps) => {
  const { data: shelfData, isSuccess } = useProductShelfResults();
  if (!isSuccess) {
    return null;
  }
  if (shelfData.productShelfResults?.totalNumberOfRecords === 0) {
    setRemoveMargin(true);
    return <CMSNullCartidge content={content} />;
  }
  return null;
};
