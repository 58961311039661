/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import type { FilterDataType, HandleFilterType } from '../types/filterTypes';
import { getOneClickFilters } from '@/components/Filter/utils/getOneClickFilters';
import { QuickFilterModel } from '@/api/types/browse-search-types';

const getOneClickFiltersArray = (
  quickFilters: QuickFilterModel[] | undefined,
  staticFilterData: Array<FilterDataType> | null | undefined,
  dynamicFilterData: Array<FilterDataType> | null | undefined
) => {
  let OneClickFiltersArray: Array<HandleFilterType> = [];
  staticFilterData?.forEach((filter: FilterDataType) =>
    getOneClickFilters(OneClickFiltersArray, filter, quickFilters)
  );
  dynamicFilterData?.forEach((filter: FilterDataType) =>
    getOneClickFilters(OneClickFiltersArray, filter, quickFilters)
  );
  return OneClickFiltersArray;
};

export const useGetOneClickFilters = (
  quickFilters: QuickFilterModel[] | undefined,
  staticFilterData: Array<FilterDataType> | null = null,
  dynamicFilterData: Array<FilterDataType> | null = null
): HandleFilterType[] => {
  const shelfChipSet = useMemo(
    () => getOneClickFiltersArray(quickFilters, staticFilterData, dynamicFilterData),
    [dynamicFilterData, quickFilters, staticFilterData]
  );

  return shelfChipSet;
};
