/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const filterIcon = {
  src: '/images/filter-icon.svg',
  alt: 'filter',
  width: 20,
  height: 18,
};
