/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const arrowDown = {
  src: '/images/arrow-down-dark.svg',
  alt: 'arrow down',
  width: 10,
  height: 15,
};
