/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useRef } from 'react';
import useLatestCallback from 'use-latest-callback';
import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { addToCart } from '@/actions/shelf/shelf';
import { productInfoConstants } from '@/constants/productInfoConstants';
import { useRouter } from 'next/router';
import { ProductNotes } from '../ProductNotes';
import { ProductInfo } from '../ProductInfo';
import { ProductImage } from '../ProductImage';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { cartConstants } from '@/constants/cart';
import { routePaths } from '@/constants/routePaths';
import { checkAvailabilityByGroupTypeId } from '@/features/orders/utils/checkAvailabilityByGroupTypeId';
import { getFulfillmentOption } from '@/utils/getFulfillmentOption';
import { getStaticFormattedPrice } from '@/utils/priceHelpers';
import { fulfillmentGroupTypeIds } from '@/constants/fulfillmentConstants';
import { ProductPricingAndAvailability } from '../ProductPricingAndAvailability';
import ProductAvailabilitySkeleton from '@/components/Skeletons/ProductAvailabilitySkeleton';
import usePrevious from '@/utils/usePrevious';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import { trackQuantityFulfillmentChange } from '@/utils/analytics/track/trackQuantityFulfillmentChange';
import { trackQuantityChange } from '@/utils/analytics/track/trackQuantityChange';
import { trackFulfillmentChange } from '@/utils/analytics/track/trackFulfillmentChange';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useFeatureFlag } from '@/features/globalConfig';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useProductList } from '../../../../../context/useProductList';
import type { ProductPriceInfoAddToCartAnalyticsData } from '../../../../../interface';
import type { ShelfPart } from '@/types/legacy';
import type { DealInfo } from '@/types/availability';
import { useQueryClient } from '@tanstack/react-query';
import {
  getProductSkuDetailsKey,
  getProductSkuDetailsFromCache,
} from '@/features/product/api/getProductSkuDetails';
import { useAddToCart } from '@/features/orders/api/postAddToCart';
import { usePatchOrderItem } from '@/features/orders/api/patchOrderItem';
import { sessionStorage } from '@/utils/sessionStorage';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { AttributeChip } from '@/components/AttributeChip';

type Props = {
  productImageAltName: string;
  part: ShelfPart;
  isList: boolean;
  application: string;
  dealInfo: DealInfo;
  cents: string;
  dollars: string;
  hasVehicle: boolean;
  configurableSku: boolean;
  index: number;
  shelfReadyStatus: string;
  storeNumber: string | undefined;
  isSearchPageType: boolean;
  toggleShowYmme: () => void;
  onDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentGroupId: number | null
  ) => void;
  resizeShelfGrids?: () => void;
  setWarrantyModal: ({
    isShown,
    showModalFrom,
    warrantyType,
  }: {
    isShown: boolean;
    showModalFrom?: string;
    warrantyType?: string;
  }) => void;
};

const ProductPriceInfoContainer = ({
  productImageAltName,
  part,
  isList,
  application,
  dealInfo,
  cents,
  dollars,
  hasVehicle,
  configurableSku,
  index,
  shelfReadyStatus,
  storeNumber,
  toggleShowYmme,
  onDealClick,
  resizeShelfGrids,
  setWarrantyModal,
  isSearchPageType,
}: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const router = useRouter();
  const ref = useRef<string>();
  const locale = useLocale();
  const skuPricingAndAvailability = part.skuPricingAndAvailability;
  const corePrice = skuPricingAndAvailability?.corePrice ?? 0;
  const storeRetailPrice = skuPricingAndAvailability?.skuAvailabilityInfo.storeRetailPrice ?? 0;
  const categoryId = skuPricingAndAvailability?.categoryId ?? '';
  const productSpecs = part.productSpecs;
  const isMxWeb = locale === countryCodes.mx;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const fitsVehicle = part.vehicleFit ?? false;
  const fulfillmentOptions = part.skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions;
  const shipToHomeAvailable = !isMxWeb
    ? checkAvailabilityByGroupTypeId(fulfillmentOptions, fulfillmentGroupTypeIds.shipToHome)
    : part.skuPricingAndAvailability?.shipToHomeAvailable;
  const storePickupAvailable = !isMxWeb
    ? checkAvailabilityByGroupTypeId(fulfillmentOptions, fulfillmentGroupTypeIds.storePickup)
    : part.skuPricingAndAvailability?.storePickupAvailable;
  const finalNotes = [part.quickNote, part.techNote].filter(Boolean).join(', ');
  const {
    data: shelfData,
    isSuccess: shelfQuerySuccess,
    productSkuIsFetching,
    secondaryProductSkuIsFetching,
  } = useProductList();
  const enable24ProductView = useIs24ProductViewEnabled();
  const shopByUrlYear = shelfData?.shopByUrlYear;
  const shopByUrlMake = shelfData?.shopByUrlMake;
  const shopByUrlModel = shelfData?.shopByUrlModel;
  const productIdFromShelfData = shelfData?.productId;

  const [showDealDrawerButton, setShowBundleDealsButton] = useState(false);
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const updateCartEnabled = useFeatureFlag('UPDATE_CART_ENABLED') === 'true';
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeSelected = useIsStoreSelected();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const isShelfYMM = shopByUrlYear && shopByUrlMake && shopByUrlModel;
  const quantityInCart = shelfData?.shelfParts[index].quantityInCart || 0;
  const shelfCatLabel = shelfData?.shelfCatLabel;
  const [count, setCount] = useState(
    updateCartEnabled || isBopusMexicoStoreEnabled ? quantityInCart : 0
  );

  const [groupId, setGroupId] = useState(
    part.fulfillmentOptions?.find((option) => option.selected)?.groupId ??
      FULFILLMENT_METHODS.ONLINEORDER
  );
  const [prevGroupId, setPrevGroupId] = useState(0);
  const [preCount, setPreCount] = useState<number | undefined>(0);
  const [prevGroupIdInCart, setPrevGroupIdInCart] = useState(0);
  const [trackQOHError, setTrackQOHError] = useState(false);
  const staticPrice = getStaticFormattedPrice(
    skuPricingAndAvailability?.configurableSku,
    skuPricingAndAvailability
  );
  const price =
    count > 0 ? (Number(staticPrice) * count).toFixed(2).toString() : staticPrice.toString();

  const [productEdited, setProductEdited] = useState(false);
  const defaultFulfillmentOption = useFeatureFlag('DEFAULT_FULFILLMENT_OPTION') || '';
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const productAttributeChipsEnabled = useFeatureFlag('ENABLE_PRODUCT_ATTRIBUTE_CHIPS') === 'true';
  const addToCartMutation = useAddToCart();
  const productRepositoryId = shelfData?.shelfParts[index].productRepositoryId;
  const originalPartTypeId = shelfData?.shelfParts[index].originalPartTypeId;

  const fulfillmentOption = getFulfillmentOption(
    shipToHomeAvailable,
    storePickupAvailable,
    defaultFulfillmentOption
  );
  const [shippingType, setShippingType] = useState(fulfillmentOption);
  useEffect(() => {
    if (!shippingType && fulfillmentOption) {
      setShippingType(fulfillmentOption);
    }
    // We need to update the shipping type if it wasn't set and the fulfillment option changes
    // This could happen if page was first rendered without a store
    // The fulfillment option will change to a valid one from empty string after store is set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentOption]);

  const handleChangeShippingType = useLatestCallback((shippingType: string) => {
    setShippingType(shippingType);
    handleProductEdited();
  });
  const handleChangeShippingTypeV2 = useLatestCallback(
    (
      shippingType: typeof cartConstants.STOREORDER,
      nweGroupId: typeof FULFILLMENT_METHODS.STOREORDER,
      maxAvailableQty: number
    ) => {
      setShippingType(shippingType);
      setPrevGroupId(groupId);
      setPreCount(prevCount);
      setGroupId(nweGroupId);
      handleProductEdited();

      if (count > maxAvailableQty) {
        handleQuantityChange(maxAvailableQty);
      }
    }
  );

  const handleProductEdited = useLatestCallback(() => {
    setProductEdited(quantityInCart > 0);
  });

  const handleQuantityChange = useLatestCallback((quantity: number) => {
    const newQuantity = quantity ? Math.abs(quantity) : 1;
    setCount(newQuantity);
    handleProductEdited();
    resizeShelfGrids?.();
  });

  const handleAddToCart = useLatestCallback(async (): Promise<void> => {
    const fulfillmentChange =
      groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH;
    const addToCartRequest = {
      sku_id: String(part.skuNumber),
      product_id: productIdFromShelfData ?? skuPricingAndAvailability?.productId,
      quantity: '1',
      category_id: categoryId !== null ? categoryId : '',
      order_type: shippingType,
      catalog_vehicle_id: part.catalogVehicleId !== null ? part.catalogVehicleId : '',
      store_number: String(storeNumber),
      storePickupAvailable,
    };
    const analyticsData: ProductPriceInfoAddToCartAnalyticsData = {
      fulfillmentChange: prevGroupId !== 0 ? fulfillmentChange : '',
      customLinkName:
        prevGroupId !== 0
          ? 'shelfPageFulfillmentChange'
          : part.isSponsoredCard
          ? 'Shelf:Sponsored Product:Cart Addition'
          : 'Shelf:AutoZone:Cart Addition',
      originalPartTypeId,
      productRepositoryId,
      price,
      corePrice,
      brandName: skuPricingAndAvailability?.brandName ?? '',
      repositoryId: part.repositoryId,
      partNumber: part.partNumber,
      productRecommendationTypeList: part.isSponsoredCard ? 'Sponsored Product' : 'AutoZone',
      shelfCatLabel,
      prodIndex: index,
    };

    if (addToCartRequest.product_id && !part.itemPresentInCart) {
      //TODO: once add to cart is removed from redux add this side effect to the add to cart mutation
      const onAddToCartSuccess = () => {
        const skuIds: string[] = [];
        if (enable24ProductView) {
          const index =
            shelfData?.shelfParts.findIndex(
              (item) => item.skuNumber.toString() === skuPricingAndAvailability?.skuId
            ) ?? 0;
          // since there are two productSkuDetails queries we must pick the first or second half depending on its index
          shelfData?.shelfParts
            .slice(index >= 12 ? 12 : 0, index >= 12 ? undefined : 12)
            .forEach((part) => {
              skuIds.push(part.skuNumber.toString());
            });
        } else {
          shelfData?.shelfParts.forEach((part) => {
            skuIds.push(part.skuNumber.toString());
          });
        }
        const skuDetailsQueryOptions = {
          skuIds: skuIds,
          vehicleId: catalogVehicleId,
          storeNumber,
          isNewDealsApiEnabled,
        };
        if (enable24ProductView) {
          const index = skuIds.findIndex((sku) => sku === skuPricingAndAvailability?.skuId);
          if (index >= 12) {
            skuDetailsQueryOptions.skuIds = skuIds.slice(12);
          } else {
            skuDetailsQueryOptions.skuIds = skuIds.slice(0, 12);
          }
        }
        const skuDetails = getProductSkuDetailsFromCache(queryClient, skuDetailsQueryOptions);
        const updatedSkuDetails = skuDetails?.map((skuDetailItem) => {
          if (
            skuDetailItem.skuPricingAndAvailability.productId ===
              skuPricingAndAvailability?.productId &&
            skuDetailItem.skuPricingAndAvailability.skuId === skuPricingAndAvailability?.skuId
          ) {
            return {
              ...skuDetailItem,
              quantityInCart: '1',
              itemPresentInCart: true,
            };
          }
          return skuDetailItem;
        });
        const skuDetailsKey = getProductSkuDetailsKey(skuDetailsQueryOptions);
        queryClient.setQueryData(skuDetailsKey, updatedSkuDetails);
      };
      await dispatch(
        addToCart(
          addToCartMutation,
          addToCartRequest,
          analyticsData,
          onAddToCartSuccess,
          queryClient,
          // searchedTerm not needed for old experience, only PCF
          undefined
        )
      );

      setTrackQOHError(true);
    } else {
      void router.push(routePaths.cart);
    }
  });

  const { data: headerData } = useHeaderData();
  const catalogVehicleId = headerData?.vehicleMap.catalogVehicleId ?? '';
  const { mutateAsync: patchOrderItem } = usePatchOrderItem();

  const updateCart = useLatestCallback(async (): Promise<void> => {
    await patchOrderItem({
      vehicleId: catalogVehicleId,
      quantity: count.toString(),
      skuId: skuPricingAndAvailability?.skuId ?? '',
      productId: productIdFromShelfData ?? '',
      storeNumber: storeNumber ?? '',
      fulfillmentTypeId: groupId,
    });
    setProductEdited(false);
    const fullFillmaxQuantity =
      part.fulfillmentOptions?.find((option) => option.groupId == groupId)?.maxAvailableQty ?? 0;

    if (
      (prevGroupIdInCart === 0 && groupId === FULFILLMENT_METHODS.STOREORDER) ||
      (prevGroupIdInCart === 0 && groupId === FULFILLMENT_METHODS.ONLINEORDER) ||
      (prevGroupIdInCart === groupId && preCount !== count && fullFillmaxQuantity !== 1)
    ) {
      if (!sessionStorage.getItem('sister_store_update_type')) {
        trackQuantityChange(
          'shelfPageQuantityChange',
          [String(fullFillmaxQuantity)],
          [String(part.skuNumber.toString())],
          [String(count)]
        );
      }
      sessionStorage.removeItem('sister_store_update_type');

      if (prevGroupIdInCart !== groupId) {
        setPrevGroupIdInCart(groupId);
      }
    } else if (prevGroupIdInCart !== groupId && preCount !== count && fullFillmaxQuantity !== 1) {
      trackQuantityFulfillmentChange(
        groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH,
        'shelfPageFulfillmentAndQuantityChange',
        [String(fullFillmaxQuantity)],
        [String(part.skuNumber.toString())],
        [String(count)],
        'fulfillmentAndQuantityChange'
      );
      setPrevGroupIdInCart(groupId);
    } else if (prevGroupIdInCart !== groupId) {
      trackFulfillmentChange(
        groupId === FULFILLMENT_METHODS.STOREORDER ? cartConstants.BOPUS : cartConstants.STH,
        'shelfPageFulfillmentChange'
      );
      setPrevGroupIdInCart(groupId);
    }
  });

  useEffect(() => {
    setCount(updateCartEnabled || isBopusMexicoStoreEnabled ? quantityInCart : 0);

    if (!shippingType || (shelfQuerySuccess && ref.current !== shelfReadyStatus)) {
      if (quantityInCart > 0) {
        const selectedGroupId =
          part.fulfillmentOptions?.find((option) => option.selected)?.groupId ??
          FULFILLMENT_METHODS.ONLINEORDER;
        setGroupId(selectedGroupId);
        setPrevGroupIdInCart(selectedGroupId);
        setShippingType(
          selectedGroupId === FULFILLMENT_METHODS.ONLINEORDER
            ? cartConstants.ONLINEORDER
            : cartConstants.STOREORDER
        );
      } else {
        const isNotAvailable = fulfillmentOptions?.every((item: { available: boolean }) => {
          return !item.available;
        });
        if (isNotAvailable && (part.availabilityInfoVO?.numberOfSisterStoresAvailable ?? 0) > 0) {
          setShippingType(cartConstants.STOREORDER);
        }
      }
    }

    ref.current = shelfReadyStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityInCart, shelfReadyStatus, updateCartEnabled]);

  const showPriceCaptionValue = configurableSku;

  const showWarrantyModalWrapper = () => {
    setWarrantyModal({
      isShown: true,
      showModalFrom: productInfoConstants.ProductSpec,
      warrantyType: part.warrantyType,
    });
  };

  const handleDealClick = useLatestCallback((ref: React.RefObject<HTMLButtonElement>) => {
    onDealClick?.(part, price, count, ref, groupId);
  });

  const renderProductAttributeChips = (
    <div
      className={cx(styles.attributeChipWrapper, {
        [styles.attributeChipListView]: isList && !isMobile,
        [styles.attributeChipGridAndMobile]: isMobile || !isList,
      })}
    >
      <AttributeChip
        locationAttribute={hasVehicle ? part.locationFilter : undefined}
        topAttributes={part.topAttributes}
      />
    </div>
  );

  const renderProductNotesComps = () => {
    if (isShelfYMM || hasVehicle) {
      //hide on mdUp for list view
      return (
        //IMPORTANT: Do not change to implementation css, SEO requested that this be completely hidden
        <Hidden implementation="js" mdUp={!!isList}>
          <div className={styles.gridViewNotes} data-testid="product-notes-grid-view">
            <ProductNotes
              isShelfYMM={!!isShelfYMM}
              application={application}
              notes={finalNotes}
              partNumber={part.partNumber}
              productName={part.name}
              skuNumber={part.skuNumber}
              warranty={part.warranty}
              locationFilter={part.locationFilter}
              productSpecs={productSpecs}
              storeRetailPrice={storeRetailPrice}
              url={part.seoUrl}
              isSearchPageType={isSearchPageType}
            />
          </div>
        </Hidden>
      );
    }
  };

  useEffect(() => {
    if (shippingType && groupId) {
      const selectedGroupFulfillmentHasDealsAvailableQty = fulfillmentOptions?.some(
        (option) =>
          option.groupTypeId === groupId && option.available && option.dealsAvailableQty > 0
      );
      const shouldShowDealsButton =
        (dealDrawerShelfEnabled &&
          storeSelected &&
          !!groupId &&
          part.skuPricingAndAvailability?.dealAvailable) ??
        false;
      selectedGroupFulfillmentHasDealsAvailableQty;
      const shouldShowButton =
        dealDrawerShelfEnabled &&
        storeSelected &&
        !!groupId &&
        selectedGroupFulfillmentHasDealsAvailableQty === true;
      setShowBundleDealsButton(isNewDealsApiEnabled ? shouldShowDealsButton : shouldShowButton);
    }
  }, [
    dealDrawerShelfEnabled,
    fulfillmentOptions,
    groupId,
    storeSelected,
    shippingType,
    isNewDealsApiEnabled,
    part.skuPricingAndAvailability?.dealAvailable,
  ]);
  const prevCount = usePrevious(count);

  return (
    <div className={styles.prodSectionWrapper}>
      <div
        className={isList ? styles.prodSectionContainer : styles.prodSectionContainerGrid}
        data-testid="productInfoSection"
      >
        <div
          className={cx(styles.prodImageContainer, {
            [styles.prodImageList]: isList,
          })}
        >
          <Hidden mdDown={!part.isSponsoredCard} mdUp>
            <div className={cx(styles.sponsoredTextMob, azCommonStyles['az-caption'])}>
              <Label label="label_ShelfPage_sponsored" />
            </div>
          </Hidden>
          <ProductImage
            isListView={isList}
            alt={productImageAltName}
            src={part.itemImageUrl ?? part.productImageUrl}
            url={part.seoUrl}
            prodIndex={index}
            isSearchPageType={isSearchPageType}
          />
          <Hidden mdUp className={styles.mobileProdInfoContainer}>
            <div data-testid="mobile-productInfo">
              <ProductInfo
                application={application}
                dealInfo={dealInfo}
                cents={cents.padEnd(2, '0')}
                dollars={dollars}
                corePrice={corePrice}
                fitsVehicle={fitsVehicle}
                hasVehicle={hasVehicle}
                itemPresentInCart={part.itemPresentInCart}
                notes={finalNotes}
                partNumber={part.partNumber}
                productId={part.skuPricingAndAvailability?.productId}
                productName={part.name}
                partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
                productImage={part.productImageUrl}
                toggleShowYmme={toggleShowYmme}
                showPriceCaption={showPriceCaptionValue}
                skuNumber={part.skuNumber}
                url={part.seoUrl}
                warranty={part.warranty}
                showWarrantyModalWrapper={showWarrantyModalWrapper}
                recordType={part.recordType}
                locationFilter={part.locationFilter}
                productSpecs={part.productSpecs}
                storeRetailPrice={storeRetailPrice}
                prodIndex={index}
                count={count}
                price={price}
                shippingType={shippingType}
                handleQuantityChange={handleQuantityChange}
                showDealDrawerButton={showDealDrawerButton}
                onDealClick={handleDealClick}
                clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
                isMobile={isMobile}
                isSearchPageType={isSearchPageType}
                renderProductAttributeChips={renderProductAttributeChips}
                productAttributeChipsEnabled={productAttributeChipsEnabled}
              />
            </div>
          </Hidden>
        </div>
        <Hidden
          smDown
          className={cx({
            [styles.prodInfoContainer]: isList,
            [styles.productInfoGrid]: !isList,
          })}
        >
          <div data-testid="productInfo">
            <ProductInfo
              application={application}
              dealInfo={dealInfo}
              cents={cents.padEnd(2, '0')}
              dollars={dollars}
              corePrice={corePrice}
              fitsVehicle={fitsVehicle}
              hasVehicle={hasVehicle}
              itemPresentInCart={part.itemPresentInCart}
              notes={finalNotes}
              partNumber={part.partNumber}
              productId={part.skuPricingAndAvailability?.productId}
              productName={part.name}
              partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
              productImage={part.productImageUrl}
              toggleShowYmme={toggleShowYmme}
              showPriceCaption={showPriceCaptionValue}
              skuNumber={part.skuNumber}
              url={part.seoUrl}
              warranty={part.warranty}
              showWarrantyModalWrapper={showWarrantyModalWrapper}
              recordType={part.recordType}
              locationFilter={part.locationFilter}
              productSpecs={part.productSpecs}
              storeRetailPrice={storeRetailPrice}
              prodIndex={index}
              count={count}
              price={price}
              showDealDrawerButton={showDealDrawerButton}
              onDealClick={handleDealClick}
              clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
              isMobile={isMobile}
              shippingType={shippingType}
              handleQuantityChange={handleQuantityChange}
              isSearchPageType={isSearchPageType}
              renderProductAttributeChips={renderProductAttributeChips}
              productAttributeChipsEnabled={productAttributeChipsEnabled}
            />
            {!isList && productAttributeChipsEnabled && renderProductAttributeChips}
          </div>
        </Hidden>
        <div
          className={cx(styles.prodPriceContainer, { [styles.oldProdPriceContainerGrid]: !isList })}
        >
          {shelfQuerySuccess &&
          !productSkuIsFetching &&
          (!enable24ProductView || index < 12 || !secondaryProductSkuIsFetching) ? (
            <ProductPricingAndAvailability
              cents={cents.padEnd(2, '0')}
              corePrice={corePrice}
              dealInfo={dealInfo}
              configurableSku={configurableSku}
              dollars={dollars}
              fitsVehicle={fitsVehicle}
              hasVehicle={hasVehicle}
              itemPresentInCart={part.itemPresentInCart}
              productId={skuPricingAndAvailability?.productId}
              productName={part.name}
              productImage={part.productImageUrl}
              shipToHomeAvailable={shipToHomeAvailable}
              skuNumber={part.skuNumber}
              partsThatFitUrl={part.productCanonicalUrl || part.productSeoUrl || ''}
              storePickupAvailable={storePickupAvailable}
              skuPricingAndAvailability={skuPricingAndAvailability}
              toggleShowYmme={toggleShowYmme}
              url={part.seoUrl}
              vehicleSpecific={part.vehicleSpecific ?? false}
              storeSelected={part.storeSelected}
              prodIndex={index}
              fulfillmentOptions={part.fulfillmentOptions}
              price={price}
              shippingType={shippingType}
              productEdited={productEdited}
              count={count}
              updateCart={updateCart}
              handleChangeShippingType={handleChangeShippingType}
              handleChangeShippingTypeV2={handleChangeShippingTypeV2}
              handleAddToCart={handleAddToCart}
              trackQOHError={trackQOHError}
              setTrackQOHError={setTrackQOHError}
              showDealDrawerButton={showDealDrawerButton}
              onDealClick={handleDealClick}
              clearance={skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem}
              groupId={groupId}
              handleQuantityChange={handleQuantityChange}
              renderProductAttributeChips={renderProductAttributeChips}
              productAttributeChipsEnabled={productAttributeChipsEnabled}
            />
          ) : (
            <ProductAvailabilitySkeleton isList={isList} finalNotes={finalNotes} />
          )}
        </div>
      </div>
      {renderProductNotesComps()}
    </div>
  );
};

export { ProductPriceInfoContainer };
