/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { View, Icon, Info, Text } from '@az/starc-ui';
import styles from './Warning.module.scss';

type Props = { warning: string };

export const Warning = ({ warning }: Props) => {
  return (
    <View
      backgroundColor="warning"
      padding={[2, 4]}
      borderRadius="small"
      direction="row"
      align="center"
      maxWidth="max-content"
    >
      <View.Item className={styles.iconContainer}>
        <Icon svg={Info} />
      </View.Item>
      <View.Item grow>
        <Text weight="bold" className={styles.warningMessage}>
          {warning}
        </Text>
      </View.Item>
    </View>
  );
};
