/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import Hidden from '../Hidden';
import { Grid } from '../Grid';
import azCommonStyles from '../../theme/globals.module.scss';
import Skeleton from '../Skeleton/Skeleton';
import styles from './styles.module.scss';
import { NewFulfillmentSkeleton } from './NewFulfillmentSkeleton';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useRouter } from 'next/router';

const ShelfSkeleton = () => {
  const { listViewState } = useProductListView();
  const router = useRouter();
  const isList = isBrandShelfPage(router.asPath)
    ? listViewState.isBrandListView
    : listViewState.isListView;

  return (
    <div
      className={cx(azCommonStyles.mainContentContainer, styles.pageContent, styles.shelfContent)}
      data-testid="shelf-skeleton"
    >
      <Grid container spacing={0} direction="row">
        <Grid item xs={12} md={12} lg={3} className={styles.hideMDDown}>
          <div className={styles.shelfLoadingContent}>
            <Skeleton animation="wave" height={50} width="80%" />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={9}>
          <div className={styles.ymmeLoader}>
            <Skeleton animation={false} width="100%" height={100} variant="rect" />
          </div>
          <div className={styles.shelfLoadingContent}>
            <Grid container spacing={0} direction={isList ? 'column' : 'row'}>
              <Grid lg={isList ? 12 : 4} md={isList ? 12 : 6} sm={12} item>
                <NewFulfillmentSkeleton isList={isList} />
              </Grid>
              <Grid lg={isList ? 12 : 4} md={isList ? 12 : 6} item>
                <Hidden smDown implementation="js">
                  <NewFulfillmentSkeleton isList={isList} />
                </Hidden>
              </Grid>
              {!isList && (
                <Grid lg={4} item>
                  <Hidden mdDown>
                    <NewFulfillmentSkeleton isList={isList} />
                  </Hidden>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ShelfSkeleton;
