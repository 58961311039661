/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Table } from '@/components/Table/Table';
import { TableBody } from '@/components/TableBody/TableBody';
import { TableRow } from '@/components/TableRow/TableRow';
import { TableCell } from '@/components/TableCell/TableCell';
import azCommonStyles from '@/theme/globals.module.scss';
import viewStyles from '../viewStyles.module.scss';
import { Label } from '@/features/i18n';
import Modal from '@/components/Modal';
import { globalConstant } from '@/constants/global';
import { useLabel } from '@/hooks/useLabels';
import { ProductTitle } from '../../ResultsList/ProductTitle';
import { ReduxState } from '@/types';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useRouter } from 'next/router';
import { useProductListView } from '../../../../../context/useProductListView';

type Props = {
  isShelfYMM?: boolean;
  application?: string;
  info?: React.ReactNode | string;
  productName?: string;
  notes?: React.ReactNode;
  partNumber?: string;
  skuNumber?: number;
  warranty?: string;
  storeRetailPrice?: string | number | undefined;
  locationFilter?: string;
  productSpecs?: Record<string, string> | null;
  url: string;
  onProductClick?: () => void;
  prodIndex?: number;
  isSearchPageType: boolean;
};

type ProductNoteProps = Props & {
  heading?: React.ReactNode;
  openModal?: boolean;
  handleSeeMoreClick?: () => void;
};

type ProductNoteTableRowProps = {
  productData: React.ReactNode;
  key?: string;
  testID: string;
  label: string;
  isNotes?: boolean;
  isSmallMobile: boolean;
};

const ProductNoteTableRow = ({
  productData,
  testID,
  label,
  isSmallMobile,
  isNotes,
}: ProductNoteTableRowProps) => {
  return productData ? (
    !isSmallMobile ? (
      <TableRow className={viewStyles.specInnerRow} key={label}>
        <TableCell
          component="th"
          scope="row"
          className={azCommonStyles['az-body-1-regular']}
          data-testid={testID}
        >
          {label}
        </TableCell>
        <TableCell>
          {isNotes ? (
            <span className={cx(azCommonStyles['az-caption'], viewStyles.italicize)}>
              {productData}
            </span>
          ) : (
            <span className={cx(azCommonStyles['az-body-2-heavy'], viewStyles.spanStyle)}>
              {productData}
            </span>
          )}
        </TableCell>
      </TableRow>
    ) : (
      <TableRow className={cx(viewStyles.mobileColumn, viewStyles.specInnerRowSmall)} key={label}>
        <TableCell
          component="th"
          scope="row"
          className={cx(azCommonStyles['az-body-1-regular'], viewStyles.mobileCell)}
          data-testid="table-cell-part-number"
        >
          {label}
          {isNotes ? (
            <span
              className={cx(
                azCommonStyles['az-caption'],
                viewStyles.productSpecValue,
                viewStyles.italicize
              )}
            >
              {productData}
            </span>
          ) : (
            <span className={cx(azCommonStyles['az-body-2-heavy'], viewStyles.productSpecValue)}>
              {productData}
            </span>
          )}
        </TableCell>
      </TableRow>
    )
  ) : null;
};

export const ProductNote = ({
  heading,
  info,
  notes,
  openModal = false,
  handleSeeMoreClick,
  partNumber,
  skuNumber,
  warranty,
  productName,
  locationFilter,
  application,
  productSpecs,
  url,
  onProductClick,
  prodIndex,
  isSearchPageType,
}: ProductNoteProps) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between(720, 1139));
  const router = useRouter();
  const { listViewState } = useProductListView();
  const isListView = isBrandShelfPage(router.asPath)
    ? listViewState.isBrandListView
    : listViewState.isListView;
  const typography = isListView
    ? azCommonStyles['az-body-2-regular']
    : azCommonStyles['az-caption'];
  let content = null;
  const lbl_ProductSpecs = useLabel('label_shelf_product_specifications');
  const lblPartNo = useLabel('label_ProductDisplayPage_body_PartNo');
  const lblSkuNumber = useLabel('Label_Sku_Uppercase');
  const lblLocation = useLabel('label_cart_lineItem_Location');
  const lblWarranty = useLabel('label_cart_lineItem_Warranty');
  const lblApplication = useLabel('label_CategoryPage_body_ApplicationSmall');
  const lblNotes = useLabel('label_CategoryPage_body_Notes');
  const renderPart = (
    <ProductNoteTableRow
      productData={partNumber}
      testID={'table-cell-part-number'}
      label={lblPartNo}
      isSmallMobile={isSmallMobile}
    />
  );
  const renderSku = (
    <ProductNoteTableRow
      productData={skuNumber}
      testID={'table-cell-sku-num'}
      label={`${lblSkuNumber} #`}
      isSmallMobile={isSmallMobile}
    />
  );
  const renderLocation = (
    <ProductNoteTableRow
      productData={locationFilter}
      testID={'table-cell-location'}
      label={lblLocation}
      isSmallMobile={isSmallMobile}
    />
  );
  const renderWarranty = (
    <ProductNoteTableRow
      productData={warranty}
      testID={'table-cell-warranty'}
      label={lblWarranty}
      isSmallMobile={isSmallMobile}
    />
  );
  const renderApplication = (
    <ProductNoteTableRow
      productData={application}
      testID={'table-cell-application'}
      label={lblApplication}
      isSmallMobile={isSmallMobile}
    />
  );
  const renderNotes = (
    <ProductNoteTableRow
      productData={notes}
      testID={'table-cell-notes'}
      label={lblNotes}
      isSmallMobile={isSmallMobile}
      isNotes={true}
    />
  );

  const renderAdditionalSpecs = () => {
    const productSpecsArr = productSpecs ? Object.entries(productSpecs) : [];

    if (productSpecsArr) {
      return productSpecsArr.map((elem, idx) => {
        return (
          <ProductNoteTableRow
            productData={elem[1]}
            key={elem[0]}
            testID={`table-cell-specification-${idx}`}
            label={elem[0]}
            isSmallMobile={isSmallMobile}
          />
        );
      });
    }
  };

  const renderShelfModal = (
    <div className={viewStyles.productSpecificationPart}>
      <h2
        className={cx(
          viewStyles.productSpecificationTitle,
          azCommonStyles['az-subtitle-1-regular']
        )}
        data-testid="product-specification-heading"
      >
        {lbl_ProductSpecs}
      </h2>
      <div
        className={cx(
          azCommonStyles['az-body-2-regular'],
          viewStyles.subHeadingColor,
          viewStyles.subHeadingRow
        )}
        data-testid="product-specification-sub-heading"
      >
        for
        <ProductTitle
          titleStyles={cx(
            azCommonStyles['az-body-2-regular'],
            viewStyles.subHeading,
            viewStyles.subHeadingColor,
            viewStyles.subHeadingText
          )}
          prodIndex={prodIndex}
          CustomElement={'div'}
          productName={productName}
          onProductClick={onProductClick}
          url={url}
          isSearchPageType={isSearchPageType}
        />
      </div>
      <Table>
        <TableBody>
          {renderPart}
          {renderSku}
          {renderLocation}
          {renderWarranty}
          {renderApplication}
          {renderNotes}
          {Object.keys(productSpecs || {}).length && renderAdditionalSpecs()}
        </TableBody>
      </Table>
    </div>
  );

  if (Object.keys(productSpecs || {}).length || info) {
    content = (
      <>
        <Modal
          body={renderShelfModal}
          isOpen={openModal}
          showCloseButton={true}
          handleClose={handleSeeMoreClick}
          handleBack={handleSeeMoreClick}
          desktopSize={globalConstant.large}
          tabletSize={globalConstant.fullscreen}
          darkHeader={isMobile}
          showBackButton={isMobile}
          noGap={isSmallMobile}
          mediumGap={isTablet}
          whiteArrow={true}
        />
        {info && (
          <div className={cx(typography, viewStyles.notes)} data-testid="product-note">
            {heading ? <strong>{heading}: </strong> : ''} {info}
          </div>
        )}
      </>
    );
  }

  return content;
};

export const ProductNotes = ({
  isShelfYMM,
  application,
  notes,
  partNumber,
  skuNumber,
  warranty,
  productName,
  locationFilter,
  productSpecs,
  storeRetailPrice,
  url,
  onProductClick,
  prodIndex,
  isSearchPageType,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSeeMoreClick = () => setOpenModal((prevState) => !prevState);

  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);
  const isABot = deviceType === 'bot';
  const lblLocation = useLabel('label_CategoryPage_body_Location');
  const lblPrice = useLabel('label_ProductDisplayPage_body_Price');

  const renderLiOptions = () => {
    const productSpecsArr = productSpecs ? Object.entries(productSpecs) : [];
    return productSpecsArr.map((elem) => {
      return (
        <li key={elem[0]}>
          {elem[0]}: {elem[1]}
        </li>
      );
    });
  };

  const renderBotList = (
    <ul>
      {storeRetailPrice ? (
        <li>
          {lblPrice}: {storeRetailPrice}
        </li>
      ) : (
        ''
      )}
      {locationFilter ? (
        <li>
          {lblLocation}: {locationFilter}
        </li>
      ) : (
        ''
      )}
      {Object.keys(productSpecs || {}).length ? renderLiOptions() : null}
    </ul>
  );

  const renderNotes = () => {
    if (isABot) {
      return (
        <>
          {notes}
          {renderBotList}
        </>
      );
    } else if (Object.keys(productSpecs || {}).length && isShelfYMM) {
      // TODO: Handle hydration error here in dev mode (on YMM shelf pages)
      return (
        <div className={viewStyles.notesContainer}>
          <span>{notes}</span>
          <button
            className={cx(azCommonStyles['az-body-2-regular'], viewStyles.readMore)}
            onClick={handleSeeMoreClick}
          >
            <p>{<Label label="hyperlink_label_shelf_See_More" />}</p>
          </button>
        </div>
      );
    } else {
      return notes;
    }
  };

  return (
    <Fragment>
      <ProductNote
        heading={<Label label="label_CategoryPage_body_ApplicationSmall" />}
        info={application}
        url={url}
        onProductClick={onProductClick}
        prodIndex={prodIndex}
        isSearchPageType={isSearchPageType}
      />
      <ProductNote
        heading={notes ? <Label label="label_CategoryPage_body_Notes" /> : null}
        application={application}
        notes={notes}
        info={renderNotes()}
        partNumber={partNumber}
        skuNumber={skuNumber}
        warranty={warranty}
        productName={productName}
        storeRetailPrice={storeRetailPrice}
        locationFilter={locationFilter}
        openModal={openModal}
        handleSeeMoreClick={handleSeeMoreClick}
        productSpecs={productSpecs}
        url={url}
        onProductClick={onProductClick}
        prodIndex={prodIndex}
        isSearchPageType={isSearchPageType}
      />
    </Fragment>
  );
};
