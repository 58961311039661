/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import cx from 'classnames';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import styles from './styles.module.scss';

type Props = {
  tabIndex?: number;
  onClick?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  className?: string;
  disabled?: boolean;
  component?: React.ElementType;
  children?: React.ReactNode;
  role?: string;
  value?: string;
  selected?: boolean;
  'data-testid'?: string;
};

export const MenuItem = (props: Props) => {
  const {
    onClick,
    onKeyDown,
    className,
    tabIndex: tabIndexProp,
    component = 'div',
    role = 'menuitem',
    selected,
    ...other
  } = props;

  const divRoleValue = role === 'menuitem' ? role : 'presentation';
  const buttonRoleValue = component === 'button' ? 'button' : undefined;

  let tabIndex;

  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      role={divRoleValue}
      className={cx(styles.wrapper, {
        [styles.selected]: selected,
      })}
    >
      <ButtonBase
        role={buttonRoleValue}
        tabIndex={tabIndex}
        component={component}
        onClick={onClick}
        onKeyDown={onKeyDown}
        {...other}
        className={cx(className, styles.button)}
      />
    </div>
  );
};
